import { useParams, useLocation } from 'react-router-dom';
import Header from "components/blocks/Header/Header"
import React, { useState, useEffect, useRef, useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss"

import PostRender from "components/blocks/PostsRenderer/PostsRenderer"

import UserRenderer from "components/blocks/UserRenderer/UserRenderer"

import Sidebar from "components/blocks/Sidebar/Sidebar"

import RightSidebar from "components/blocks/RightSidebar/RightSidebar"

import logo from "assets/img/conceptpdn-white-alpha.png"

import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";


import { UserAuthCheckManager, getPost, getReply, getCursor, getProfile, getProfilePosts, UserFollow, UserUnfollow, SearchKeyword, SearchUser } from "common/api/APIUtils"

import EasyCache from 'components/blocks/EasyCache/EasyCache';

import { useModal, ModalBox } from 'components/blocks/ProfileEditor/ProfileEditor';

import { SnackbarContext } from 'components/blocks/GlobalSnackbar/SnackbarProvider';


const Search = ({ importUserList }) => {
    console.log("ユザリストレンダ稼働")
    console.log(importUserList)
    //let { user_id } = useParams();

    //const [treePosts, setTreePosts] = useState([false]);
    const openSnackbar = useContext(SnackbarContext);

    const [posts, setPosts] = useState([false]);

    const [reply, setReply] = useState(EasyCache("searchPost", "searchPost", "json"));

    const [userlist, setUserlist] = useState(EasyCache("userList", "userList", "json"));

    const [profile, setProfile] = useState([false])

    const [following, setFollowing] = useState(profile.is_following);

    const [keyword, setKeyword] = useState(EasyCache("keyword", "", "bypass", ""));



    const [searchMode, setSearchMode] = useState(EasyCache("searchMode", "searchMode", "bypass", "post"));


    const { modal, submitFormMode, target, modalOpen, modalClose } = useModal();
    //const [replyCursor, setReplyCursor] = useState("");

    //	useEffect(() => {
    //		const fetchPosts = async () => {
    //			const response = await getPost(post_id);
    //			console.log("ポスト取得d")
    //			console.log(response);
    //			console.log(post_id)
    //			setPosts(response);  // レスポンスをステートにセット
    //			//setReplyCursor(response.next)
    //			//setReplyCursor("STR")
    //		};
    //
    //		fetchPosts();
    //	}, [user_id]);  // post_idが変更されたときに再度フェッチするように依存配列を設定

    useEffect(() => {
		const fetchPosts = async () => {
			//console.log("ユーザーネーム")

			//console.log(username)
			//const response = await getProfile(username);

            setUserlist(importUserList)
			//setProfile(response);  // レスポンスをステートにセット
			//setFollowing(response.is_following)
		
		};

		fetchPosts();
	}, [importUserList]);  // post_idが変更されたときに再度フェッチするように依存配列を設定
    

    useEffect(() => {
        window.addEventListener('beforeunload', () => {
            //sessionStorage.clear();
            console.log("リロード検知1")
            //EasyCache("profilePost", user_id, "delete")
        });

        return () => {
            window.removeEventListener('beforeunload', () => {
                //sessionStorage.clear();
                console.log("リロード検知2")
                //EasyCache("profilePost", user_id, "delete")
            });
        };
    }, []);

    const observer = useRef();
    const lastElementRef = useRef(null);

    useEffect(() => {
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                // ここに最後の要素が表示されたときの処理を書く
                console.log('あああ.E');
                console.log(reply)
                EasyCache("searchPost", "searchPost", "json", reply)

                // 新しい投稿をフェッチする
                fetchMorePosts();

            }
        });

        if (lastElementRef.current) {
            observer.current.observe(lastElementRef.current);
        }

        return () => {
            if (lastElementRef.current) {
                observer.current.unobserve(lastElementRef.current);
            }
        };
    }, [reply.results]);  // reply.resultsが変更されたときに再度フェッチするように依存配列を設定

    useEffect(() => {
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                // ここに最後の要素が表示されたときの処理を書く
                console.log('あああ.');
                //console.log(reply)
                EasyCache("userList", "userList", "json", userlist)

                // 新しい投稿をフェッチする
                fetchMorePosts();

            }
        });

        if (lastElementRef.current) {
            observer.current.observe(lastElementRef.current);
        }

        return () => {
            if (lastElementRef.current) {
                observer.current.unobserve(lastElementRef.current);
            }
        };
    }, [userlist.results]);  // reply.resultsが変更されたときに再度フェッチするように依存配列を設定


    useEffect(() => {
        EasyCache("searchMode", "searchMode", "val", searchMode)
        //setUserlist("")
        //setReply(EasyCache("searchPost", "searchPost", "json"))
        //setReply("")

        //setKeyword("")
        //setKeyword("")
        //EasyCache("userList", user_id, "delete")
        //EasyCache("profilePost", user_id, "delete")
    }, [searchMode]);  // reply.resultsが変更されたときに再度フェッチするように依存配列を設定

    function searchModeSwitchWrapper(mode) {
        search()
        setSearchMode(mode)
    }


    // 新しい投稿をフェッチするための非同期関数
    const fetchMorePosts = async () => {
        //console.log("replyCursor!:", replyCursor)
        if (reply.next) {
            const response = await getCursor(reply.next);
            console.log('fetchMorePosts response:', response);
            console.log('fetchMorePosts response.results:', response.results);
            setReply(prevReply => ({
                ...response,
                results: [...prevReply.results, ...response.results]
            }));
            //		if (response.next) {
            //			setReplyCursor(response.next)
            //	}
        }
        if (userlist.next) {
            const response = await getCursor(userlist.next);
            console.log('fetchMorePosts response:', response);
            console.log('fetchMorePosts response.results:', response.results);
            setUserlist(prevReply => ({
                ...response,
                results: [...prevReply.results, ...response.results]
            }));
            //		if (response.next) {
            //			setReplyCursor(response.next)
            //	}
        }
    };

    const [favorited, setFavorited] = useState(false);

    async function postSearch(keyword) {
        let result = await SearchKeyword(keyword)
        console.log("検索結果")
        console.log(result)
        setReply(result)
        EasyCache("searchPost", "searchPost", "json", result)
    }

 //   async function userSearch(keyword) {
 //       let result = await SearchUser(keyword)
 //       console.log("検索結果")
 //       console.log(result)
 //       setUserlist(result)
 //       EasyCache("userList", "userList", "json", result)
//    }

    async function search() {
        if (searchMode === "post") {
            //EasyCache("keyword", "keyword", "val", keyword)
            EasyCache("keyword", "", "", keyword)
            let result = await postSearch(keyword)
        }
        if (searchMode === "media") {
            //EasyCache("keyword", "keyword", "val", keyword)
            EasyCache("keyword", "", "", keyword)
            let result = await postSearch(keyword + " has:media")
        }
        if (searchMode === "user") {
            let result = await userSearch(keyword)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // loginUserは非同期処理であるが、処理が完了してからログインチェックを行わないとログインチェックに失敗する
        // そのためawaitで処理の完了を待つ必要がある
        console.log("何らかの検索ボタン")
        search()
    }



    return (
        <>

            <div className='userListView'>
                {/*こっちが親投稿のリプライを表示する部分 */}
                {userlist.results?.map((user, index) => (
                    <React.Fragment key={index}>
                        <UserRenderer key={index} user={user} />
                        {index === userlist.results.length - 2 && <div ref={lastElementRef}></div>}
                    </React.Fragment>
                ))}
            </div>

        </>
    );
}

export default Search;
