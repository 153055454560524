import React, { useState, useContext } from 'react';
import MaskedInput from 'react-text-mask';
import axios from 'axios';
import "./style.scss";
import logo from "assets/img/conceptpdn-white-alpha.png"
import { Helmet } from 'react-helmet';

import { Signup, useIntelligentNavigate, logout, allCleanup, ChangePassword, EmailChangeEmailAuthenticated, EmailChangeEmailCodeSubmit } from "common/api/APIUtils";
import { SnackbarContext } from 'components/blocks/GlobalSnackbar/SnackbarProvider';


//import { loginUser } from "../../../common/api/APIUtils"

const SignupForm = () => {
	const [username, setUsername] = useState('');
	const [nickname, setNickname] = useState('');
	const [email, setEmail] = useState('');
	const [currentPassword, setCurrentPassword] = useState('');

	const [tokenId, setTokenId] = useState('');
	const [activationCode, setActivationCode] = useState('');
	const [page, setPage] = useState(0);

	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');

	const [showPassword, setShowPassword] = useState(false);

	const [prosessing, setProsessing] = useState(false);

	const openSnackbar = useContext(SnackbarContext);

	const intelligentNavigate = useIntelligentNavigate();

	const handleEmailSubmit = async (e) => {
		e.preventDefault();
		setProsessing(true)
		let result = await EmailChangeEmailAuthenticated(email);
		setProsessing(false)
		console.log("result")
		if (result?.name === "AxiosError") {
			openSnackbar("エラーが発生しました: " + result, "error")
			openSnackbar(result.request.response, "error")
		} else if (result?.code) {
			openSnackbar("エラーが発生しました: " + result, "error")
			openSnackbar(result?.request?.response, "error")
		} else {
			if (result?.message) {
				setTokenId(result.token_id)
				setPage(1)
				openSnackbar("認証コードがメールに送信されました")
			}
		}
	}

	const handleCodeSubmit = async (e) => {
		e.preventDefault();
		setProsessing(true)
		let result = await EmailChangeEmailCodeSubmit(tokenId, activationCode, password);
		setProsessing(false)
		console.log("result")
		if (result == true) {
			openSnackbar("メールアドレスの変更が完了しました")
			intelligentNavigate("/home")
		} else {
			openSnackbar("エラーが発生しました: " + result, "error")
			openSnackbar(result.request.response, "error")
		}
		if (result == true) {
			// 次の認証コード入力画面に飛ばす
		}
	}

	const handleSubmit = async (e) => {
		if (password !== passwordConfirm) {
			openSnackbar("パスワードと確認パスワードが一致していません", "error")
		}
		e.preventDefault();
		setProsessing(true)
		let result = await ChangePassword(tokenId, email);
		setProsessing(false)

		if (result == true) {
			openSnackbar("パスワードが変更されました")
		} else {
			openSnackbar("エラーが発生しました: " + result, "error")
			openSnackbar(result.request.response, "error")
		}
		if (result == true) {
			allCleanup()
			intelligentNavigate("/login")
		}
	};


	return (
		<div className='blackbirdLoginFormRoot'>
			<div class="container">
				<Helmet>
					<script src="https://www.google.com/recaptcha/enterprise.js?render=6LfHvIUpAAAAAGKt7B9UnXTN8AL3587h3ml-Uv1-"></script>
				</Helmet>
				<div class="logo">
					<img src={logo} alt="" class="logo" />
				</div>
				{(page == 0) &&
					<>
						<div class="elements">
							<p class="text" id="title">メールアドレスを変更</p>
						</div>
						<div class="elements">
							<p class="text">変更したい電子メールを入力してください</p>
						</div>
						<form onSubmit={handleEmailSubmit}>
							<div class="form">
								<input
									type="text"
									required
									id="email"
									placeholder="Email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div class="elements topMargin">
								{prosessing ?
									<div id="loginbuttonDummy">送信中...</div>
									:
									<button type="submit" id="loginbutton">認証コードを送信</button>
								}
							</div>
							<br />
						</form>
					</>}
				{(page == 1) &&
					<>
						<div class="elements">
							<p class="text" id="title">メールアドレスを変更</p>
						</div>
						<div class="elements">
							<p class="text">Emailに送信された10桁の認証コードを入力してください</p>
						</div>
						<form onSubmit={handleCodeSubmit}>
							<div class="form">
								<input
									type="text"
									required
									id="activationCode"
									placeholder="認証コード"
									value={activationCode}
									autoComplete='none'
									onChange={(e) => setActivationCode(e.target.value)}
								/>
								<input
									type={showPassword ? "text" : "password"}
									required
									id="password"
									placeholder="パスワード"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<div class="checkboxXXX">
								<input type="checkbox" id="showPassword" onClick={() => { setShowPassword(!showPassword); }} />
								<label for="showPassword">パスワードの表示</label>
							</div>
							<div class="elements">
								{prosessing ?
									<div id="loginbuttonDummy">送信中...</div>
									:
									<button type="submit" id="loginbutton">メールアドレスを変更</button>
								}
							</div>
							<br />
							<div className='cursorPointer' onClick={() => { location.reload() }}>
								<span>認証コードが届きませんか？</span>
								<br />
								<span>もう一度やり直しましょう</span>
							</div>
						</form>
					</>}
			</div>
		</div>
	);
};

export default SignupForm;
