import React, { useState, useEffect } from 'react';
//import axios from 'axios';
import { LoginManagerWrapper, UnkX, accessTokenCheck, UserAuthCheckManager } from "common/api/APIUtils"

//import { BrowserRouter, Route, Routes, Link, useNavigate, Navigate } from 'react-router-dom';

//import { loginUser } from "common/api/APIUtils"
//import logo from "assets/img/conceptpdn-white-alpha.png"

import ChangeEmailForm from "components/blocks/ChangeEmailForm/ChangeEmailForm";

//import "./style.scss";

const Signup = () => {

	return (
		<div class="container">
			<UserAuthCheckManager />
			<ChangeEmailForm />
		</div>
	);
};

export default Signup;
