import { useParams } from 'react-router-dom';
import Header from "components/blocks/Header/Header"
import React, { useState, useEffect, useRef } from 'react';

import { useIntelligentNavigate, getTimeLine, TokenAuthManager, UserAuthCheckManager, LoginManagerWrapper, PostFavorite, GetEngagement, DeleteFavorite, getCursor } from "../../../common/api/APIUtils"

import "./style.scss"

import PostRender from "components/blocks/PostsRenderer/PostsRenderer"

import Sidebar from "components/blocks/Sidebar/Sidebar"

import RightSidebar from "components/blocks/RightSidebar/RightSidebar"

import logo from "assets/img/conceptpdn-white-alpha.png"

function About() {
	const intelligentNavigate = useIntelligentNavigate();
	return (
		<>
			<div className="home">
				<UserAuthCheckManager />
				<Sidebar />
				<Header enableBack={true} />

				<div class="mainContentBase">
					<div class="mainContent">
						<div className='mainLine'>
							<div className='settings'>
								<div className='cursorPointer' onClick={() => intelligentNavigate("/change_email")}>
									メールアドレス変更
								</div>
								<div className='cursorPointer' onClick={() => intelligentNavigate("/password_reset")}>
									パスワード変更
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</>
	);
}

export default About;
