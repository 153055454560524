import React, { useState, useEffect } from 'react';
//import axios from 'axios';
import { LoginManagerWrapper, UnkX, accessTokenCheck } from "common/api/APIUtils"

//import { BrowserRouter, Route, Routes, Link, useNavigate, Navigate } from 'react-router-dom';

//import { loginUser } from "common/api/APIUtils"
//import logo from "assets/img/conceptpdn-white-alpha.png"

import ChangePasswordForm from "components/blocks/ChangePasswordForm/ChangePasswordForm";

//import "./style.scss";

const Signup = () => {

	return (
		<div class="container">
        <ChangePasswordForm/>
		</div>
	);
};

export default Signup;
