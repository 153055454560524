import React, { useState, useEffect, useRef, useContext } from 'react';

import { getTimeLine, TokenAuthManager, UserAuthCheckManager, LoginManagerWrapper, PostFavorite, GetEngagement, DeleteFavorite, PostRepost, DeleteRepost, useIntelligentNavigate, DeletePost, UserFollow, UserUnfollow, getProfile } from "common/api/APIUtils"


import logo from "assets/img/conceptpdn-white-alpha.png"
import { useNavigate } from 'react-router-dom';
import { SnackbarContext } from 'components/blocks/GlobalSnackbar/SnackbarProvider';

// get our fontawesome imports
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';

import { faRetweet as solidRetweet } from '@fortawesome/free-solid-svg-icons';

import { faMessage as regularMessage } from '@fortawesome/free-regular-svg-icons';

import { faChartBar } from '@fortawesome/free-solid-svg-icons';

import { faReply } from '@fortawesome/free-solid-svg-icons';

import { faBookmark as solidBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as regularBookmark } from '@fortawesome/free-regular-svg-icons';

import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faFlag } from '@fortawesome/free-solid-svg-icons';

import { useModal, ModalBox } from 'components/blocks/PostSubmit/PostSubmit';

import mediaSizeSwitch from "components/blocks/mediaSizeSwitch/mediaSizeSwitch"

import "./style.scss"

//import { faRetweet as regularRetweet } from '@fortawesome/free-regular-svg-icons'; // 輪郭のみのアイコン
//import { faHeart } from '@fortawesome/free-svg-icons';

let modeSelect
let tab = 0;//tabIndex用

const typeCheck = (user) => {
    return ["normal", user]
    if (modeSelect === "reply") {
        return ["direct", post.content.post, post.content.reply]
    }
    //オブジェクトが存在していない場合にエラーとならないように"?"をつけています
    if (post?.content?.post) {
        if (post.content.repost) {
            //console.log("[post.content.post, post.content.repost]")
            return ["list", post.content.post, post.content.repost]
        } else if (post.content.reply) {
            //console.log("[post.content.post, post.content.repost]")
            return ["reply", post.content.post, post.content.reply]
        } else {
            //console.log("[post.content.post]")

            return ["post", post.content.post]
        }
    } else if (post?.content?.repost) {
        //console.log("[post.content.repost]")

        return ["repost", post.content.repost]
    } else {
        //console.log("post")
        return ["direct", post]
    }
}


// 投稿を表示するためのコンポーネント
const PostsRenderer = ({ user, mode = false, engagementHidden = false }) => {
    const openSnackbar = useContext(SnackbarContext);
    const { modal, submitFormMode, target, modalOpen, modalClose } = useModal();

    //const [profile, setProfile] = useState([false])

    const [wrapUser, setWrapUser] = useState();
    const [following, setFollowing] = useState();

    tab += 1;
    console.log(tab)

    const [renderMode, setRenderMode] = useState(mode);

    useEffect(() => {
        // レンダリングのモードを管理する
        if (renderMode === "notification") {
            setModeSelect("reply");
        }
    }, [renderMode]);  // modeを依存配列に追加

    const intelligentNavigate = useIntelligentNavigate();
    const navigate = useNavigate();

    useEffect(() => {
        // メニューが表示されているときにのみクリックイベントを監視
        setWrapUser(user)
        setFollowing(user.is_following)
    },[user]);

    const followManage = async () => {
		console.log('フォロー制御', wrapUser.username);
		if (following) {
			// is_favoritedがtrueの場合、「いいね」を解除するAPIを呼び出す
			//response = await DeleteFavorite(post_id);
			await UserUnfollow(wrapUser.username);
		} else {
			// is_favoritedがfalseの場合、「いいね」をするAPIを呼び出す
			//response = await PostFavorite(post_id);
			await UserFollow(wrapUser.username);
		}

		const response = await getProfile(wrapUser.username);

		setWrapUser(response); // 値を更新
		setFollowing(response.is_following)
	};


    //	const favorite = async (post_id) => {
    //	  console.log('いいねしました', post_id);
    //	  // PostFavorite APIを呼び出す
    //	  await PostFavorite(post_id);
    //	  const response = await GetEngagement(post_id)
    //	  
    //	  // 新しいfavorite_countを取得する（APIのレスポンスから取得または再度APIを呼び出してデータをフェッチ）
    //	  const newFavoriteCount = response.favorite_count; // これは仮のコードで、実際のコードはAPIのレスポンスによります
    //	  //const newFavoriteCount = 100
    //
    //	  // favoritedの状態を更新する
    //	  setFavorited(true);
    //	  setFavoriteCount(newFavoriteCount);
    //  
    //	  // postのfavorite_countを更新する
    //	  post.favorite_count = newFavoriteCount;
    //	};
    const userClick = (username) => {
        //history.push('/posts/'+post_id);
        localStorage.setItem('timeline-scroll-position', window.scrollY);
        intelligentNavigate('/profile/' + username);
    }

    const [showRepostMenu, setshowRepostMenu] = useState(false);
    const [showPostOtherMenu, setshowPostOtherMenu] = useState(false);

    const menuRef = useRef();
    useEffect(() => {
        // メニューが表示されているときにのみクリックイベントを監視
        if (showRepostMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        // クリーンアップ関数
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showRepostMenu]);
    useEffect(() => {
        // メニューが表示されているときにのみクリックイベントを監視
        if (showPostOtherMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        // クリーンアップ関数
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showPostOtherMenu]);
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setshowRepostMenu(false);
            setshowPostOtherMenu(false);
        }
    };

    async function deletePost(post_id) {
        let result = await DeletePost(post_id)
        console.log("result")
        console.log(result)
        if (result == true) {
            openSnackbar("投稿を削除しました")
        } else {
            openSnackbar("エラーが発生しました: " + result?.message, "error")
        }
    }

    //通常投稿とリポスト時のレスポンスを生成
    // ここでpostを表示する
    return (
        
        <>{wrapUser &&
            <div class="userSmartView" onClick={() => { if (!engagementHidden) userClick(user.username) }}>
                <div className='userSmartViewFrame'>
                    <div className='logoFrame'>
                        {/*<img src={logo} alt="" class="logo" />*/}
                        <img onClick={(e) => { e.stopPropagation(); userClick(wrapUser.username); }} src={wrapUser.user_icon} alt="" class="logo" />

                    </div>
                    <div className="postContent">
                        <div className='postContentUsername' onClick={(e) => { e.stopPropagation(); userClick(wrapUser.username); }}>
                            <strong>{wrapUser.nickname}</strong>
                            <br />
                            <strong className='username'>@{wrapUser.username}</strong>
                            {wrapUser.is_follower && <strong className='isFollower'>フォローされています</strong>}
                            <br />
                            <strong className='bio'>{wrapUser.bio}</strong>
                        </div>
                    </div>
                    <div className='userFollowingManagementContent'>
                        {wrapUser.username === localStorage.getItem('user_name') ? <div className="hidden"></div> : <div className={following ? "profileFollowingManageButton following" : "profileFollowingManageButton follow"} onClick={(e) => { e.stopPropagation(); followManage() }}>{following ? <><span class="followNomal">フォロー中</span><span class="followHover">フォロー解除</span></> : "フォローする"}</div>}
                    </div>
                </div>
            </div>}
        </>
    );
}

export default PostsRenderer;