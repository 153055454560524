import React, { useEffect, useRef, useState } from 'react';

const PullToRefresh = ({ children, onRefresh, threshold = 150 }) => {
	const [refreshing, setRefreshing] = useState(false);
	const [pullDownDistance, setPullDownDistance] = useState(0);
	const touchStartY = useRef(0);
	const touchCurrentY = useRef(0);
	const beingPulledDown = useRef(false);

	const reset = () => {
		beingPulledDown.current = false;
		setPullDownDistance(0);
		setRefreshing(false);
	};

	const touchStartHandler = (e) => {
		if (e.touches.length === 1) {
			touchStartY.current = e.touches[0].screenY;
		}
	};

	const touchMoveHandler = (e) => {
		if (e.touches.length === 1 && window.pageYOffset === 0) {
			e.preventDefault();
			touchCurrentY.current = e.touches[0].screenY;
			const newPullDownDistance = touchCurrentY.current - touchStartY.current;
			if (!beingPulledDown.current && newPullDownDistance > threshold) {
				beingPulledDown.current = true;
			} else if (newPullDownDistance < threshold) {
				beingPulledDown.current = false;
				reset();
			}
			if (beingPulledDown.current) {
				// e.preventDefault();
				setPullDownDistance(newPullDownDistance);
			}
		}
	};

	const touchEndHandler = () => {
		if (beingPulledDown.current && window.pageYOffset === 0) {
			beingPulledDown.current = false;
			setRefreshing(true);
			const maybePromise = onRefresh();
			if (maybePromise && typeof maybePromise.then === 'function') {
				maybePromise.then(reset);
			} else {
				reset();
			}
		} else {
			reset();
		}
	};

	useEffect(() => {
		document.addEventListener('touchstart', touchStartHandler);
		document.addEventListener('touchmove', touchMoveHandler);
		document.addEventListener('touchend', touchEndHandler);

		return () => {
			document.removeEventListener('touchstart', touchStartHandler);
			document.removeEventListener('touchmove', touchMoveHandler);
			document.removeEventListener('touchend', touchEndHandler);
		};
	}, []);

	const style = beingPulledDown.current
		? {
			transform: `translateY(100px)`,
			transition: 'transform 0.3s ease-out',
		}
		: {
			transition: 'transform 0.3s ease-out',
		};

	const pullGlimpse = beingPulledDown.current
		? {
			position: 'absolute',
			backgroundColor: 'gray',
			width: "100%",
			height: 200 + "px",
			top: "-" + 200 + "px",
			transition: 'background-color 0.3s ease-out',
		}
		: {
			position: 'absolute',
			backgroundColor: 'black',
			width: "100%",
			height: 200 + "px",
			top: "-" + 200 + "px",
			transition: 'background-color 0.3s ease-out',
		};


	return (
		<div style={style}>
			<div style={pullGlimpse}>
				<span style={{ position: "absolute", left: "50%", transform: "translateX(-50%)", bottom: "0px", fontWeight: "bold", fontSize: "large" }}>リロード</span>
			</div>
			{children}
			{refreshing && <div>Refreshing...</div>}
		</div>
	);
};

export default PullToRefresh;
