import React, { useEffect, useState } from 'react';

export default function TimeAgo({ timestamp, forceFullDate = false }) {
    const [timeAgo, setTimeAgo] = useState('');

    const calculateTimeAgo = () => {
        const date = new Date(timestamp);
        const now = new Date();
        const diffMs = now - date;
        const diffSec = Math.floor(diffMs / 1000);
        const diffMin = Math.floor(diffSec / 60);
        const diffHour = Math.floor(diffMin / 60);
        const diffDay = Math.floor(diffHour / 24);

        let timeAgo;
        if (forceFullDate || diffDay > 0) {
            timeAgo = date.toLocaleString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
        } else if (diffHour > 0) {
            timeAgo = `${diffHour}時間前`;
        } else if (diffMin > 0) {
            timeAgo = `${diffMin}分前`;
        } else {
            timeAgo = `${diffSec}秒前`;
        }

        setTimeAgo(timeAgo);
    };

    useEffect(() => {
        calculateTimeAgo(); // 呼び出した瞬間から最初の一回が実行されるように
        const interval = setInterval(calculateTimeAgo, 1000);

        return () => clearInterval(interval);
    }, [timestamp, forceFullDate]);

    return <>{timeAgo}</>;
}
    
    // 使用例
    // <TimeAgo timestamp="2024-02-01T02:02:25+09:00" forceFullDate={true} />