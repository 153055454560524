import { useParams, useLocation } from 'react-router-dom';
import Header from "components/blocks/Header/Header"
import React, { useState, useEffect, useRef, useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss"
import PostRender from "components/blocks/PostsRenderer/PostsRenderer"
import UserRenderer from "components/blocks/UserRenderer/UserRenderer"
import Sidebar from "components/blocks/Sidebar/Sidebar"
import RightSidebar from "components/blocks/RightSidebar/RightSidebar"
import logo from "assets/img/conceptpdn-white-alpha.png"
import smallLogo from "assets/img/conceptpdn-darkgray.png"
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { UserAuthCheckManager, getPost, getReply, getCursor, getProfile, getProfilePosts, UserFollow, UserUnfollow, SearchKeyword, SearchUser } from "common/api/APIUtils"
import EasyCache from 'components/blocks/EasyCache/EasyCache';
import { useModal, ModalBox } from 'components/blocks/ProfileEditor/ProfileEditor';
import { SnackbarContext } from 'components/blocks/GlobalSnackbar/SnackbarProvider';

const Search = ({ postList, postListKey = "default", reverseMode = false, cache = true, endLogo = true, replyMode = false }) => {
    console.log("ユザリストレンダ稼働")
    console.log(postList)
    //let { user_id } = useParams();
    //const [treePosts, setTreePosts] = useState([false]);
    const openSnackbar = useContext(SnackbarContext);
    const [posts, setPosts] = useState([false]);
    const [reply, setReply] = useState(EasyCache("searchPost", "searchPost", "json"));
    const [postlist, setPostList] = useState(EasyCache("postListRenderer", postListKey, "json"));
    const [replyModeSelect, setReplyModeSelect] = useState(replyMode);
    const [profile, setProfile] = useState([false])
    const [reverse, setReverse] = useState(reverseMode)
    const [cacheMode, setCacheMode] = useState(cache)
    const [endLogoMode, setEndLogoMode] = useState(endLogo)
    const [following, setFollowing] = useState(profile.is_following);
    const [keyword, setKeyword] = useState(EasyCache("keyword", "", "bypass", ""));
    const [searchMode, setSearchMode] = useState(EasyCache("searchMode", "searchMode", "bypass", "post"));
    const { modal, submitFormMode, target, modalOpen, modalClose } = useModal();

    useEffect(() => {
        if (EasyCache("postListRenderer", postListKey, "json") === false) {
            EasyCache("postListRenderer", postListKey, "json", postList)
        }

        const fetchPosts = async () => {
            const data = EasyCache("postListRenderer", postListKey, "json")
            let response
            // セッションからページ状態を復旧する処理
            if (data && cacheMode) {
                console.log("セッションから復旧")
                response = data;
            } else {
                response = postList;
            }
            setPostList(response)
        };

        fetchPosts();
    }, [postList]);  // post_idが変更されたときに再度フェッチするように依存配列を設定

    useEffect(() => {
        window.addEventListener('beforeunload', () => {
            //sessionStorage.clear();
            console.log("リロード検知1")
            //EasyCache("profilePost", user_id, "delete")
            EasyCache("postListRenderer", postListKey, "delete")
        });

        return () => {
            window.removeEventListener('beforeunload', () => {
                //sessionStorage.clear();
                console.log("リロード検知2")
                //EasyCache("profilePost", user_id, "delete")
                EasyCache("postListRenderer", postListKey, "delete")

            });
        };
    }, []);

    const observer = useRef();
    const lastElementRef = useRef(null);


    useEffect(() => {
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                // ここに最後の要素が表示されたときの処理を書く
                console.log('あああ.');
                //console.log(reply)
                EasyCache("XSDER", "XSDER", "json", postlist)
                EasyCache("postListRenderer", postListKey, "json", postlist)

                // 新しい投稿をフェッチする
                fetchMorePosts();

            }
        });

        if (lastElementRef.current) {
            observer.current.observe(lastElementRef.current);
        }

        return () => {
            if (lastElementRef.current) {
                observer.current.unobserve(lastElementRef.current);
            }
        };
    }, [postlist.results]);  // reply.resultsが変更されたときに再度フェッチするように依存配列を設定


    function searchModeSwitchWrapper(mode) {
        search()
        setSearchMode(mode)
    }


    // 新しい投稿をフェッチするための非同期関数
    const fetchMorePosts = async () => {
        if (postlist.next) {
            const response = await getCursor(postlist.next);
            console.log('fetchMorePosts response:', response);
            console.log('fetchMorePosts response.results:', response.results);
            setPostList(prevReply => ({
                ...response,
                results: [...prevReply.results, ...response.results]
            }));
            //		if (response.next) {
            //			setReplyCursor(response.next)
            //	}
        }
    };

    const [favorited, setFavorited] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // loginUserは非同期処理であるが、処理が完了してからログインチェックを行わないとログインチェックに失敗する
        // そのためawaitで処理の完了を待つ必要がある
        console.log("何らかの検索ボタン")
        search()
    }

    return (
        <>
            <div className='postListView'>
                {/*こっちが親投稿のリプライを表示する部分 */}
                {!reverse &&
                    <>
                        {postlist.results?.map((post, index) => (
                            <React.Fragment key={index}>
                                <PostRender key={index} post={post} replyBorder={replyModeSelect}/>
                                {index === postlist.results.length - 2 && <div className='infinityScrollDummy' ref={lastElementRef}></div>}
                            </React.Fragment>
                        ))}
                    </>
                }
                {reverse &&
                    <>
                        {postlist.results?.slice().reverse().map((post, index) => (
                            <React.Fragment key={index}>
                                {index === 2 && <div className='infinityScrollDummy' ref={lastElementRef}></div>}
                                <PostRender key={index} post={post} replyBorder={replyModeSelect}/>
                            </React.Fragment>
                        ))}
                    </>
                }
                {endLogoMode && 0 !== postlist.results?.length &&
                    <img src={smallLogo} alt="" class="accentLogo" />
                }
            </div>
        </>
    );
}

export default Search;
