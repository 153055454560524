import React, { useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Welcome from "./components/pages/Wellcome/wellcome";
import Home from "./components/pages/Home/Home";
import GlobalTimeline from "components/pages/GlobalTimeline/GlobalTimeline";
import Bookmark from "components/pages/Bookmark/Bookmark";
import HomeTest from "./components/pages/Home/HomeTest";
import TokenTest from "./components/pages/Login/TokenTest";
import Mng from "./components/pages/Login/Login"
import Test from "./components/pages/Test/Test"
import PostSubmit from "./components/pages/PostSubmit";
//import PostView from "./components/pages/PostView/PostView";
import Signup from './components/pages/Signup/Signup';
import PostView from './components/pages/Post/Post';
import ProfileView from './components/pages/Profile/Profile';
import About from './components/pages/About/About';
import Preview from './components/pages/Preview/Preview'
import Mapview from './components/pages/Mapview/Mapview';
import Search from 'components/pages/Search/Search';
import FollowerView from 'components/pages/FollowerView/FollowerView'
import EngagementView from "components/pages/EngagementView/EngagementView"
import Notification from "components/pages/Notification/Notification"
import ChangePassword from "components/pages/ChangePassword/ChangePassword"
import ChangeEmail from "components/pages/ChangeEmail/ChangeEmail"
import APIDocs from "components/pages/APIDocs/APIDocs"
import Settings from "components/pages/Settings/Settings"
import Changelog from "components/pages/Changelog/Changelog"

import { SnackbarProvider } from 'components/blocks/GlobalSnackbar/SnackbarProvider';

let scrollPositions = {};

function useScrollRestoration() {
	const { pathname } = useLocation();

	useEffect(() => {
		// ページに戻ったときにスクロール位置を復元（存在しない場合はトップにスクロール）
		const scrollPosition = JSON.parse(sessionStorage.getItem(`scrollPosition_${pathname}`) || '0');
		window.scrollTo(0, scrollPosition);
		console.log("SPO:" + scrollPosition)
	}, [pathname]);
	//console.log("ANF:" + pathname)
}

const App = () => {
	return (
		<BrowserRouter>
			<SnackbarProvider>
				<ScrollRestoration />

				<link rel="stylesheet" type="text/css" href="http://fonts.googleapis.com/css?family=Ubuntu:regular,bold&subset=Latin"></link>
				<link href="http://fonts.googleapis.com/earlyaccess/notosansjp.css"></link>
				<Routes>
					<Route path='/' element={<Welcome />} />
					<Route path='/home' element={<Home />} />
					<Route path='/global' element={<GlobalTimeline />} />
					<Route path='/bookmark' element={<Bookmark />} />
					<Route path='/token-test' element={<TokenTest />} />
					<Route path='/login' element={<Mng />} />
					<Route path='/post-submit' element={PostSubmit} />
					<Route path='/post-view' element={<PostView />} />
					<Route path='/signup' element={<Signup />} />
					<Route path='/password_reset' element={<ChangePassword />} />
					<Route path='/change_email' element={<ChangeEmail />} />
					<Route path='/notification' element={<Notification />} />
					<Route path='/settings' element={<Settings />} />
					<Route path='/changelog' element={<Changelog />} />

					<Route path="/posts/:post_id/quote" element={<EngagementView mode={"quote"} />} />
					<Route path="/posts/:post_id/repost" element={<EngagementView mode={"repost"} />} />
					<Route path="/posts/:post_id/like" element={<EngagementView mode={"like"} />} />

					<Route path="/posts/:post_id" element={<PostView />} />
					<Route path="/posts/:post_id/preview/:media_num" element={<Preview />} />

					<Route path="/profile/:username/following" element={<FollowerView mode={"following"} />} />
					<Route path="/profile/:username/followers" element={<FollowerView mode={"followers"} />} />

					<Route path="/profile/:username/with_replies" element={<ProfileView mode={"reply"} />} />
					<Route path="/profile/:username/media" element={<ProfileView mode={"media"} />} />
					<Route path="/profile/:username/likes" element={<ProfileView mode={"favorite"} />} />
					<Route path="/profile/:username" element={<ProfileView mode={"post"} />} />
					<Route path="/about" element={<About />} />
					<Route path="/api1.0docs" element={<APIDocs />} />
					<Route path="/mapview" element={<Mapview />} />
					<Route path="/search" element={<Search />} />
				</Routes>
			</SnackbarProvider>
		</BrowserRouter>
	)
}

function ScrollRestoration() {
	useScrollRestoration();
	return null;
}

export default App