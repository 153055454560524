import { useParams } from 'react-router-dom';
import Header from "components/blocks/Header/Header"
import React, { useState, useEffect, useRef } from 'react';
import "./style.scss"

import PostRender from "components/blocks/PostsRenderer/PostsRenderer"

import { useIntelligentNavigate } from "common/api/APIUtils";

import Sidebar from "components/blocks/Sidebar/Sidebar"

import RightSidebar from "components/blocks/RightSidebar/RightSidebar"

import logo from "assets/img/conceptpdn-white-alpha.png"

function About() {
	const intelligentNavigate = useIntelligentNavigate();
	return (
		<>
			<div className="home">
				<Sidebar />
				<Header enableBack={true}/>

				<div class="mainContentBase">
					<div class="mainContent">
						<div className='mainLine'>
							<div className='aboutMessage'>
								<h1>Blackbird Client V1.1B</h1>
								<h3>Powered by React</h3>
								<p className='cursorPointer' style={{color: "#0080ff"}} onClick={() => intelligentNavigate("/changelog")}>チェンジログはこちらから</p>
								<p>API互換性 Blackbird API 1.0</p>
								<br/>
								<h3>Blackbirdへようこそ</h3>
								<p>本サービスは理想のソーシャルネットワーキングを目指して開発・運用されているサービスです。</p>

								<p>本サービスは現在テスト段階での公開です、よって大幅な変更や機能追加が行われるかもしれません。
									今後も成長を続けていくことをお約束いたしますため、どうか皆さまにはBlackbirdの成長を温かい目で見守っていただけると幸いです。
									Blackbird運営チームより🐈</p>
									<br/>
									<p>お問い合わせは以下のメールアドレスからお願いいたします。</p>
									<h4>nazo2004c@gmail.com</h4>
									<br/>
								<p>Copyright 2023 RavenDevelopTeam.</p>
							</div>
						</div>
						<RightSidebar />
					</div>
				</div>
			</div>
		</>
	);
}

export default About;
