function EasyCache(cacheName, cacheKey, type = false, data = false) {
	function returnGenerator(bypass = false) {
		const cacheCallKey = cacheName + ":" + cacheKey;
		data = sessionStorage.getItem(cacheCallKey);
		//sessionStorage.clear(cacheCallKey)
		if (data) {
			if (type === "json") {
				data = JSON.parse(data)
			}
			return data;
		} else {
			return bypass;
		}
	}

	const cacheCallKey = cacheName + ":" + cacheKey;
	//let bypassMode = false;
	if (type === "bypass") {
		//sessionStorage.clear(cacheCallKey)
		//bypassMode = true;
		return returnGenerator(data)
	}
	if (type === "delete") {
		//sessionStorage.clear(cacheCallKey)
        sessionStorage.removeItem(cacheCallKey)
        console.log("[EasyCache]>>"+cacheCallKey+"を削除しました")
	}
	if (data) {
		if (type === "json") {
			data = JSON.stringify(data)
		}
		sessionStorage.setItem(cacheCallKey, data);
		return true;
	} else {
		return returnGenerator()
	}
}

export default EasyCache;
/*function EasyCache({cacheName, cacheKey = "", type = false, data = false, }) {
	const cacheCallKey = cacheName + ":" + cacheKey;
	if (type === "delete") {
		//sessionStorage.clear(cacheCallKey)
        sessionStorage.removeItem(cacheCallKey)
        console.log("[EasyCache]>>"+cacheCallKey+"を削除しました")
	}
	if (data) {
		if (type === "json") {
			data = JSON.stringify(data)
		}
		sessionStorage.setItem(cacheCallKey, data);
		return true;
	} else {
		const cacheCallKey = cacheName + ":" + cacheKey;
		data = sessionStorage.getItem(cacheCallKey);
		//sessionStorage.clear(cacheCallKey)
		if (data) {
			if (type === "json") {
				data = JSON.parse(data)
			}
			return data;
		} else {
			return false;
		}
	}
}

export default EasyCache;*/