import { useParams, useNavigate } from 'react-router-dom';
import Header from "components/blocks/Header/Header"
import React, { useState, useEffect, useRef } from 'react';
import "./style.scss"

import PostRender from "components/blocks/PostsRenderer/PostsRenderer"

import Sidebar from "components/blocks/Sidebar/Sidebar"

import RightSidebar from "components/blocks/RightSidebar/RightSidebar"

import logo from "assets/img/conceptpdn-white-alpha.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import mediaSizeSwitch from "components/blocks/mediaSizeSwitch/mediaSizeSwitch"

import { useIntelligentNavigate, getPost, getReply, getCursor } from "common/api/APIUtils"

import EasyCache from 'components/blocks/EasyCache/EasyCache';

function PostView() {
    let { post_id } = useParams();
    let { media_num } = useParams();

    const intelligentNavigate = useIntelligentNavigate();
    const navigate = useNavigate();

    //const [treePosts, setTreePosts] = useState([false]);

    const [posts, setPosts] = useState(EasyCache(post_id, "post", "json"));

    const [reply, setReply] = useState(EasyCache(post_id, "reply", "json"));

    const [media_count, setmedia_count] = useState();


    //const [replyCursor, setReplyCursor] = useState("");

    useEffect(() => {
        window.addEventListener('beforeunload', () => {
            //sessionStorage.clear();
            console.log("リロード検知1")
            EasyCache(post_id, "post", "delete")
            EasyCache(post_id, "reply", "delete")
        });

        return () => {
            window.removeEventListener('beforeunload', () => {
                //sessionStorage.clear();
                console.log("リロード検知2")
                EasyCache(post_id, "post", "delete")
                EasyCache(post_id, "reply", "delete")
            });
        };
    }, []);


    useEffect(() => {
        const fetchPosts = async () => {
            //const response = await getPost(post_id);

            const data = EasyCache(post_id, "post", "json")
            let response
            // セッションからページ状態を復旧する処理
            if (data) {
                console.log("セッションから復旧")
                response = data
            } else {
                //response = await getProfile(user_id);
                response = await getPost(post_id);

            }
            EasyCache(post_id, "post", "json", response)
            console.log("ポスト取得dSE")
            console.log(response)
            console.log("ポスト取得d")
            console.log(response);
            console.log(post_id)
            setPosts(response);  // レスポンスをステートにセット
            setmedia_count(response[0].content?.post?.media_links.length)
            //            console.log("れんぐぐぐぐ")
            //            console.log(response[0].content?.post?.media_links.length)
            //setReplyCursor(response.next)
            //setReplyCursor("STR")
            console.log("投稿のキャッシュ")
            console.log(EasyCache(post_id, "post", "json"))
        };

        fetchPosts();
    }, [post_id]);  // post_idが変更されたときに再度フェッチするように依存配列を設定

    useEffect(() => {
        const fetchPosts = async () => {
            //const response = await getReply(post_id);
            const data = EasyCache(post_id, "reply", "json")
            let response
            // セッションからページ状態を復旧する処理
            if (data) {
                console.log("セッションから復旧")
                response = data
            } else {
                //response = await getProfile(user_id);
                response = await getReply(post_id);
            }
            console.log("ポスト取得!!")
            console.log(response);
            console.log(post_id)
            setReply(response);  // レスポンスをステートにセット
            console.log("response")
            console.log(response.next)

            //	if (response.next) {
            //			setReplyCursor(response.next)
            //		}

            //	console.log("replyCursor1:", replyCursor)
            //	setReplyCursor("STR")
            //	console.log("replyCursor1.1:", response.next)
            //		console.log("replyCursor1.2:", replyCursor)
        };

        fetchPosts();
    }, [post_id]);  // post_idが変更されたときに再度フェッチするように依存配列を設定

    const observer = useRef();
    const lastElementRef = useRef(null);

    useEffect(() => {
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                // ここに最後の要素が表示されたときの処理を書く
                console.log('あああ.');
                EasyCache(post_id, "reply", "json", reply)

                // 新しい投稿をフェッチする
                fetchMorePosts();
            }
        });

        if (lastElementRef.current) {
            observer.current.observe(lastElementRef.current);
        }

        return () => {
            if (lastElementRef.current) {
                observer.current.unobserve(lastElementRef.current);
            }
        };
    }, [reply.results]);  // reply.resultsが変更されたときに再度フェッチするように依存配列を設定

    // 新しい投稿をフェッチするための非同期関数
    const fetchMorePosts = async () => {
        //console.log("replyCursor!:", replyCursor)
        if (reply.next) {
            const response = await getCursor(reply.next);
            console.log('fetchMorePosts response:', response);
            console.log('fetchMorePosts response.results:', response.results);
            setReply(prevReply => ({
                ...response,
                results: [...prevReply.results, ...response.results]
            }));
            //		if (response.next) {
            //			setReplyCursor(response.next)
            //	}
        }
    };

    const [favorited, setFavorited] = useState(false);
    function flattenRepliedPostTree(tree) {
        let result = [];
        if (tree && tree.post_id) {
            result = result.concat(flattenRepliedPostTree(tree.reply));
            result.push(tree);

            //if (tree.reply) {
            //	result = result.concat(flattenRepliedPostTree(tree.reply));
            //}
        }
        return result;
    }

    function mediaScrollLimit(dirrection) {
        let next_media_num;
        if (dirrection == "back") {
            next_media_num = Number(media_num) - 1;
        } else {
            next_media_num = Number(media_num) + 1;
        }
        if (-1 < next_media_num && next_media_num < media_count) {
            return true
        } else {
            return false
        }
    }

    function mediaCountController(dirrection) {
        //media_count
        //media_num

        let next_media_num;
        if (dirrection == "back") {
            next_media_num = Number(media_num) - 1;
        } else {
            next_media_num = Number(media_num) + 1;
        }

        console.log(next_media_num)
        console.log(dirrection == "back")

        if (-1 < next_media_num && next_media_num < media_count) {
            //navigate('/posts/' + post_id + '/preview/' + next_media_num);
            navigate('/posts/' + post_id + '/preview/' + next_media_num, { replace: true })
        } else {
            next_media_num = media_num
        }
    }

    return (
        <>
            <div className="universalPreviewRoot">
                <Header enableBack={true}/>

                {console.log("ぽすとおお")}
                {console.log(posts[0]?.content?.post?.media_links[media_num])}

                <div class="mainContentBase">
                    <div class="mainContent">
                        <div className='previewContent' onClick={() => {navigate(-1)}}>
                            <img src={posts[0] && mediaSizeSwitch(posts[0]?.content?.post?.media_links[media_num], "large")} onClick={(e) => { e.stopPropagation() }}></img>
                            <div className='mediaCountController'>
                                <div className='leftButton' onClick={(e) => { e.stopPropagation(), mediaCountController("back") }}>
                                    {mediaScrollLimit("back") && <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>}
                                </div>
                                <div className='rightButton' onClick={(e) => { e.stopPropagation(), mediaCountController("next") }}>
                                    {mediaScrollLimit("next") && <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default PostView;
