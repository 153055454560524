import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { LoginManagerWrapper, UnkX, accessTokenCheck } from "common/api/APIUtils"

import { BrowserRouter, Route, Routes, Link, useNavigate, Navigate } from 'react-router-dom';

import { loginUser } from "common/api/APIUtils"
import logo from "assets/img/conceptpdn-white-alpha.png"

import { useIntelligentNavigate } from "common/api/APIUtils"

import "./style.scss";
import { SnackbarContext } from 'components/blocks/GlobalSnackbar/SnackbarProvider';

const LoginForm = () => {
	const navigate = useNavigate();
	const openSnackbar = useContext(SnackbarContext);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [loginAttempt, setLoginAttempt] = useState(0);
	const [showPassword, setShowPassword] = useState(false);
	const [prosessing, setProsessing] = useState(false);
	const intelligentNavigate = useIntelligentNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		// loginUserは非同期処理であるが、処理が完了してからログインチェックを行わないとログインチェックに失敗する
		// そのためawaitで処理の完了を待つ必要がある
		setProsessing(true)
		let result = await loginUser(username, password);
		setProsessing(false)

		if (result == true) {
			openSnackbar("ログイン成功")
			intelligentNavigate("/home")
		} else {
			openSnackbar("ログインに失敗しました: " + result.message, 'error')
			if (result?.response?.status === 401) {
				openSnackbar("ユーザー名またはパスワードが間違えています", "error")
			}
		}


		//return (<h1>TTTTTSE</h1>);
		//return <LoginManagerWrapper />
		//navigate("/login")
		// const root = createRoot(document.body);
		// const divFoo = <LoginManagerWrapper />;
		// root.render(divFoo);
		setLoginAttempt(loginAttempt + 1);
		console.log("aaa" + await accessTokenCheck())

	};

	return (
		<div className='blackbirdLoginFormRoot'>
			<div class="container">
				<LoginManagerWrapper key={loginAttempt} />
				<img src={logo} alt="" class="logo" />
				<div class="elements">
					<p class="text" id="title">Blackbirdにログイン</p>
				</div>
				<form className='form' onSubmit={handleSubmit}>

					<input
						type="text"
						required
						id="username"
						placeholder="ユーザー名"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>


					<input
						type={showPassword ? "text" : "password"}
						required
						id="password"
						placeholder="パスワード"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<div className='checkboxXXX'>
						<input type="checkbox" id="showPassword" onClick={() => { setShowPassword(!showPassword); }} />
						<label for="showPassword">パスワードの表示</label>
					</div>
					<div class="elements">
						{prosessing ?
							<div id="loginbuttonDummy">ログイン中...</div>
							:
							<button type="submit" id="loginbutton">ログイン</button>
						}
					</div>
					<p class="text" className='cursorPointer' onClick={() => { intelligentNavigate("/password_reset") }}>パスワードを変更する</p>
				</form>
				<div class="wellcomeGuid">
					<p class="text" onClick={() => { intelligentNavigate("/global") }}>タイムラインを覗いてみる</p>
					<p class="text" onClick={() => { intelligentNavigate("/signup") }}>アカウントを作成</p>
				</div>
			</div>
			<div className='loginFormRightMessage'>
			</div>
		</div>
	);
};

export default LoginForm;
