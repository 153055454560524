import { useParams } from 'react-router-dom';
import Header from "components/blocks/Header/Header"
import React, { useState, useEffect, useRef } from 'react';
import "./style.scss"

import PostRender from "components/blocks/PostsRenderer/PostsRenderer"

import Sidebar from "components/blocks/Sidebar/Sidebar"

import RightSidebar from "components/blocks/RightSidebar/RightSidebar"

import logo from "assets/img/conceptpdn-white-alpha.png"

function About() {
	return (
		<>
			<div className="home">
				<Sidebar />
				<Header enableBack={true} />

				<div class="mainContentBase">
					<div class="mainContent">
						<div className='mainLine'>
							<div className='changelogMessage'>
								<h1 className='outlineMessage'>Blackbird Client Changelog</h1>
								<h4 className='outlineMessage'>あまり更新されないかもしれないチェンジログです</h4>
								<div>
									<h2>2024/04/22</h2>
									<ul>
										<li>ホイールクリックで新しいタブを開く機能の追加</li>
										<li>テキスト選択で投稿を開いてしまう動作の修正</li>
										<li>画像が投稿できないバグの修正</li>
									</ul>
								</div>
								<div>
									<h2 className='outlineMessage'>バージョン別</h2>
								</div>
								<div>
									<h2>Blackbird Client 1.1</h2>

									<ul>
										<li>Email認証機能の追加</li>
										<li>iOS系端末のブラウザで正常に動作しない不具合の修正</li>
										<li>投稿画面css修正</li>

										<li>通知のバグ修正</li>
										<li>ボタンの表記名の修正</li>
										<li>welcomeページの挙動変更</li>

										<li>ログイン完了ページの追加やホームタイムラインのバグ修正、ビルド更新</li>

										<li>メール認証機能の追加、iOS環境で動作しない不具合を修正、タッチ環境でのscroll reloadの追加</li>

										<li>APIDocsの追加やAboutのEmailの変更など</li>
										<li>文字数制限を示すバーを実装</li>
									</ul>
								</div>
								<div>
									<h2>Blackbird Client 1.0</h2>
									<span>最低限SNSとして使えるようにしたひな型であるバージョン。</span>
									<br />
									<span>初版であるが故変更点が多すぎて書ききれていないですが、思い出せる範囲でだけ書き残しておきます。</span>
									<ul>
										<li>返信先表示が異なるバグの修正</li>

										<li>APIDocsの追加やAboutのEmailの変更など</li>
										<li>文字数制限を示すバーを実装</li>
										<li>返信先表示が異なるバグの修正</li>

										<li>通知実装</li>
										<li>Searchの細かなバグ修正</li>

										<li>検索機能のクエリパラメーター対応実装</li>
										<li>ユーザープロフィールビューのひな型を作成</li>
										<li>投稿フォーム追加、ユーザープロフィール画面調整</li>
										<li>投稿作成機能実装</li>
										<li>プレビュー表示やアカウント作成画面を実装</li>
										<li>フォロワービューの追加、エンゲージメントビューの追加、ポストビューリストコンポーネントの作成</li>
										<li>グローバルページ追加</li>
										<li>ゲストモード追加</li>
										<li>タグ表示機能の実装</li>
										<li>ポップアップメッセージ機能実装</li>
										<li>ログイン関係のバグ修正</li>
										<li>色々追加！</li>
									</ul>

								</div>
								<p className='outlineMessage'>Copyright 2023 RavenDevelopTeam.</p>
							</div>
						</div>
						<RightSidebar />
					</div>
				</div>
			</div>
		</>
	);
}

export default About;
