import { Link, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
//import Sidebar from "./Sidebar";
import "./style.scss"
import logo from "assets/img/conceptpdn-white-alpha.png"
import Header from "components/blocks/Header/Header"
import Sidebar from "components/blocks/Sidebar/Sidebar"
import RightSidebar from "components/blocks/RightSidebar/RightSidebar"
import PostRender from "components/blocks/PostsRenderer/PostsRenderer"
import EasyCache from 'components/blocks/EasyCache/EasyCache';
import EasySnackbar from 'components/blocks/EasySnackbar/EasySnackbar'
import { getTimeLine, TokenAuthManager, UserAuthCheckManager, LoginManagerWrapper, PostFavorite, GetEngagement, DeleteFavorite, getCursor, GetFollowing, GetFollower, GetFavorite, GetRepost, GetQuote, GetNotification } from "../../../common/api/APIUtils"
import UserListRenderer from "components/blocks/UserListRenderer/UserListRenderer"
import { useIntelligentNavigate } from "common/api/APIUtils"
import PostListRender from "components/blocks/PostListRenderer/PostListRenderer"
import NotificationRender from "components/blocks/NotificationRenderer/NotificationRenderer"
import NotificationListRenderer from "components/blocks/NotificationListRenderer/NotificationListRenderer"
import PullToRefresh from "components/blocks/PullToRefresh/PullToRefresh"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from '@fortawesome/free-solid-svg-icons';

let posts
if (await TokenAuthManager()) {
    console.log("認証成功")
    //	let timelines = await getTimeLine()
    //	console.log(timelines)
    //posts = 
}

const FollowerView = ({ mode }) => {
    let { post_id } = useParams();
    const intelligentNavigate = useIntelligentNavigate();

    const [easySnackbarMessage, setEasySnackbarMessage] = useState();
    const [displayContent, setDisplayContent] = useState();
    const [displayPostContent, setDisplayPostContent] = useState();
    const [showMode, setShowMode] = useState("all");

    useEffect(() => {
        //EasyCache("searchMode", "searchMode", "val", searchMode)
        const fetchPosts = async () => {

            if (showMode === "all") {
                let result = await GetNotification()
                console.log("CSR")
                console.log(result)
                setDisplayPostContent(result)
            }
        };
        fetchPosts();
    }, [showMode]);  // reply.resultsが変更されたときに再度フェッチするように依存配列を設定

    function searchModeSwitchWrapper(mode) {
        if (mode === "quote") {
            setShowMode("quote")
            intelligentNavigate("quote", true)
        }
        if (mode === "repost") {
            setShowMode("repost")
            intelligentNavigate("repost", true)
        }
        if (mode === "like") {
            setShowMode("like")
            intelligentNavigate("like", true)
        }
    }

    return (
        <>
            <div className="home">
                <UserAuthCheckManager />
                <Sidebar />
                <Header enableBack={true} />
                <EasySnackbar message={easySnackbarMessage} />

                <div class="mainContentBase">
                    <div class="mainContent">
                        <div class="mainLine" id="mainlinnn">
                            <PullToRefresh onRefresh={() => location.reload()}>
                                {0 === displayPostContent?.results?.length &&
                                    <div className='post'>
                                        <div className='loginWarn'>
                                            <div>
                                                <FontAwesomeIcon icon={faBell} />
                                                <br />
                                                通知はまだ来ていないようです
                                            </div>
                                        </div>
                                    </div>
                                }
                                {displayContent && (showMode === "repost" || showMode === "like") && <UserListRenderer importUserList={displayContent} />}
                                {displayPostContent && (showMode === "quote") && <PostListRender postList={displayPostContent} />}
                                {displayPostContent &&
                                    <NotificationListRenderer postList={displayPostContent} />
                                }
                            </PullToRefresh>
                        </div>
                        <RightSidebar />
                    </div>
                </div>
            </div>
        </>
    );
};

export default FollowerView;