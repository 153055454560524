import React, { useState, useEffect, useRef } from 'react';

import { getTimeLine, TokenAuthManager, UserAuthCheckManager, LoginManagerWrapper, PostFavorite, GetEngagement, DeleteFavorite, PostRepost, DeleteRepost, useIntelligentNavigate, DeletePost } from "common/api/APIUtils"


import logo from "assets/img/conceptpdn-white-alpha.png"
import { useNavigate } from 'react-router-dom';

// get our fontawesome imports
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import mediaSizeSwitch from "components/blocks/mediaSizeSwitch/mediaSizeSwitch"

import "./style.scss"

// 投稿を表示するためのコンポーネント
const EasySnackbar = ({ message = false }) => {
    
    const intelligentNavigate = useIntelligentNavigate();
    const navigate = useNavigate();

        return (
            <div className={message ? 'easySnackbarFrame' : 'easySnackbarFrameHide'}>
                <div className='easySnackbar'>
                    {message}
                </div>
            </div>
        );
}

export default EasySnackbar;