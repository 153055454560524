import React, { useEffect, useState, useRef } from 'react'
import { useMap, useMapEvent, useMapEvents, Marker, Popup } from 'react-leaflet'
import L from 'leaflet';
import markerIconUrl from './Icon/bird.png';
import centerIconUrl from './Icon/target.png';
import { getTimeLine, getCursor, getGlobalTimeLine, getGlobalTimeLinePosOnly } from './../../../common/api/APIUtils';
import { localData } from './localData/localData';

import PostRender from "components/blocks/PostsRenderer/PostsRenderer"
//import EasyCache from '../EasyCache/EasyCache';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

// 現在地の取得
const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    });
};

//初回起動時のデータ
export const startup = {
    pos: [],
    zoom: 18,
    iconSize: 45,
};

// 初期位置を現在地に設定
getCurrentPosition().then((position) => {
    startup.pos = [position.coords.latitude, position.coords.longitude];
}).catch((err) => {
    console.error(err.message);
    // 現在地の取得に失敗した場合はデフォルトの位置を設定
    startup.pos = [34.985, 135.753];
});



//マップのセットアップデータ
const corner1 = L.latLng(-78.0, -105.0);
const corner2 = L.latLng(100.0, 430.0);
const bounds = L.latLngBounds(corner1, corner2);
export const setup = {
    bounds: bounds,
    minZoom: 3,
    maxZoom: 18,
};


//センターマーカーアイコンの設定
const CenterIcon = () => {
    return L.icon({
        iconUrl: centerIconUrl,
        iconSize: [40, 40],
        iconAnchor: [20, 20]
    })
}
//センターマーカーの設置
//マップにインポートして使用
export function SetCenterMarker() {
    const [position, setPosition] = useState(startup.pos);
    const map = useMapEvent({
        move() {
            setPosition(map.getCenter());
        },
    })
    return (
        <Marker position={position} icon={CenterIcon()} />
    )
}


//投稿時に中心座標の取得
//ページにインポートして使用
export let pos = startup.pos;
//マップの中心座標を取得
//マップにインポートして使用
export function GetCenter() {
    const map = useMap();

    // GetCenterが呼び出された時点での中心座標を取得
    pos = [];
    pos.push(parseFloat(map.getCenter().lat.toFixed(6)));
    pos.push(parseFloat(map.getCenter().lng.toFixed(6)));

    useMapEvent({
        load() {
            pos = [];
            pos.push(parseFloat(map.getCenter().lat.toFixed(6)));
            pos.push(parseFloat(map.getCenter().lng.toFixed(6)));
        },
        moveend() {
            pos = [];
            pos.push(parseFloat(map.getCenter().lat.toFixed(6)));
            pos.push(parseFloat(map.getCenter().lng.toFixed(6)));
        }
    })
}



//現在地へ移動
//マップにインポートして使用
export function CurrentLocation() {
    const [position, setPosition] = useState(null)
    const map = useMapEvents({
        dblclick() {
            map.locate()
        },
        locationfound(e) {
            setPosition(e.latlng)
            console.log(e.latlng)
            map.flyTo(e.latlng, map.getZoom())
        },
    })
    return position === null ? null : (
        <Marker position={position}>
            <Popup>You are here</Popup>
        </Marker>
    )
}


//投稿アイコンの設定
const postIcon = (size) => {
    return L.icon({
        iconUrl: markerIconUrl,
        iconSize: [size, size],
        className: "postIcon"
    })
}
//投稿アイコンの表示サイズの変更
//倍率とアイコンサイズの対応表、倍率7以下は表示しない
const iconSizeList = [
    { zoom: 18, size: 45 },
    { zoom: 17, size: 33 },
    { zoom: 16, size: 23 },
    { zoom: 15, size: 20 },
    { zoom: 14, size: 20 },
    { zoom: 13, size: 20 },
    { zoom: 12, size: 20 },
    { zoom: 11, size: 20 },
    { zoom: 10, size: 20 },
    { zoom: 9, size: 20 },
    { zoom: 8, size: 20 },
    { zoom: 7, size: 20 },
    { zoom: 6, size: 20 },
    { zoom: 5, size: 20 },
    { zoom: 4, size: 20 },
    { zoom: 3, size: 20 },
    { zoom: 2, size: 20 },
    { zoom: 1, size: 20 },
];
//アイコンサイズを決める関数
function scaleCalIconSize(currentZoom) {
    let iconSize;
    let isMatch = false;
    //対応表を参照
    for (let i = 0; i < iconSizeList.length; i++) {
        if (currentZoom === iconSizeList[i].zoom) {
            iconSize = iconSizeList[i].size;
            isMatch = true;
            break;
        }
    }
    //対応表にマッチしなかった場合、0をセット
    if (isMatch === false) {
        iconSize = 0;
    }
    return (
        iconSize
    )
}


//日付でフィルターする関数
function dateFilter(filteringData) {
    //フィルター後のデータを格納する配列
    var filteredData = [];
    //フォームから値を取得
    let startDate = document.getElementById("startDate").value;
    let endDate = document.getElementById("endDate").value;
    //値が空欄の時に代入
    if (startDate === "") startDate = "0000-00-00";
    if (endDate === "") endDate = "999999-99-99";
    //開始日の方が終了日より後の場合、エラーを表示
    if (startDate > endDate) {
        alert("error:日付の入力に誤りがあります");
    } else {
        for (let i = 0; i < filteringData.length; i++) {
            //指定した日付の範囲内か判定
            if (filteringData[i].date >= startDate && filteringData[i].date <= endDate) {
                filteredData.push(filteringData[i]);
            }
        }
    }
    return (
        //フィルター後の配列を返す
        filteredData
    )
}


//posが空のデータをフィルターする関数
function blankPosFilter(filteringData) {
    if (filteringData && filteringData.results) {
        return filteringData.results.filter((post) => {
            return post && post.content && post.content.post && post.content.post.location && post.content.post.location.lat;
        });
    }
    return [];
}


//マップのレンダリング関数
export function RenderPostIcon() {

    //投稿データを取得
    const [timeline, setTimeline] = useState("");
    useEffect(() => {
        const fetchPosts = async () => {
            //const data = EasyCache("mapView", "mapView", "json")
            let response
            // セッションからページ状態を復旧する処理

            //response = await getProfile(user_id);
            response = await getGlobalTimeLinePosOnly();

            setTimeline(response);  // レスポンスをステートにセット

            if (response.length === 0 || response[0] === false) {
                await getGlobalTimeLinePosOnly();
            }
        };

        fetchPosts();
    }, []);


    // 新しい投稿をフェッチするための非同期関数
    const fetchMorePosts = async () => {
        if (timeline.next) {
            console.log("新しい投稿をフェッチ");
            const response = await getCursor(timeline.next);
            setTimeline({
                ...response,
                results: [...response.results]
            });
        }
    };


    useEffect(() => {
        let count = 0;  // 実行回数を追跡するカウンター

        const interval = setInterval(() => {
            if (count < 50) {  // 最大50回まで実行
                fetchMorePosts();
                map.fireEvent('customEvent');
                console.log("CZ" + currentZoom)
                setCurrentZoom(10)
                count += 1;
            } else {
                clearInterval(interval);  // 最大回数に達したらインターバルをクリア
            }
        }, 1000);  // 1000ミリ秒（1秒）ごとに新しい投稿をフェッチ

        return () => clearInterval(interval);  // コンポーネントのアンマウント時にインターバルをクリア
    }, [fetchMorePosts]);


    //取得した投稿データから座標がないデータをフィルターし、表示用の配列に入れる
    const [mappingData, setMappingData] = useState([]);
    useEffect(() => {
        console.log(timeline);
        const filtered = blankPosFilter(timeline);
        filtered.forEach(post => {
            mappingData.push(post);
        });
        console.log(mappingData);
    }, [timeline]);


    //ズームサイズを変更時、取得
    const [currentZoom, setCurrentZoom] = useState(startup.zoom);
    const map = useMapEvent({
        zoomend() {
            setCurrentZoom(map.getZoom());
        }
    })
    //ズームサイズが変更時、アイコンサイズが更新
    const [iconSize, setIconSize] = useState(startup.iconSize);
    useEffect(() => {
        setIconSize(scaleCalIconSize(currentZoom));
    }, [currentZoom])

    return (
        <>
            {
                //投稿をマップに表示
                mappingData && mappingData.map((postData, index) => (
                    <Marker key={postData?.content?.post?.post_id} position={postData?.content?.post?.location} icon={postIcon(iconSize)}>
                        <Popup>
                            <React.Fragment key={index}>
                                <PostRender key={index} post={postData} />

                            </React.Fragment>
                        </Popup>
                    </Marker>
                ))
            }
        </>
    )
}


//入力地名を検索し、座標を返す関数
//keyword：入力した検索のキーワード
function searchLocation() {
    let keyword = document.getElementById("searchLocalinp").value;
    //座標を返す用
    let returnPos;
    //localDataを参照
    for (let i = 0; i < localData.length; i++) {
        if (localData[i].name.match(keyword)) {
            returnPos = localData[i].pos;
            break;
        }
    }
    return (
        returnPos
    )
}


//指定した座標に移動する関数
export function MoveLocation() {

    //検索後の座標を保持
    const [searchedPos, setSearchedPos] = useState(null);

    //地名検索ボタンをクリック
    const moveLocationbtn = () => {
        setSearchedPos(searchLocation());
    }

    //マップ移動
    const map = useMap();
    useEffect(() => {
        if (map && searchedPos) {
            map.flyTo(searchedPos);
        }
    }, [map, searchedPos]);

    //フォームにカーソルがある状態でEnterを押すとボタンをクリック
    const myButton = useRef(null);
    const keyDownEnter = (event) => {
        if (event.key === 'Enter') {
            myButton.current.click();
        }
    }

    return (
        <>
            {/*場所検索フォーム*/}
            <div id="searchLocalForm">
                <input type="text" id="searchLocalinp" placeholder=" 地名や場所を入力" onKeyDown={keyDownEnter} />
                <button id="searchLocalbtn" ref={myButton} onClick={moveLocationbtn}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
            </div>
        </>
    )
}