import { useState, useEffect } from "react";
import "./style.scss"
import { useIntelligentNavigate } from "common/api/APIUtils"

import { getTrend } from "common/api/APIUtils";
function RightSidebar() {
    const intelligentNavigate = useIntelligentNavigate();
    const [trend, setTrend] = useState("");

    //天気の変換する
    function changeWeatherCode(code) {
        if (code === 0) return "快晴"
        if (code === 1) return "晴れ"
        if (code === 2) return "晴れ時々曇り"
        if (code === 3) return "曇り"
        if (code <= 49) return "霧"
        if (code <= 59) return "霧雨"
        if (code <= 69) return "雨"
        if (code <= 79) return "雪"
        if (code <= 84) return "俄か雨"
        if (code <= 94) return "雪・雹"
        if (code <= 99) return "雷雨"
        return "不明"
    }


    // 現在地の取得
    const [location, setLocation] = useState('');
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }, []);


    // 天気を取得
    const [weatherAPI, setWeatherAPI] = useState(''); //APIから取得したデータを格納
    const [weather, setWeather] = useState(''); //weather_codeを文字に変換したデータを格納

    const fetchWeather = () => {
        const weatherUrl = 'https://api.open-meteo.com/v1/forecast?latitude=' + location.latitude + '&longitude=' + location.longitude + '&current=temperature_2m,weather_code&daily=temperature_2m_max,temperature_2m_min&forecast_days=1';
        fetch(weatherUrl)
            .then(response => response.json())
            .then(json => setWeatherAPI(json))
    }
    useEffect(() => {
        fetchWeather();
    }, [location]);

    useEffect(() => {
        if (weatherAPI.daily) {
            setWeather(changeWeatherCode(weatherAPI.current.weather_code));
        }
    }, [weatherAPI]);


    useEffect(() => {
        const fetchPosts = async () => {
            const response = await getTrend();

            //console.log("ポスト取得!!")
            //console.log(response);
            //console.log(user_id)
            console.log("トレンド")
            console.log(response)
            setTrend(response);  // レスポンスをステートにセット
        };

        fetchPosts();
    }, []);  // post_idが変更されたときに再度フェッチするように依存配列を設定


    //犬の画像を表示
    const [imageSrc, setImageSrc] = useState('');
    const fetchDogImage = () => {
        fetch('https://dog.ceo/api/breeds/image/random')
            .then(response => response.json())
            .then(myJson => {
                setImageSrc(myJson.message);
            });
    };
    useEffect(() => {
        fetchDogImage();
        const timer = setInterval(fetchDogImage, 60000);
        return () => clearInterval(timer); // cleanup on unmount
    }, []);

    return (
        <div class="rightSidebar">
            <div className="searchbox cursorPointer" onClick={() => intelligentNavigate("/search")}>
                <span>検索開始...</span>
            </div>
            <div className='dogImage'>
                <button className="cursorPointer" onClick={fetchDogImage}>
                    {imageSrc && <img src={imageSrc} alt="Dog" />}
                </button>
            </div>
            <div className="weather">
                {weatherAPI.current &&
                    <>
                        <p>現在の天気は{weather}、気温は{weatherAPI.current.temperature_2m}°です。
                            <br />今日の最高気温は{weatherAPI.daily.temperature_2m_max}°、最低気温は{weatherAPI.daily.temperature_2m_min}°です。</p>
                    </>
                }
            </div>
            <div className="feed">
                <span className="feedTitle">トレンド</span>

                <div className="trendList">
                    {trend?.trend?.map((trend, index) => (
                        <div onClick={() => intelligentNavigate("/search/?tag=" + trend.tag)}>
                            <span className="tag">#{trend.tag}</span>
                            <span className="tagCount">{trend.count}投稿</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default RightSidebar;