import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Header from "components/blocks/Header/Header"
import React, { useState, useEffect, useRef, useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss"

import { useIntelligentNavigate } from "common/api/APIUtils"
import PostRender from "components/blocks/PostsRenderer/PostsRenderer"

import UserRenderer from "components/blocks/UserRenderer/UserRenderer"

import { getTrend } from "common/api/APIUtils";

import Sidebar from "components/blocks/Sidebar/Sidebar"

import RightSidebar from "components/blocks/RightSidebar/RightSidebar"

import logo from "assets/img/conceptpdn-white-alpha.png"

import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faWind } from "@fortawesome/free-solid-svg-icons";
import { faUserInjured } from "@fortawesome/free-solid-svg-icons";
import { faPlaneDeparture } from "@fortawesome/free-solid-svg-icons";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";

import { UserAuthCheckManager, getPost, getReply, getCursor, getProfile, getProfilePosts, UserFollow, UserUnfollow, SearchKeyword, SearchUser } from "common/api/APIUtils"
import EasyCache from 'components/blocks/EasyCache/EasyCache';
import { useModal, ModalBox } from 'components/blocks/ProfileEditor/ProfileEditor';
import { SnackbarContext } from 'components/blocks/GlobalSnackbar/SnackbarProvider';



function Search() {
	//let { user_id } = useParams();

	//const [treePosts, setTreePosts] = useState([false]);
	const openSnackbar = useContext(SnackbarContext);
	const [posts, setPosts] = useState([false]);
	const [resultdata, setResultData] = useState("");
	const [userlist, setUserlist] = useState("");
	const [profile, setProfile] = useState([false])
	const [following, setFollowing] = useState(profile.is_following);
	const [keyword, setKeyword] = useState("");
	const [searchMode, setSearchMode] = useState();
	const { modal, submitFormMode, target, modalOpen, modalClose } = useModal();
	const location = useLocation();
	const navigate = useNavigate();
	const [queryParams, setQueryParams] = useState(new URLSearchParams(location.search));

	useEffect(() => {
		// URLのクエリパラメータを取得
		const searchParams = new URLSearchParams(location.search);
		const keyword = searchParams.get('q');
		const hashtag = searchParams.get('tag');
		//const filter = searchParams.get('filter');
		let mode = searchParams.get('type');
		setSearchMode(mode)
		if (mode === null) {
			setSearchMode("post")
			mode = "post"
		} else {
			setSearchMode(mode)
		}
		if (keyword !== null) {
			setKeyword(keyword)
			search(mode, keyword)
		}
		if (hashtag !== null) {
			setKeyword("#" + hashtag)
			search(mode, "#" + hashtag)
		}
	}, [location.search]);

	useEffect(() => {
		window.addEventListener('beforeunload', () => {
			//sessionStorage.clear();
			console.log("リロード検知1")
			//EasyCache("profilePost", user_id, "delete")
		});

		return () => {
			window.removeEventListener('beforeunload', () => {
				//sessionStorage.clear();
				console.log("リロード検知2")
				//EasyCache("profilePost", user_id, "delete")
			});
		};
	}, []);

	const observer = useRef();
	const lastElementRef = useRef(null);

	useEffect(() => {
		if (observer.current) observer.current.disconnect();

		observer.current = new IntersectionObserver(entries => {
			if (entries[0].isIntersecting) {
				// ここに最後の要素が表示されたときの処理を書く
				//console.log('あああ.E');
				//console.log(resultdata)
				//EasyCache("searchPost", "searchPost", "json", resultdata)

				// 新しい投稿をフェッチする
				fetchMorePosts();

			}
		});

		if (lastElementRef.current) {
			observer.current.observe(lastElementRef.current);
		}

		return () => {
			if (lastElementRef.current) {
				observer.current.unobserve(lastElementRef.current);
			}
		};
	}, [resultdata.results]);  // resultdata.resultsが変更されたときに再度フェッチするように依存配列を設定

	useEffect(() => {
		if (observer.current) observer.current.disconnect();

		observer.current = new IntersectionObserver(entries => {
			if (entries[0].isIntersecting) {
				// ここに最後の要素が表示されたときの処理を書く
				//console.log('あああ.');
				//console.log(resultdata)
				//EasyCache("userList", "userList", "json", userlist)

				// 新しい投稿をフェッチする
				fetchMorePosts();

			}
		});

		if (lastElementRef.current) {
			observer.current.observe(lastElementRef.current);
		}

		return () => {
			if (lastElementRef.current) {
				observer.current.unobserve(lastElementRef.current);
			}
		};
	}, [userlist.results]);  // resultdata.resultsが変更されたときに再度フェッチするように依存配列を設定


	useEffect(() => {
		//EasyCache("searchMode", "searchMode", "val", searchMode)
		//setUserlist("")
		//setResultData(EasyCache("searchPost", "searchPost", "json"))
		//setResultData("")

		//setKeyword("")
		//setKeyword("")
		//EasyCache("userList", user_id, "delete")
		//EasyCache("profilePost", user_id, "delete")
	}, [searchMode]);  // resultdata.resultsが変更されたときに再度フェッチするように依存配列を設定

	function searchModeSwitchWrapper(mode) {
		//setSearchMode(mode)
		//search(mode, keyword)
		searchQueryWrapper("", mode)
	}


	// 新しい投稿をフェッチするための非同期関数
	const fetchMorePosts = async () => {
		//console.log("resultdataCursor!:", resultdataCursor)
		if (resultdata.next) {
			const response = await getCursor(resultdata.next);
			console.log('fetchMorePosts response:', response);
			console.log('fetchMorePosts response.results:', response.results);
			setResultData(prevResultData => ({
				...response,
				results: [...prevResultData.results, ...response.results]
			}));
			//		if (response.next) {
			//			setResultDataCursor(response.next)
			//	}
		}
		if (userlist.next) {
			const response = await getCursor(userlist.next);
			console.log('fetchMorePosts response:', response);
			console.log('fetchMorePosts response.results:', response.results);
			setUserlist(prevResultData => ({
				...response,
				results: [...prevResultData.results, ...response.results]
			}));
			//		if (response.next) {
			//			setResultDataCursor(response.next)
			//	}
		}
	};

	const [favorited, setFavorited] = useState(false);

	async function postSearch(keyword, media_only = false) {
		let result = await SearchKeyword(keyword, media_only)
		console.log("検索結果")
		console.log(result)
		setResultData(result)
		//EasyCache("searchPost", "searchPost", "json", result)
	}

	async function userSearch(keyword) {
		let result = await SearchUser(keyword)
		console.log("検索結果")
		console.log(result)
		setUserlist(result)
		//EasyCache("userList", "userList", "json", result)
	}

	async function search(mode, keyword) {

		if (mode === "post") {
			//EasyCache("keyword", "keyword", "val", keyword)
			//EasyCache("keyword", "", "", keyword)
			let result = await postSearch(keyword)
		}
		if (mode === "media") {
			//EasyCache("keyword", "keyword", "val", keyword)
			//EasyCache("keyword", "", "", keyword)
			let result = await postSearch(keyword, true)
		}
		if (mode === "user") {
			let result = await userSearch(keyword)
		}
	}

	function searchQueryWrapper(s, modeSetting) {
		console.log("何らかの検索ボタン")
		//setSearchMode(modeSetting)
		let mode
		if (modeSetting) {
			mode = modeSetting
		} else {
			mode = searchMode
		}
		// スペース区切りで配列にする
		let words = keyword.split(/[\s　]+/);

		let normalWords = ""
		let hashWords = ""
		let postTo = ""
		for (let word of words) {
			if (word.startsWith('#')) {
				hashWords = hashWords + (word.slice(1));
			} else if (word.startsWith('@')) {
				postTo = postTo + (word.slice(1));
			} else {
				normalWords = (normalWords + word)
			}
		}

		let searchParams = new URLSearchParams(location.search);

		let hasMedia = ""

		if (mode === "media") {
			searchParams.set("type", "media")
		} else if (mode === "user") {
			searchParams.set("type", "user")
		} else {
			searchParams.delete("type")
		}


		if (hashWords) {
			searchParams.set("tag", hashWords);
		} else {
			searchParams.delete("tag")
		}
		if (normalWords) {
			searchParams.set("q", normalWords);
		} else {
			searchParams.delete("q")
		}
		if (postTo) {
			searchParams.set("to", postTo);
		} else {
			searchParams.delete("to")
		}

		navigate({ search: searchParams.toString() });
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		// loginUserは非同期処理であるが、処理が完了してからログインチェックを行わないとログインチェックに失敗する
		// そのためawaitで処理の完了を待つ必要がある

		//search(searchMode, keyword)
		searchQueryWrapper("", searchMode)
	}

	const intelligentNavigate = useIntelligentNavigate();
	const [trend, setTrend] = useState("");

	useEffect(() => {
		const fetchPosts = async () => {
			const response = await getTrend();

			//console.log("ポスト取得!!")
			//console.log(response);
			//console.log(user_id)
			console.log("トレンド")
			console.log(response)
			setTrend(response);  // レスポンスをステートにセット
		};

		fetchPosts();
	}, []);  // post_idが変更されたときに再度フェッチするように依存配列を設定

	return (
		<>
			<div className="home">
				
				<Sidebar />
				<Header enableBack={true} />

				<ModalBox modal={modal} submitFormMode={submitFormMode} target={target} close={modalClose}>
					<button onClick={modalClose}>Close Modal</button>
				</ModalBox>

				<div class="mainContentBase">
					<div class="mainContent">
						<div className='mainLine'>
							<div className='search'>



								<form className='searchForm' onSubmit={handleSubmit}>
									{searchMode === "post" && <div class="searchFormTextArea">
										<input
											autocomplete="off"
											type="text"
											required
											id="search"
											placeholder="検索開始..."
											value={keyword}
											onChange={(e) => setKeyword(e.target.value)}
										/>
									</div>}
									{searchMode === "media" && <div class="searchFormTextArea">
										<input
											autocomplete="off"
											type="text"
											required
											id="search"
											placeholder="メディア検索開始..."
											value={keyword}
											onChange={(e) => setKeyword(e.target.value)}
										/>
									</div>}
									{searchMode === "user" && <div class="searchFormTextArea">
										<input
											autocomplete="off"
											type="text"
											required
											id="search"
											placeholder="ユーザーを検索..."
											value={keyword}
											onChange={(e) => setKeyword(e.target.value)}
										/>
									</div>}
									<div class="searchButton">
										<button type="submit" id="searchButton"><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
									</div>
								</form>
								<div className='postType'>

									<div onClick={() => searchModeSwitchWrapper("post")} className={searchMode === "post" ? "selected" : ""}>
										<span>投稿</span>
									</div>
									<div onClick={() => searchModeSwitchWrapper("media")} className={searchMode === "media" ? "selected" : ""}>
										<span>メディア</span>
									</div>
									<div onClick={() => searchModeSwitchWrapper("user")} className={searchMode === "user" ? "selected" : ""}>
										<span>ユーザー</span>
									</div>
								</div>
								{(searchMode === "post" || searchMode === "media") &&
									<div className='childPost'>
										{!resultdata &&
											<div className="trendList">
												{trend?.trend?.map((trend, index) => (
													<div onClick={() => intelligentNavigate("/search/?tag=" + trend.tag)}>
														<span className="tag">#{trend.tag}</span>
														<span className="tagCount">{trend.count}投稿</span>
													</div>
												))}
											</div>
										}
										{/*こっちが親投稿のリプライを表示する部分 */}
										{resultdata.results?.map((post, index) => (
											<React.Fragment key={index}>
												<PostRender key={index} post={post} />
												{index === resultdata.results.length - 2 && <div ref={lastElementRef}></div>}
											</React.Fragment>
										))}
										{console.log("resultdataのURL:", resultdata.next)}
										{console.log(resultdata)}
										{searchMode === "post" && resultdata && 0 === resultdata?.results?.length &&
											<div className='post'>
												<div className='loginWarn'>
													<FontAwesomeIcon icon={faHashtag} />
													<br />
													一致する投稿が見つからなかったようです
												</div>
											</div>
										}
										{searchMode === "media" && resultdata && 0 === resultdata?.results?.length &&
											<div className='post'>
												<div className='loginWarn'>
													<FontAwesomeIcon icon={faPlaneDeparture} />
													<br />
													一致するメディアが見つからなかったようです
												</div>
											</div>
										}
									</div>
								}
								{searchMode === "user" &&
									<div className='childPost'>
										{/*こっちが親投稿のリプライを表示する部分 */}
										{userlist.results?.map((user, index) => (
											<React.Fragment key={index}>
												<UserRenderer key={index} user={user} />
												{index === userlist.results.length - 2 && <div ref={lastElementRef}></div>}
											</React.Fragment>
										))}
										{userlist && 0 === userlist?.results?.length &&
											<div className='post'>
												<div className='loginWarn'>
													<FontAwesomeIcon icon={faUserInjured} />
													<br />
													一致するユーザーが見つからなかったようです
												</div>
											</div>
										}
									</div>
								}
							</div>
						</div>
						<RightSidebar />
					</div>
				</div>
			</div>
		</>
	);
}

export default Search;
