import React, { useState, useEffect } from 'react';
//import Sidebar from "./Sidebar";
import "./style.scss"

import Header from "components/blocks/Header/Header"

import Sidebar from "components/blocks/Sidebar/Sidebar"

import { ViewMap } from 'components/blocks/Map/ViewMap';

import { getTimeLine, TokenAuthManager, UserAuthCheckManager, LoginManagerWrapper, PostFavorite, GetEngagement, DeleteFavorite } from "../../../common/api/APIUtils"
let posts
if (await TokenAuthManager()) {
    console.log("認証成功")
    //	let timelines = await getTimeLine()
    //	console.log(timelines)
    //posts = 
}

const Mapview = () => {

    return (
        <>
            <div className="home">
                
                <Sidebar />
                <Header enableBack={true} />
                <div class="mainContent">
                    <div className='mapViewContainer'>
                        <ViewMap />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Mapview;