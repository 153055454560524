import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';

import { BrowserRouter, Route, Routes, Link, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { SnackbarContext } from 'components/blocks/GlobalSnackbar/SnackbarProvider';
//navigate("/about");

// サーバーのURLを定義しておく
//const serverUrl = "http://10.40.2.53:8000"
//const serverUrl = "http://localhost:8000"
//const serverUrl = "http://192.168.0.6:8000"
//const serverUrl = "http://192.168.0.11:8000"
//const serverUrl = "http://techtips.server-on.net:8000"
//const serverUrl = "http://192.168.0.12:8000"
//const serverUrl = "http://xxx.xxx.xx.xx:8000"
const serverUrl = "https://b-bird.net:8000"

// 低レイヤな関数のためあまり直接使うことはないと思う
export const accessTokenCheck = async () => {
	let access = localStorage.getItem('access')
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/access-token-check',
			config
		);

		` console.log(data)
			if( data.status === 200 ) {
				return true
        
			} else {
				return false
			}`
		//console.log("OK")
		return true
	} catch (error) {
		console.log('トークンが無効です:', error);

		return error
	}
};

export const accessTokenRefresh = async () => {
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	try {
		let refresh = localStorage.getItem('refresh')
		const { data } = await axios.post(
			serverUrl + '/api/token/refresh/',
			{ refresh },
			config
		);
		localStorage.setItem('access', data.access);
		localStorage.setItem('refresh', data.refresh);
		console.log("トークンは更新されました。")
		return data

	} catch (error) {
		console.log('リフレッシュトークンが無効です:RTS', error);
		return error
	}
}

export const TokenAuthManager = async () => {
	return true
	try {
		if (await accessTokenCheck() == true) {
			console.log("アクセストークンは更新されませんでした。")
			//const navigate = useNavigate()
			//useNavigate("/about");
			//navigate('/login');
			return true
		} else {
			if (await accessTokenRefresh() == true) {
				console.log("トークンは更新されました。")
				return true
			} else {
				console.log("リフレッシュトークンが無効です。")
				return false
			}
		}

	} catch (error) {
		console.error('エラーが発生しました:', error);

		return false
	}
};


export const TokenAuthManagerV2 = async () => {
	try {
		if (await accessTokenCheck() == true) {
			console.log("アクセストークンは更新されませんでした。")
			//const navigate = useNavigate()
			//useNavigate("/about");
			//navigate('/login');
			return true
		} else {
			if (await accessTokenRefresh() == true) {
				console.log("トークンは更新されました。")
				return true
			} else {
				console.log("リフレッシュトークンが無効です。")
				return false
			}
		}

	} catch (error) {
		console.error('エラーが発生しました:', error);

		return false
	}
};

export const whoAmI = async () => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		console.log("ユーザ情報取得")
		const { data } = await axios.get(
			serverUrl + '/api/1.0/who-am-i/',
			config
		);
		localStorage.setItem('user_id', data.user_id);
		localStorage.setItem('user_name', data.username);
		localStorage.setItem('user_nickname', data.nickname);
		localStorage.setItem('user_icon', data.user_icon);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('ユーザー情報の取得に失敗しました:', error);
		//return false
		return false
	}
}

export const LoginManagerV2 = async () => {
	if (await accessTokenCheck() == true) {
		console.log("アクセストークンは更新されませんでした。")
		return localStorage.getItem('access')
		//const navigate = useNavigate()
		//useNavigate("/about");
		//navigate('/login');
		return true
	} else {
		let result = await accessTokenRefresh()
		//return result.access
		//console.log("ああ＠＠＠＠＠＠＠＠＠あ＠あ＠あ＠あ＠あ＠")
		//console.log(result.access)
		//console.log(result.access)
		if (result?.access) {
			console.log("トークンは更新されました。XXXXA")
			localStorage.removeItem('network');
			return result.access
		} else {
			console.log("リフレッシュトークンが無効です。OFKD")
			result = await accessTokenRefresh()
			if (result?.access) {
				console.log("トークンは更新されました。XXXXA2")
				return result.access
			}
			//if (result?.code == "ERR_BAD_REQUEST") {
			if (result?.request?.status == "401") {
				console.log("リフレッシュトークンが無効です。XXXXAE")
				//localStorage.removeItem('access');
				//localStorage.removeItem('refresh');
				allCleanup()
				//return <Navigate replace to={"/login"} />;
				//return false
			}
			if (result?.message == "Network Error") {
				localStorage.setItem('network', 'offline');
			}
			return false
		}
	}
}


export const getProfile = async (post_id) => {
	let access = await LoginManagerV2()
	let config
	if (access) {
		config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				"Authorization": "Bearer " + access
			}
		};
	} else {
		config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		};
	}

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/' + post_id + '/profile/',
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('プロフィールの取得に失敗しました:', error);
		//return false
		return false
	}
}

export const getProfilePosts = async (post_id, filterQuery = false) => {
	let access = await LoginManagerV2()
	let filter = ""
	if (filterQuery) {
		filter = "?filter=" + filterQuery
	}
	let config
	if (access) {
		config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				"Authorization": "Bearer " + access
			}
		};
	} else {
		config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		};
	}

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/' + post_id + '/profile-posts/' + filter,
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('プロフィールの取得に失敗しました:', error);
		//return false
		return false
	}
}

export const getTrend = async () => {
	let access = await LoginManagerV2()
	let config
	if (access) {
		config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		};
	} else {
		config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		};
	}

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/trend/',
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('トレンドの取得に失敗しました:', error);
		//return false
		return false
	}
}

export const getGlobalTimeLine = async () => {
	let access = await LoginManagerV2()
	let config
	if (access) {
		config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				"Authorization": "Bearer " + access
			}
		};
	} else {
		config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		};
	}

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/global_timeline/',
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('タイムラインの取得に失敗しました:', error);
		//return false
		return false
	}
}

// 時間がないので新規関数で位置情報投稿のみを取得する物を作ります
export const getGlobalTimeLinePosOnly = async () => {
	let access = await LoginManagerV2()
	let config
	if (access) {
		config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				"Authorization": "Bearer " + access
			}
		};
	} else {
		config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		};
	}

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/global_timeline/?filter=has:pos',
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('タイムラインの取得に失敗しました:', error);
		//return false
		return false
	}
}

export const getTimeLine = async () => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/user_timeline/',
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('タイムラインの取得に失敗しました:', error);
		//return false
		return false
	}
}

export const GetNotification = async () => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/notification/',
			config
		);
		//timeline = JSON.parse(data)
		return data

	} catch (error) {
		console.log('通知の取得に失敗しました:', error);
		//return false
		return false
	}
}

export const SearchKeyword = async (keywords, media_only = false) => {

	//let words = keywords.split(' ');

	//let words = keywords.split(' ');
	let words = keywords.split(/[\s　]+/);

	let normalWords = [];
	let hashWords = [];
	let postTo = [];

	for (let word of words) {
		if (word.startsWith('#')) {
			hashWords.push(word);
		} else if (word.startsWith('@')) {
			postTo.push(word);
		} else {
			normalWords.push(word);
		}
	}

	let hasMedia = ""
	if (media_only) {
		hasMedia = "has:media_index,has:media"
	}

	let normalStr = normalWords.join(' ');
	let hashStr = hashWords.join(' ');
	let postToStr = postTo.join(' ');


	let processedKeywords = normalStr.replace(/[\s　]/g, ',');
	let processedHashtags = hashStr.replace(/[\s　]/g, ',');
	let processedPostTo = postToStr.replace(/[\s　]/g, ',');
	processedHashtags = processedHashtags.replace(/#/g, '');
	processedPostTo = processedPostTo.replace(/@/g, '');

	console.log("キーワード")
	console.log(processedKeywords);  // "テスト メッセージ"
	console.log("ハシュタグ")
	console.log(processedHashtags);  // "#タグ #タグ2"
	//console.log('/api/1.0/search/keyword/' + "?keyword=" + processedKeywords + "&tags=" + processedHashtags, + "&by=" + postTo)

	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			//"Authorization": "Bearer " + access
		}
	};
	if (localStorage.getItem('access')) {
		config.headers["Authorization"] = "Bearer " + access;
	}

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/search/keyword/' + "?q=" + processedKeywords + "&tags=" + processedHashtags + "&by=" + processedPostTo + "&filter=" + hasMedia,
			config
		);
		//timeline = JSON.parse(data)
		console.log(data);
		return data


	} catch (error) {
		console.log('検索の取得に失敗しました:', error);
		//return false
		return false
	}
}

export const SearchUser = async (keywords) => {

	console.log("入力されたkeyword")
	console.log(keywords)
	//let words = keywords.split(' ');

	//let words = keywords.split(' ');
	let words = keywords.split(/[\s　]+/);

	//	let nickname = [];
	//	let username = [];
	let username = ""
	let nickname = ""
	//
	for (let word of words) {
		if (word.startsWith('@')) {
			username = word;
			username = username.replace("@", "");
		} else {
			nickname = word;
		}
	}

	//	let normalStr = nickname.join(' ');
	//	let hashStr = username.join(' ');


	//	let processedKeywords = normalStr.replace(/[\s　]/g, ',');
	//	let processedHashtags = hashStr.replace(/[\s　]/g, ',');
	//username = username.replace(/@/g, '');


	//	console.log("キーワード")
	//	console.log(processedKeywords);  // "テスト メッセージ"
	//	console.log("ハシュタグ")
	//	console.log(processedHashtags);  // "#タグ #タグ2"
	//console.log("ずつう")
	//console.log(serverUrl + '/api/1.0/search/user/' + "?username=" + username + "&nickname=" + nickname)

	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			//"Authorization": "Bearer " + access
		}
	};
	if (localStorage.getItem('access')) {
		config.headers["Authorization"] = "Bearer " + access;
	}

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/search/user/' + "?username=" + username + "&nickname=" + nickname,
			config
		);
		//timeline = JSON.parse(data)
		console.log("ユーザー検索");
		console.log(data);
		return data


	} catch (error) {
		console.log('検索の取得に失敗しました:', error);
		//return false
		return false
	}
}


export const getPost = async (post_id) => {
	let access = await LoginManagerV2()
	let config
	if (access) {
		config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				"Authorization": "Bearer " + access
			}
		};
	} else {
		config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		};
	}

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/' + post_id + '/post/',
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('投稿の取得に失敗しました:', error);
		//return false
		return false
	}
}

export const getTree = async (post_id) => {
	let access = await LoginManagerV2()
	let config
	if (access) {
		config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				"Authorization": "Bearer " + access
			}
		};
	} else {
		config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		};
	}

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/tree/?cursor='+post_id,
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('投稿の取得に失敗しました:', error);
		//return false
		return false
	}
}

export const getReply = async (post_id) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/' + post_id + '/reply/',
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('投稿の取得に失敗しました:', error);
		//return false
		return false
	}
}

export const getCursor = async (cursor) => {
	let access = await LoginManagerV2()
	let config
	if (access) {
		config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				"Authorization": "Bearer " + access
			}
		};
	} else {
		config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		};
	}

	try {
		const { data } = await axios.get(
			cursor,
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('カーソルの取得に失敗しました:', error);
		//return false
		return false
	}
}

export const UserFollow = async (user_id) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.post(
			serverUrl + '/api/1.0/' + user_id + '/following/',
			{}, //データ部分は空を渡す
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('Followできませんでした:', error);
		//return false
		return false
	}
}


export const UserUnfollow = async (user_id) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.delete(
			serverUrl + '/api/1.0/' + user_id + '/following/',
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('UnFollowできませんでした:', error);
		//return false
		return false
	}
}


export const GetFollowing = async (user_id) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/' + user_id + '/following/',
			config
		);
		//timeline = JSON.parse(data)
		console.log("フォロー一覧")
		console.log(data)
		return data


	} catch (error) {
		console.log('フォロー一覧取得ができませんでした:', error);
		//return false
		return false
	}
}

export const GetFollower = async (user_id) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/' + user_id + '/follower/',
			config
		);
		//timeline = JSON.parse(data)
		console.log("フォロワー一覧")
		console.log(data)
		return data


	} catch (error) {
		console.log('フォワー一覧取得ができませんでした:', error);
		//return false
		return false
	}
}


export const GetFavorite = async (post_id) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/' + post_id + '/favorite/',
			config
		);
		//timeline = JSON.parse(data)
		console.log("フォロー一覧")
		console.log(data)
		return data


	} catch (error) {
		console.log('フォロー一覧取得ができませんでした:', error);
		//return false
		return false
	}
}

export const GetBookmark = async () => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/bookmark/',
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('ブックマーク取得ができませんでした:', error);
		//return false
		return false
	}
}


export const postBookmark = async (post_id) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.post(
			serverUrl + '/api/1.0/' + post_id + '/bookmark/',
			{}, //データ部分は空を渡す
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('ブックマーク送信ができませんでした:', error);
		//return false
		return false
	}
}


export const deleteBookmark = async (post_id) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.delete(
			serverUrl + '/api/1.0/' + post_id + '/bookmark/',
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('ブックマーク削除ができませんでした:', error);
		//return false
		return false
	}
}

export const GetRepost = async (post_id) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/' + post_id + '/repost/',
			config
		);
		//timeline = JSON.parse(data)
		console.log("フォロー一覧")
		console.log(data)
		return data


	} catch (error) {
		console.log('フォロー一覧取得ができませんでした:', error);
		//return false
		return false
	}
}

export const GetQuote = async (post_id) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/' + post_id + '/quote/',
			config
		);
		//timeline = JSON.parse(data)
		console.log("フォロー一覧")
		console.log(data)
		return data


	} catch (error) {
		console.log('フォロー一覧取得ができませんでした:', error);
		//return false
		return false
	}
}



export const PostRepost = async (post_id) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.post(
			serverUrl + '/api/1.0/' + post_id + '/repost/',
			{}, //データ部分は空を渡す
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('Repostできませんでした:', error);
		//return false
		return false
	}
}


export const DeleteRepost = async (post_id) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.delete(
			serverUrl + '/api/1.0/' + post_id + '/repost/',
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('UnRepostできませんでした:', error);
		//return false
		return false
	}
}


export const PostFavorite = async (post_id) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.post(
			serverUrl + '/api/1.0/' + post_id + '/favorite/',
			{}, //データ部分は空を渡す
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('Favoriteできませんでした:', error);
		//return false
		return false
	}
}

export const DeleteFavorite = async (post_id) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.delete(
			serverUrl + '/api/1.0/' + post_id + '/favorite/',
			config
		);
		//timeline = JSON.parse(data)
		return data


	} catch (error) {
		console.log('UnFavoriteできませんでした:', error);
		//return false
		return false
	}
}

export const DeletePost = async (post_id) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.delete(
			serverUrl + '/api/1.0/' + post_id + '/post/',
			config
		);
		//timeline = JSON.parse(data)
		return true


	} catch (error) {
		console.log('投稿削除できませんでした:', error);
		//return false
		return error
	}
}


export const PostSubmit = async (message, selectedImages, lat, lng) => {
	let access = await LoginManagerV2()
	//	var body = {
	//		'message': message,
	//		"image": selectedImages,
	//	};

	// FormDataオブジェクトを作成
	const formData = new FormData();

	// 'message'を追加
	formData.append('message', message);
	if (lat) {
		formData.append('lat', lat);
		formData.append('lng', lng);
	}

	// 各画像を追加
	for (let i = 0; i < selectedImages.length; i++) {
		// 画像ファイルを取得
		const file = selectedImages[i];

		console.log("file")
		console.log(file)

		// ファイル名から拡張子を抽出
		const extension = file.name.split('.').pop();

		// MIMEタイプを決定
		let mimeType = 'image/jpeg';  // デフォルト値
		if (extension === 'png') {
			mimeType = 'image/png';
		} else if (extension === 'gif') {
			mimeType = 'image/gif';
		}

		// 新しいFileオブジェクトを作成
		const newFile = new File([file], `image${i + 1}.${extension}`, { type: mimeType });

		formData.append('image', newFile);
	}

	//	for (let image of selectedImages) {
	//		body.push.image("image");
	//	}

	//	for (let i = 0; i < 10; i++) {
	//		var new_data = { image : "tanaka"}
	//		body.push(new_data)
	//	}

	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const result = await axios.post(serverUrl + '/api/1.0/post/', formData, config);
		//timeline = JSON.parse(data)
		console.log(formData)
		return true
		//return result


	} catch (error) {
		console.log('Postに失敗しました:', error);
		return error
		return false
	}
}


export const QuoteSubmit = async (post_id, message, selectedImages) => {
	let access = await LoginManagerV2()
	//	var body = {
	//		'message': message,
	//		"image": selectedImages,
	//	};

	// FormDataオブジェクトを作成
	const formData = new FormData();

	// 'message'を追加
	formData.append('message', message);

	// 各画像を追加
	for (let i = 0; i < selectedImages.length; i++) {
		// 画像ファイルを取得
		const file = selectedImages[i];

		console.log("file")
		console.log(file)

		// ファイル名から拡張子を抽出
		const extension = file.name.split('.').pop();

		// MIMEタイプを決定
		let mimeType = 'image/jpeg';  // デフォルト値
		if (extension === 'png') {
			mimeType = 'image/png';
		} else if (extension === 'gif') {
			mimeType = 'image/gif';
		}

		// 新しいFileオブジェクトを作成
		const newFile = new File([file], `image${i + 1}.${extension}`, { type: mimeType });

		formData.append('image', newFile);
	}

	//	for (let image of selectedImages) {
	//		body.push.image("image");
	//	}

	//	for (let i = 0; i < 10; i++) {
	//		var new_data = { image : "tanaka"}
	//		body.push(new_data)
	//	}

	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const result = await axios.post(serverUrl + '/api/1.0/' + post_id + '/quote/', formData, config);
		//timeline = JSON.parse(data)
		console.log(formData)
		//return true
		return true


	} catch (error) {
		console.log('Postに失敗しました:', error);
		//return false
		return error
	}
}

export const ReplySubmit = async (post_id, message, selectedImages) => {
	let access = await LoginManagerV2()
	//	var body = {
	//		'message': message,
	//		"image": selectedImages,
	//	};

	// FormDataオブジェクトを作成
	const formData = new FormData();

	// 'message'を追加
	formData.append('message', message);

	// 各画像を追加
	for (let i = 0; i < selectedImages.length; i++) {
		// 画像ファイルを取得
		const file = selectedImages[i];

		console.log("file")
		console.log(file)

		// ファイル名から拡張子を抽出
		const extension = file.name.split('.').pop();

		// MIMEタイプを決定
		let mimeType = 'image/jpeg';  // デフォルト値
		if (extension === 'png') {
			mimeType = 'image/png';
		} else if (extension === 'gif') {
			mimeType = 'image/gif';
		}

		// 新しいFileオブジェクトを作成
		const newFile = new File([file], `image${i + 1}.${extension}`, { type: mimeType });

		formData.append('image', newFile);
	}

	//	for (let image of selectedImages) {
	//		body.push.image("image");
	//	}

	//	for (let i = 0; i < 10; i++) {
	//		var new_data = { image : "tanaka"}
	//		body.push(new_data)
	//	}

	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const result = await axios.post(serverUrl + '/api/1.0/' + post_id + '/reply/', formData, config);
		//timeline = JSON.parse(data)
		console.log(formData)
		//return true
		return true


	} catch (error) {
		console.log('Postに失敗しました:', error);
		//return false
		return error
	}
}


export const ProfileEdit = async ({ username, nickname, bio, iconImage, headerImage }) => {
	console.log("APIUTAAAAA")
	console.log(username)
	console.log(iconImage)

	let access = await LoginManagerV2()
	//	var body = {
	//		'message': message,
	//		"image": selectedImages,
	//	};

	// FormDataオブジェクトを作成
	const formData = new FormData();

	// 'message'を追加
	if (username) {
		formData.append('username', username);
	}
	if (nickname) {
		formData.append('nickname', nickname);
	}
	if (bio) {
		formData.append('bio', bio);
	}
	if (iconImage[0]) {
		formData.append('user_icon', iconImage[0]);
	}
	if (headerImage[0]) {
		formData.append('user_header', headerImage[0]);
	}

	//	for (let image of selectedImages) {
	//		body.push.image("image");
	//	}

	//	for (let i = 0; i < 10; i++) {
	//		var new_data = { image : "tanaka"}
	//		body.push(new_data)
	//	}

	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const result = await axios.put(serverUrl + '/api/1.0/settings/profile/', formData, config);
		//timeline = JSON.parse(data)
		console.log(formData)
		return true
		//return result


	} catch (error) {
		console.log('プロフィール変更に失敗しました:', error);
		return error
		return false
	}
}


export const GetEngagement = async (post_id) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.get(
			serverUrl + '/api/1.0/' + post_id + '/engagement_count/',
			config
		);
		return data


	} catch (error) {
		console.log('エンゲージメントの取得に失敗しました:', error);
		//return false
		return false
	}
}

export const loginUser = async (username, password) => {
	await console.log("ログイン試行")
	//console.log("A" + accessTokenCheck())

	//if (await accessTokenCheck() == true) {
	//	return
	//}
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	try {
		if (await accessTokenCheck() == true) {
			console.log("アクセストークンは更新されませんでした。")
			return
		} else {
			console.log("アクセストークンは更新されました。")
			const { data } = await axios.post(
				serverUrl + '/api/token/',
				{ username, password },
				config
			);
			localStorage.setItem('access', data.access);
			localStorage.setItem('refresh', data.refresh);
			await whoAmI()
		}
		return true
	} catch (error) {
		console.error('エラーが発生しました:', error);
		return error
	}
};

export const allCleanup = () => {
	console.log("クリーンアップ実行")
	localStorage.clear()
	sessionStorage.clear()
};

export const logout = () => {
	console.log("Logout試行")
	allCleanup()
	location.reload()
};

//export const UserAuthCheckManager = () => {	
//    const [isLoggedIn, setIsLoggedIn] = useState(null);
//
//    useEffect(() => {
//        const checkLoginStatus = async () => {
//            const result = await LoginManager();
//            setIsLoggedIn(result);
//        };
//
//        checkLoginStatus();
//    }, []);
//
//    if (isLoggedIn === null) {
//        // ログイン状態が確認されるまで何も表示しない、またはローディングスピナーを表示する
//        return null;
//    }
//
//    return <Navigate replace to={isLoggedIn ? "" : "/login"} />;
//
//	}

export const EmailAuthenticated = async (email) => {
	//let access = await LoginManagerV2()
	const recaptcha_token = await grecaptcha.enterprise.execute('6LfHvIUpAAAAAGKt7B9UnXTN8AL3587h3ml-Uv1-', { action: 'send_email' });

	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	try {
		const { data } = await axios.post(
			serverUrl + '/api/1.0/activate/',

			{
				'email': email,
				'recaptcha_token': recaptcha_token
			}
			, //データ部分は空を渡す
			config
		);
		//timeline = JSON.parse(data)
		console.log(data)
		return data

	} catch (error) {
		console.log('アカウント作成できませんでした:', error);
		//return false
		return error
	}
}

export const EmailCodeSubmit = async (tokenId, activationCode) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	try {
		const { data } = await axios.post(
			serverUrl + '/api/1.0/activate/',

			{
				'token_id': tokenId,
				'activation_code': activationCode
			}
			, //データ部分は空を渡す
			config
		);
		//timeline = JSON.parse(data)
		console.log(data)
		return true

	} catch (error) {
		console.log('アカウント作成できませんでした:', error);
		//return false
		return error
	}
}

export const Signup = async (username, nickname, tokenId, password, date_birthday) => {
	let access = await LoginManagerV2()
	const recaptcha_token = await grecaptcha.enterprise.execute('6LfHvIUpAAAAAGKt7B9UnXTN8AL3587h3ml-Uv1-', { action: 'signup' });

	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	try {
		const { data } = await axios.post(
			serverUrl + '/api/1.0/signup/',

			{
				'username': username,
				'nickname': nickname,
				'token_id': tokenId,
				'date_birthday': date_birthday,
				'password': password,
				'recaptcha_token': recaptcha_token
			}
			, //データ部分は空を渡す
			config
		);
		//timeline = JSON.parse(data)
		console.log(data)
		return true


	} catch (error) {
		console.log('アカウント作成できませんでした:', error);
		//return false
		return error
	}
}

export const EmailChangePasswordAuthenticated = async (email) => {
	//let access = await LoginManagerV2()
	const recaptcha_token = await grecaptcha.enterprise.execute('6LfHvIUpAAAAAGKt7B9UnXTN8AL3587h3ml-Uv1-', { action: 'send_email' });

	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	try {
		const { data } = await axios.post(
			serverUrl + '/api/1.0/change_password_activate/',

			{
				'email': email,
				'recaptcha_token': recaptcha_token
			}
			, //データ部分は空を渡す
			config
		);
		//timeline = JSON.parse(data)
		console.log(data)
		return data

	} catch (error) {
		console.log('アカウント作成できませんでした:', error);
		//return false
		return error
	}
}

export const EmailChangePasswordCodeSubmit = async (tokenId, activationCode) => {
	let access = await LoginManagerV2()

	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	//console.log("RSDX:" + tokenId + "  " + activationCode)

	try {
		const { data } = await axios.post(
			serverUrl + '/api/1.0/change_password_activate/',

			{
				'token_id': tokenId,
				'activation_code': activationCode,
			}
			, //データ部分は空を渡す
			config
		);
		//timeline = JSON.parse(data)
		console.log(data)
		return true

	} catch (error) {
		console.log('アカウント作成できませんでした:', error);
		//return false
		return error
	}
}

export const ChangePassword = async (tokenId, password) => {
	let access = await LoginManagerV2()
	const recaptcha_token = await grecaptcha.enterprise.execute('6LfHvIUpAAAAAGKt7B9UnXTN8AL3587h3ml-Uv1-', { action: 'reset_password' });

	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	try {
		const { data } = await axios.put(
			serverUrl + '/api/1.0/change_password/',

			{
				'token_id': tokenId,
				'password': password,
				'recaptcha_token': recaptcha_token
			}
			, //データ部分は空を渡す
			config
		);
		//timeline = JSON.parse(data)
		console.log(data)
		return true


	} catch (error) {
		console.log('パスワード変更ができませんでした:', error);
		//return false
		return error
	}
}

export const EmailChangeEmailAuthenticated = async (email) => {
	let access = await LoginManagerV2()
	const recaptcha_token = await grecaptcha.enterprise.execute('6LfHvIUpAAAAAGKt7B9UnXTN8AL3587h3ml-Uv1-', { action: 'send_email' });

	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.post(
			serverUrl + '/api/1.0/change_email/',

			{
				'email': email,
				'recaptcha_token':recaptcha_token
			}
			, //データ部分は空を渡す
			config
		);
		//timeline = JSON.parse(data)
		console.log(data)
		return data

	} catch (error) {
		console.log('アカウント作成できませんでした:', error);
		//return false
		return error
	}
}

export const EmailChangeEmailCodeSubmit = async (tokenId, activationCode, password) => {
	let access = await LoginManagerV2()
	const recaptcha_token = await grecaptcha.enterprise.execute('6LfHvIUpAAAAAGKt7B9UnXTN8AL3587h3ml-Uv1-', { action: 'change_email' });

	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	//console.log("RSDX:" + tokenId + "  " + activationCode)

	try {
		const { data } = await axios.post(
			serverUrl + '/api/1.0/change_email/',

			{
				'token_id': tokenId,
				'activation_code': activationCode,
				'password': password,
				'recaptcha_token': recaptcha_token
			}
			, //データ部分は空を渡す
			config
		);
		//timeline = JSON.parse(data)
		console.log(data)
		return true

	} catch (error) {
		console.log('電子メールトークン生成が失敗しました:', error);
		//return false
		return error
	}
}

export const ChangeEmail = async (tokenId, password) => {
	let access = await LoginManagerV2()
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			"Authorization": "Bearer " + access
		}
	};

	try {
		const { data } = await axios.put(
			serverUrl + '/api/1.0/change_email/',

			{
				'token_id': tokenId,
				'password': password
			}
			, //データ部分は空を渡す
			config
		);
		//timeline = JSON.parse(data)
		console.log(data)
		return true


	} catch (error) {
		console.log('Email変更ができませんでした:', error);
		//return false
		return error
	}
}

// ログイン状態でなければにログインを求めるコンポーネント。ログインが必要なページのソースにインポートして<UserAuthCheckManager/>を設置して使用してください。
export const UserAuthCheckManager = () => {
	//const [isLoggedIn, setIsLoggedIn] = useState(null);
	const openSnackbar = useContext(SnackbarContext);
	const [returnType, setReturnType] = useState(null);
	const [reason, setReason] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const checkLoginStatus = async () => {
			if (localStorage.getItem('access') === null || localStorage.getItem('refresh') === null) {
				//return <Navigate replace to={isLoggedIn ? "" : "/login"} />;
				//navigate("/login")
				const result = await accessTokenCheck()
				setReason(result)
				//return <Navigate replace to="/login" />
				setReturnType("logout")
				return true
			} else {
				return true
			}
		};

		checkLoginStatus();
	}, []);

	if (returnType === null) {
		// ログイン状態が確認されるまで何も表示しない、またはローディングスピナーを表示する
		return null;
	} else if (returnType === "logout") {
		openSnackbar(reason.message, "error")
		if (reason.code === "ERR_BAD_REQUEST") {
			openSnackbar("この機能を利用するにはログインが必要です", "error")
			if (localStorage.getItem('network') === "offline") {
				openSnackbar("サーバーに接続できませんでした", "error")
			} else {
				navigate("/login", { replace: true })
			}
		}
	}

	//return <Navigate replace to={isLoggedIn ? "" : "/login"} />;

}
export const LoginManagerWrapper = () => {
	//const [isLoggedIn, setIsLoggedIn] = useState(null);
	const openSnackbar = useContext(SnackbarContext);
	const [returnType, setReturnType] = useState(null);
	const [reason, setReason] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const checkLoginStatus = async () => {
			let result = await LoginManagerV2()
			setReason(result)
			result = await accessTokenCheck()
			setReason(result)
		};
		checkLoginStatus();
	}, []);
	console.log(reason)

	if (reason === true) {
		navigate("/home")
	}
}

// UserAuthCheckManagerコンポーネントと基本的には同じであるが、これはログイン状態が確認されれば/homeに推移する
export const LoginManagerWrapperOLD = () => {
	const [isLoggedIn, setIsLoggedIn] = useState(null);

	useEffect(() => {
		const checkLoginStatus = async () => {
			const result = await TokenAuthManagerV2();
			setIsLoggedIn(result);
		};
		checkLoginStatus();
	}, []);

	if (isLoggedIn === null) {
		// ログイン状態が確認されるまで何も表示しない、またはローディングスピナーを表示する
		return null;
	}
	return <Navigate replace to={isLoggedIn ? "/home" : "/login"} />;
}

export const ScrollePositionManager = (keyname) => {
	const navigate = useNavigate();
	const location = useLocation();
	const prevLocation = useRef(location);

	useEffect(() => {
		return () => {
			// ページ遷移が発生したかどうかを確認
			if (prevLocation.current !== location) {
				// ここでコンポーネントを閉じる処理を実行
				console.log("ページが遷移しました");
				console.log("ページ推移" + document.documentElement.scrollTop)
			}
			console.log("ページが遷移しましたX");
			console.log("ページ推移X" + document.documentElement.scrollTop)
		};
	}, [location]); // locationを依存配列に追加
}

export const useIntelligentNavigate = () => {
	const navigate = useNavigate();
	const location = useLocation();

	return (link, { root = false, replace = false, newTab = false } = {}) => {
		sessionStorage.setItem(`scrollPosition_${location.pathname}`, JSON.stringify(window.pageYOffset));
		if (root) {
			if (typeof root === 'number' && !isNaN(root)) {
				const pathSegments = location.pathname.split('/');

				// 最後のセグメントを新しいセグメントで置換
				pathSegments[pathSegments.length - root] = link;

				// パスを再結合
				link = pathSegments.join('/');
			} else {
				//let url = "/a/b/a/b/c";
				//let target = "/b/"; // 除去するためのターゲット

				//	console.log("location.pathname.split('/');")
				//	console.log(location.pathname)

				// URLを"/"で区切って配列にする
				let pathSegments = location.pathname.split('/');
				//	console.log("pathSegments")
				//	console.log(pathSegments)
				// ターゲットが見つかるまでの配列の要素を取得
				let index = pathSegments.indexOf(root) + 1; // sliceを使用して先頭と末尾の"/"を除去
				//	console.log("index")
				//	console.log(index)
				// 先頭からターゲットの直後までの部分配列を取得し、それを"/"で結合して新しいURLを作成
				link = pathSegments.slice(0, index).join('/') + "/" + link;

				//console.log(url); // "/a/b"を出力
			}

		}
		if (location.pathname !== link) {
			if (newTab) {
				window.open(link,'_blank')
				//window.open(`${window.location.origin}/${link}`)
				//console.log("ネムネムaa")
			} else if (replace) {
				navigate(link, { replace: true });
			} else {
				navigate(link);
			}
		}
	};
};


export const UnkX = () => {
	//const navigateA = useNavigate();
	console.log("lajasdjn ")
	//navigateA('/login')
	return <Navigate replace to="/login" />;
}

//export default LoginForm;
