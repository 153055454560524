import { useParams } from 'react-router-dom';
import Header from "components/blocks/Header/Header"
import React, { useState, useEffect, useRef } from 'react';
import "./style.scss"
import PostRender from "components/blocks/PostsRenderer/PostsRenderer"
import Sidebar from "components/blocks/Sidebar/Sidebar"
import RightSidebar from "components/blocks/RightSidebar/RightSidebar"
import logo from "assets/img/conceptpdn-white-alpha.png"
import { getPost, getReply, getCursor, getTree } from "common/api/APIUtils"
import EasyCache from 'components/blocks/EasyCache/EasyCache';
import PostListRender from "components/blocks/PostListRenderer/PostListRenderer"
import PullToRefresh from "components/blocks/PullToRefresh/PullToRefresh"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkSlash } from '@fortawesome/free-solid-svg-icons';

function PostView() {
	let { post_id } = useParams();

	//const [treePosts, setTreePosts] = useState([false]);

	const [posts, setPosts] = useState(EasyCache(post_id, "post", "json"));
	const [tree, setTree] = useState(EasyCache(post_id, "tree", "json"));
	const [reply, setReply] = useState(EasyCache(post_id, "reply", "json"));
	//const [replyCursor, setReplyCursor] = useState("");

	useEffect(() => {
		window.addEventListener('beforeunload', () => {
			//sessionStorage.clear();
			console.log("リロード検知1")
			EasyCache(post_id, "post", "delete")
			EasyCache(post_id, "reply", "delete")
		});

		return () => {
			window.removeEventListener('beforeunload', () => {
				//sessionStorage.clear();
				console.log("リロード検知2")
				EasyCache(post_id, "post", "delete")
				EasyCache(post_id, "reply", "delete")
			});
		};
	}, []);


	useEffect(() => {
		const fetchPosts = async () => {
			//const response = await getPost(post_id);

			const data = EasyCache(post_id, "post", "json")
			let response
			// セッションからページ状態を復旧する処理
			if (data) {
				console.log("セッションから復旧")
				response = data
			} else {
				//response = await getProfile(user_id);
				response = await getPost(post_id);

			}
			EasyCache(post_id, "post", "json", response)
			console.log("ポスト取得dSE")
			console.log(response)
			console.log("ポスト取得d")
			console.log(response);
			console.log(post_id)
			setPosts(response);  // レスポンスをステートにセット
			//setReplyCursor(response.next)
			//setReplyCursor("STR")
			console.log("投稿のキャッシュ")
			console.log(EasyCache(post_id, "post", "json"))
		};

		fetchPosts();
	}, [post_id]);  // post_idが変更されたときに再度フェッチするように依存配列を設定

	useEffect(() => {
		const fetchPosts = async () => {
			//const response = await getPost(post_id);

			const data = EasyCache(post_id, "tree", "json")
			let response
			// セッションからページ状態を復旧する処理
			if (data) {
				console.log("セッションから復旧")
				response = data
			} else {
				//response = await getProfile(user_id);
				response = await getTree(post_id);

			}
			EasyCache(post_id, "tree", "json", response)
			console.log(response);
			console.log(post_id)
			setTree(response);  // レスポンスをステートにセット
			//setReplyCursor(response.next)
			//setReplyCursor("STR")
		};

		fetchPosts();
	}, [post_id]);  // post_idが変更されたときに再度フェッチするように依存配列を設定

	useEffect(() => {
		const fetchPosts = async () => {
			//const response = await getReply(post_id);
			const data = EasyCache(post_id, "reply", "json")
			let response
			// セッションからページ状態を復旧する処理
			if (data) {
				console.log("セッションから復旧")
				response = data
			} else {
				//response = await getProfile(user_id);
				response = await getReply(post_id);
			}
			console.log("ポスト取得!!")
			console.log(response);
			console.log(post_id)
			setReply(response);  // レスポンスをステートにセット
			console.log("response")
			console.log(response.next)

			//	if (response.next) {
			//			setReplyCursor(response.next)
			//		}

			//	console.log("replyCursor1:", replyCursor)
			//	setReplyCursor("STR")
			//	console.log("replyCursor1.1:", response.next)
			//		console.log("replyCursor1.2:", replyCursor)
		};

		fetchPosts();
	}, [post_id]);  // post_idが変更されたときに再度フェッチするように依存配列を設定

	const observer = useRef();
	const lastElementRef = useRef(null);

	useEffect(() => {
		if (observer.current) observer.current.disconnect();

		observer.current = new IntersectionObserver(entries => {
			if (entries[0].isIntersecting) {
				// ここに最後の要素が表示されたときの処理を書く
				console.log('あああ.');
				EasyCache(post_id, "reply", "json", reply)

				// 新しい投稿をフェッチする
				fetchMorePosts();
			}
		});

		if (lastElementRef.current) {
			observer.current.observe(lastElementRef.current);
		}

		return () => {
			if (lastElementRef.current) {
				observer.current.unobserve(lastElementRef.current);
			}
		};
	}, [reply.results]);  // reply.resultsが変更されたときに再度フェッチするように依存配列を設定

	// 新しい投稿をフェッチするための非同期関数
	const fetchMorePosts = async () => {
		//console.log("replyCursor!:", replyCursor)
		if (reply.next) {
			const response = await getCursor(reply.next);
			console.log('fetchMorePosts response:', response);
			console.log('fetchMorePosts response.results:', response.results);
			setReply(prevReply => ({
				...response,
				results: [...prevReply.results, ...response.results]
			}));
			//		if (response.next) {
			//			setReplyCursor(response.next)
			//	}
		}
	};

	const [favorited, setFavorited] = useState(false);
	function flattenRepliedPostTree(tree) {
		let result = [];
		if (tree && tree.post_id) {
			result = result.concat(flattenRepliedPostTree(tree.reply));
			result.push(tree);

			//if (tree.reply) {
			//	result = result.concat(flattenRepliedPostTree(tree.reply));
			//}
		}
		return result;
	}



	return (
		<>
			<div className="home">
				<Sidebar />
				<Header enableBack={true} />

				<div class="mainContentBase">
					<div class="mainContent">
						<div className='mainLine'>
							<PullToRefresh onRefresh={() => sessionStorage.clear() & location.reload()}>
								{/*<div className='treePost'>

									{posts[0]?.content && flattenRepliedPostTree(posts[0]?.content?.reply)?.map((post, index) => (
										<PostRender key={index} post={post} />
									))}

								</div>*/}

								<div className='treePost'>
									{/*{reply.results?.map((post, index) => (
										<React.Fragment key={index}>
											{index === reply.results.length - 5 && <div ref={lastElementRef}></div>}
											<PostRender key={index} post={post} />
									</React.Fragment>
									))}*/}
									<PostListRender postList={tree} postListKey="tree" reverseMode={true} cache={false} endLogo={false} replyMode={true} />
								</div>
								<div className='rootPost'>
									{/*こっちが親投稿 */}
									{0 === posts?.length &&
										<div className='post'>
											<div className='loginWarn'>
												<div>
													<FontAwesomeIcon icon={faLinkSlash} />
													<br />
													投稿が見つかりませんでした
												</div>
											</div>
										</div>
									}
									{posts[0]?.content && <PostRender post={posts[0]} mode="showEngagementButton" />}

								</div>
								{posts[0]?.content &&
									<div className='replyForm'>
										<h2>投稿を追加しよう</h2>
									</div>
								}
								<div className='childPost'>
									{/*こっちが親投稿のリプライを表示する部分 */}
									{reply.results?.map((post, index) => (
										<React.Fragment key={index}>
											<PostRender key={index} post={post} hidePoem={true}/>
											{index === reply.results.length - 5 && <div ref={lastElementRef}></div>}
										</React.Fragment>
									))}
									{console.log("replyのURL:", reply.next)}
									{console.log(reply)}
								</div>
							</PullToRefresh>
						</div>
						<RightSidebar />
					</div>
				</div>
			</div>
		</>
	);
}

export default PostView;
