import logo from "assets/img/conceptpdn-white-alpha.png"
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
//import { useModal, ModalBox } from 'components/blocks/OverlayDialog/OverlayDialog';
import { useModal, ModalBox } from 'components/blocks/PostSubmit/PostSubmit';

//import PostSubmit from "components/blocks/PostSubmit/PostSubmit";

import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faCompass } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { faEarthAsia } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as solidBookmark } from '@fortawesome/free-solid-svg-icons';

import { useIntelligentNavigate } from "common/api/APIUtils"

function Sidebar() {
    const intelligentNavigate = useIntelligentNavigate();
    const navigate = useNavigate();
    const location = useLocation();

    const { modal, modalOpen, modalClose } = useModal();

    //const style = {
    //    content: {
    //        //position: 'static',
    //        //backgroundColor: "rgb(0 0 0 / 0%)",
    //       width: "600px",
    //        height: "300px",
    //    },
    //}

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const [isVisible, setIsVisible] = useState(whatsMobile());
    //console.log("whatsMobile()")
    //console.log(whatsMobile())

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        // クリーンアップ関数を返す
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);  // 空の依存配列を指定して、エフェクトをマウントとアンマウント時にのみ実行

    function whatsMobile() {
        if (window.innerWidth < 650) {
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        // windowSizeが変更されたときに実行する関数
        const doSomething = () => {
            console.log('windowSize has changed:', windowSize);
            if (!whatsMobile()) {
                setIsVisible()
            } else {
                setIsVisible(true)
            }
        };

        doSomething();
    }, [windowSize]);  // windowSizeを依存配列に追加

    return (
        <div className="mainSidebarMenu">
            <div className="submit">
                {/*<button onClick={modalOpen}>Open Modal</button>*/}
                <ModalBox modal={modal} close={modalClose}>
                    <button onClick={modalClose}>Close Modal</button>
                </ModalBox>
            </div>
            {isVisible && <div className="sidebar">
                <div className="submit">
                </div>
                <div className="sidebarFrame">
                    <img src={logo} alt="" class="logo" />
                    <div id="home" className={location.pathname === "/home" ? "selected" : ""} onClick={() => intelligentNavigate('/home')}>
                        <FontAwesomeIcon icon={faHouse} />ホーム
                        {/*<a className="Link" href="/home/"></a>*/}
                    </div>
                    <div id="global" className={location.pathname === "/global" ? "selected" : ""} onClick={() => intelligentNavigate('/global')}>
                        <FontAwesomeIcon icon={faCompass} />グローバル
                        {/*<a className="Link" href="/home/"></a>*/}
                    </div>
                    <div id="search" className={location.pathname === "/search" ? "selected" : ""} onClick={() => intelligentNavigate('/search')}>
                        <FontAwesomeIcon icon={faMagnifyingGlass} />検索
                        {/*<a className="Link" href="/search/"></a>*/}
                    </div>
                    <div id="notification" className={location.pathname === "/notification" ? "selected" : ""} onClick={() => intelligentNavigate('/notification')}>
                        <FontAwesomeIcon icon={faBell} />通知
                        {/*<a className="Link" href="/search/"></a>*/}
                    </div>
                    <div id="bookmark" className={location.pathname === "/bookmark" ? "selected" : ""} onClick={() => intelligentNavigate('/bookmark')}>
                        <FontAwesomeIcon icon={solidBookmark} />ブックマーク
                        {/*<a className="Link" href="/search/"></a>*/}
                    </div>
                    <div id="mapview" className={location.pathname === "/mapview" ? "selected" : ""} onClick={() => intelligentNavigate('/mapview')}>
                        <FontAwesomeIcon icon={faEarthAsia} />マップビュー
                        {/*<a className="Link" href="/search/"></a>*/}
                    </div>
                    {/*<div id="dm" onClick={() => intelligentNavigate('/messages')}>
                        <FontAwesomeIcon icon={faEnvelope} />メッセージ
                    </div>*/}
                    {/*<div id="community" onClick={() => intelligentNavigate('/community')}>
                        <FontAwesomeIcon icon={faUserGroup} />コミュニティ
                    </div>*/}
                    {localStorage.getItem('user_name') &&
                        <div id="profile"className={location.pathname.split('/')[1] === "profile" ? "selected" : ""} onClick={() => intelligentNavigate("/profile/" + localStorage.getItem('user_name'))}>
                            <FontAwesomeIcon icon={faAddressCard} />プロフィール
                            {/*<a className="Link" href={"/profile/"+localStorage.getItem('user_id')+"/"}></a>*/}
                        </div>
                    }
                    {/*<div id="other">
                        <FontAwesomeIcon icon={faEllipsis} />その他
                    </div>*/}
                    {localStorage.getItem('user_name') &&
                        <div id="post" onClick={modalOpen}>
                            <FontAwesomeIcon icon={faPlus} />投稿
                            {/*<a className="Link" href="/users/!username/"></a>*/}
                        </div>
                    }
                </div>
            </div>}

            <div className="mobileSidebar">
                <div className="sidebarFrame">

                    <div id="showSandbar" onClick={() => toggleVisibility()}>
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                    <div id="search" onClick={() => intelligentNavigate('/search')}>
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </div>
                    <div id="home" onClick={() => intelligentNavigate('/home')}>
                        <FontAwesomeIcon icon={faHouse} />
                    </div>
                    <div id="notification" onClick={() => intelligentNavigate('/notification')}>
                        <FontAwesomeIcon icon={faBell} />
                    </div>
                    <div id="post" onClick={modalOpen}>
                        <FontAwesomeIcon icon={faPlus} />
                    </div>
                </div>
            </div>

        </div>
    );
}
export default Sidebar;

