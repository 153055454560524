import { Link, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
//import Sidebar from "./Sidebar";
import "./style.scss"
import logo from "assets/img/conceptpdn-white-alpha.png"
import Header from "components/blocks/Header/Header"
import Sidebar from "components/blocks/Sidebar/Sidebar"
import RightSidebar from "components/blocks/RightSidebar/RightSidebar"
import PostRender from "components/blocks/PostsRenderer/PostsRenderer"
import EasyCache from 'components/blocks/EasyCache/EasyCache';
import EasySnackbar from 'components/blocks/EasySnackbar/EasySnackbar'
import { getTimeLine, TokenAuthManager, UserAuthCheckManager, LoginManagerWrapper, PostFavorite, GetEngagement, DeleteFavorite, getCursor, GetFollowing, GetFollower, GetFavorite, GetRepost, GetQuote } from "../../../common/api/APIUtils"
import UserListRenderer from "components/blocks/UserListRenderer/UserListRenderer"
import { useIntelligentNavigate } from "common/api/APIUtils"
import PostListRender from "components/blocks/PostListRenderer/PostListRenderer"

let posts
if (await TokenAuthManager()) {
    console.log("認証成功")
    //	let timelines = await getTimeLine()
    //	console.log(timelines)
    //posts = 
}

const FollowerView = ({ mode }) => {
    let { post_id } = useParams();
    const intelligentNavigate = useIntelligentNavigate();

    const [easySnackbarMessage, setEasySnackbarMessage] = useState();
    const [displayContent, setDisplayContent] = useState();
    const [displayPostContent, setDisplayPostContent] = useState();
    const [showMode, setShowMode] = useState(mode);

    const navigate = useNavigate();

    useEffect(() => {
        //EasyCache("searchMode", "searchMode", "val", searchMode)
        const fetchPosts = async () => {
            if (showMode === "quote") {
                let result = await GetQuote(post_id)
                console.log("CSR")
                console.log(result)
                setDisplayPostContent(result)
            }
            if (showMode === "repost") {
                let result = await GetRepost(post_id)
                console.log("CSRVREP")
                console.log(result)
                setDisplayContent(result)
            }
            if (showMode === "like") {
                let result = await GetFavorite(post_id)
                console.log("CSRLIK")
                console.log(result)
                setDisplayContent(result)
            }
        };

        fetchPosts();

    }, [showMode]);  // reply.resultsが変更されたときに再度フェッチするように依存配列を設定

    function searchModeSwitchWrapper(mode) {
        if (mode === "quote") {
            setShowMode("quote")
            //intelligentNavigate("quote", 1)
            intelligentNavigate("quote", {root:1, replace:true})
            //navigate('/quote', { replace: true })
        }
        if (mode === "repost") {
            setShowMode("repost")
            //intelligentNavigate("repost", 1)
            intelligentNavigate("repost", {root:1, replace:true})
            //navigate('/repost', { replace: true })
        }
        if (mode === "like") {
            setShowMode("like")
            //intelligentNavigate("like", 1)
            intelligentNavigate("like", {root:1, replace:true})
            //navigate('/like', { replace: true })
        }
    }

    return (
        <>
            <div className="home">
                <UserAuthCheckManager />
                <Sidebar />
                <Header enableBack={true}/>
                <EasySnackbar message={easySnackbarMessage} />

                <div class="mainContentBase">
                    <div class="mainContent">
                        <div class="mainLine" id="mainlinnn">
                            <div className='postType'>
                                <div onClick={() => searchModeSwitchWrapper("repost")} className={showMode === "repost" ? "selected" : ""}>
                                    <span>リポスト</span>
                                </div>
                                <div onClick={() => searchModeSwitchWrapper("quote")} className={showMode === "quote" ? "selected" : ""}>
                                    <span>引用</span>
                                </div>
                                <div onClick={() => searchModeSwitchWrapper("like")} className={showMode === "like" ? "selected" : ""}>
                                    <span>いいね</span>
                                </div>
                            </div>
                            {displayContent && (showMode === "repost" || showMode === "like") && <UserListRenderer importUserList={displayContent} />}
                            {displayPostContent && (showMode === "quote") && <PostListRender postList={displayPostContent} />}
                        </div>
                        <RightSidebar />
                    </div>
                </div>
            </div>
        </>
    );
};

export default FollowerView;