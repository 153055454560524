import React, { useState, useRef, useEffect } from 'react';
import { logout, useIntelligentNavigate } from "common/api/APIUtils"
import "./style.scss";
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

//import { loginUser } from "../../../common/api/APIUtils"

const Header = (enableBack = false, customPage = false) => {
    const intelligentNavigate = useIntelligentNavigate();
    let enableBackMode
    let customPageMode

    if (enableBack.enableBack == true) {
        enableBackMode = true
        if (enableBack.customPage !== false) {
            customPageMode = enableBack.customPage
        } else {
            customPageMode = false
        }
    } else {
        enableBackMode = false
    }
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);
    // メニューの外側がクリックされたときにメニューを閉じる関数
    const closeMenu = (event) => {
        if (event.target.className === 'userInfo') {
            setShowMenu(false);
        }
    };
    const menuRef = useRef();
    useEffect(() => {
        // メニューが表示されているときにのみクリックイベントを監視
        if (showMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        // クリーンアップ関数
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showMenu]);
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenu(false);
        }
    };
    return (

        <div class="header">
            <div className="leftContent">
                {enableBackMode && <FontAwesomeIcon onClick={() => customPageMode ? navigate(customPageMode) : navigate(-1)} icon={faArrowLeft} />}

                <div>Blackbird</div>
            </div>
            <div className="rightContent">
                <div className="userInfo" onClick={() => setShowMenu(!showMenu)}>
                    <div className="userDetal">
                        {localStorage.getItem('access') ?
                            <>
                                <div className="userName">
                                    {localStorage.getItem('user_nickname')}
                                </div>

                                <div className="userId">
                                    @{localStorage.getItem('user_name')}
                                </div>
                            </>
                            :
                            <>
                                <div className="userName">
                                    ゲストモード
                                </div>
                                <div className="userId">
                                    アカウント作成はこちらから
                                </div>
                            </>
                        }
                        <br />
                    </div>
                    <div className="userIcon">
                        <img src={localStorage.getItem('user_icon')} alt="" class="userIcon" />

                    </div>
                </div>
                {showMenu && (
                    <div className="menu" ref={menuRef}>
                        {/* メニューの内容をここに書く */}
                        <div onClick={() => navigate('/about/')}>
                            <FontAwesomeIcon icon={faCircleInfo} />クライアント情報
                            {/*<a className="Link" href="/about/"></a>*/}
                        </div>
                        {localStorage.getItem('user_name') &&
                            <div onClick={() => intelligentNavigate("/settings")}>
                                <FontAwesomeIcon icon={faUserPlus} />設定
                            </div>
                        }
                        {localStorage.getItem('user_name') &&
                            <div onClick={() => intelligentNavigate("/api1.0docs")}>
                                <FontAwesomeIcon icon={faUser} />APIドキュメント
                            </div>
                        }
                        {!localStorage.getItem('user_name') &&
                            <div onClick={() => intelligentNavigate("/signup")}>
                                <FontAwesomeIcon icon={faUserPlus} />アカウント作成
                            </div>
                        }
                        {localStorage.getItem('access') ?
                            <div onClick={() => logout()}>
                                <FontAwesomeIcon icon={faRightFromBracket} />ログアウト
                            </div>
                            :
                            <div onClick={() => intelligentNavigate("/login")}>
                                <FontAwesomeIcon icon={faRightFromBracket} />ログイン
                            </div>
                        }
                    </div>
                )}
            </div>
        </div>

    );
};

export default Header;
