import React, { useEffect, useState, useRef } from 'react'
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; //マップの描写
import { RenderPostIcon, MoveLocation, startup, setup } from './MapComp.js';
import './Map.scss';


export const ViewMap = () => {

    const [position, setPosition] = useState(false);

    useEffect(() => {
        console.log("位置情報取得")
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setPosition([position.coords.latitude, position.coords.longitude]);
            }, (error) => {
                console.log(error);
                setPosition([35.6894, 139.6917]);
            });
        }
    }, []);

    return (
        <>
        {position &&
            <MapContainer
                center={position}
                zoom={startup.zoom}
                scrollWheelZoom={false}
                style={{ height: "100%" , width: "100%" }}
                minZoom={setup.minZoom}
                maxZoom={setup.maxZoom}
                maxBounds={setup.bounds}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {/*関数を記入↓ */}
                <RenderPostIcon />
                <MoveLocation />

            </MapContainer >
            }
        </>
    )
}