import React, { useState, useEffect, useRef, useContext } from 'react';

import { getTimeLine, TokenAuthManager, UserAuthCheckManager, LoginManagerWrapper, PostFavorite, GetEngagement, DeleteFavorite, PostRepost, DeleteRepost, useIntelligentNavigate, DeletePost } from "common/api/APIUtils"

import logo from "assets/img/conceptpdn-white-alpha.png"
import { useNavigate } from 'react-router-dom';
import { SnackbarContext } from 'components/blocks/GlobalSnackbar/SnackbarProvider';

// get our fontawesome imports
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';
import { faRetweet as solidRetweet } from '@fortawesome/free-solid-svg-icons';
import { faMessage as regularMessage } from '@fortawesome/free-regular-svg-icons';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as solidBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as regularBookmark } from '@fortawesome/free-regular-svg-icons';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useModal, ModalBox } from 'components/blocks/PostSubmit/PostSubmit';
import mediaSizeSwitch from "components/blocks/mediaSizeSwitch/mediaSizeSwitch";
import PostsRenderer from "components/blocks/PostsRenderer/PostsRenderer"
import "./style.scss"

// 投稿を表示するためのコンポーネント
const NotificationRenderer = ({ data, mode = false, engagementHidden = false }) => {
    const openSnackbar = useContext(SnackbarContext);
    const { modal, submitFormMode, target, modalOpen, modalClose } = useModal();
    const intelligentNavigate = useIntelligentNavigate()
    const [modeSelect, setModeSelect] = useState();
    const [notification, setNotification] = useState(data);
    const [renderMode, setRenderMode] = useState(mode);

    useEffect(() => {
        // レンダリングのモードを管理する
        if (renderMode === "reply") {
            setModeSelect("reply");
        }
        if (renderMode === "showEngagementButton") {
            setModeSelect("showEngagementButton");
        }
    }, [renderMode]);  // modeを依存配列に追加

//    useEffect(() => {
        // レンダリングのモードを管理する
//        console.log("入力ログ")
//        console.log(notification)
//    }, [notification]);  // modeを依存配列に追加

    //通常投稿とリポスト時のレスポンスを生成
    if (notification?.notification_type === "quote" || notification?.notification_type === "reply") {
        return (
            <>
                {notification?.send_post &&
                    <PostsRenderer post={notification.send_post} showUserFrom={true} />
                }
            </>
        );
    } else if (notification?.notification_type === "mention") {
        return (
            <>
                {notification?.post &&
                    <PostsRenderer post={notification.post} />
                }
            </>
        );
    } else {
        return (
            <div class="notificationContent" onClick={() => {
                (((notification?.notification_type === "favorite") || (notification?.notification_type === "repost")) && intelligentNavigate("/posts/" + notification.post.content.post.post_id) || (notification?.notification_type === "follow") && intelligentNavigate("/profile/" + notification.send_user.username))
            }}>
                <div className='postFrame'>
                    <div className='faLogoFrame'>
                        {/*<img src={logo} alt="" class="logo" />*/}
                        {notification?.notification_type === "favorite" && <FontAwesomeIcon icon={solidHeart} />}
                        {notification?.notification_type === "follow" && <FontAwesomeIcon icon={faUserPlus} />}
                        {notification?.notification_type === "repost" && <FontAwesomeIcon icon={solidRetweet} />}
                    </div>
                    <div className="postContent">
                        <div className='logoFrame'>
                            {/*<img src={logo} alt="" class="logo" />*/}
                            <img onClick={(e) => { e.stopPropagation(); intelligentNavigate("/profile/" + notification.send_user.username); }} src={notification.send_user?.user_icon} alt="" class="logo" />
                            {"typeCheck(post)[0]" === "direct" && <div className='border'></div>}
                            {modeSelect === "reply" && <div className='border'></div>}
                        </div>
                        <p className='postMessage'>
                            {notification?.notification_type === "favorite" &&
                                <>{notification.send_user.nickname}さんがあなたのポストをいいねしました
                                    <br />
                                    <div className='contentInPostMessage'>{notification.post.content.post.post_message}</div>
                                </>
                            }
                            {notification?.notification_type === "repost" &&
                                <>{notification.send_user.nickname}さんがあなたのポストをリポストしました
                                    <br />
                                    <div className='contentInPostMessage'>{notification.post.post_message}</div>
                                </>
                            }
                            {notification?.notification_type === "follow" &&
                                <>{notification.send_user.nickname}さんにフォローされました
                                </>
                            }
                        </p>

                        <div className="postImg">
                            {"typeCheck(post)[1]".media_links?.map((img, num) => (
                                <div onClick={(e) => { e.stopPropagation(); intelligentNavigate("/posts/" + "typeCheck(post)[1]".post_id + "/preview/" + num) }}>
                                    <img src={mediaSizeSwitch(img, "medium")} tabIndex={tab} />
                                </div>
                            ))}
                        </div>

                        {modeSelect == "showEngagementButton" &&
                            <div className='engagement-view-container'>
                                <div className='reply' onClick={() => intelligentNavigate("repost")}>{repostCount}<span>リポスト</span></div>
                                <div className='quote' onClick={() => intelligentNavigate("quote")}>{quoteCount}<span>件の引用</span></div>
                                <div className='favorite' onClick={() => intelligentNavigate("like")}>{favoriteCount}<span>いいね</span></div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default NotificationRenderer;