import { useParams } from 'react-router-dom';
import Header from "components/blocks/Header/Header"
import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import "./style.scss"

import PostRender from "components/blocks/PostsRenderer/PostsRenderer"

import { getPost, getReply, getCursor, getProfile, PostSubmit, QuoteSubmit, ReplySubmit, whoAmI, ProfileEdit } from "common/api/APIUtils"

import EasySnackbar from 'components/blocks/EasySnackbar/EasySnackbar'

import { SnackbarContext } from 'components/blocks/GlobalSnackbar/SnackbarProvider';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';


import Modal from 'react-modal';

export const useModal = () => {
    const [modal, setModal] = React.useState(false);
    const [submitFormMode, setSubmitFormMode] = React.useState("");
    const [target, setTarget] = React.useState("");

    const handleImageChange = (e) => {
        if (e.target.files) {
            // Fileオブジェクトの配列を直接headerImageに保存
            const filesArray = Array.from(e.target.files);
            setHeaderImage(prevImages);
        }
    };


    const modalOpen = (submitFormMode = "", target = "") => {
        setSubmitFormMode(submitFormMode);
        setTarget(target);
        setModal(true);
    };
    const modalClose = () => setModal(false);
    return { modal, submitFormMode, target, modalOpen, modalClose };
};

export const ModalBox = ({ modal, submitFormMode, target, close }) => {
    const [submitProgress, setsubmitProgress] = useState(false);
    const [easySnackbarMessage, setEasySnackbarMessage] = useState();
    const [contentHeight, setContentHeight] = useState('500px');
    const openSnackbar = useContext(SnackbarContext);

    const [message, setMessage] = useState('');

    const [headerImage, setHeaderImage] = useState([]);
    const [iconImage, setIconImage] = useState([]);

    const [selectedHeader, setSelectedHeader] = useState();
    const [selectedHeaderURL, setSelectedHeaderURL] = useState();

    const [profile, setProfile] = useState([false])

    const [username, setUsername] = useState('');
    const [nickname, setNickname] = useState('');
    const [bio, setBio] = useState('');


    useEffect(() => {
        const fetchPosts = async () => {
            const response = await whoAmI();

            //console.log("ポスト取得!!")
            //console.log(response);
            //console.log(user_id)
            setProfile(response);  // レスポンスをステートにセット
            setUsername(response.username)
            setNickname(response.nickname)
            setBio(response.bio)
            //setFollowing(response.is_following)
            //console.log("response")
            //console.log(response.next)
            //	if (response.next) {
            //			setReplyCursor(response.next)
            //		}

            //	console.log("replyCursor1:", replyCursor)
            //	setReplyCursor("STR")
            //	console.log("replyCursor1.1:", response.next)
            //		console.log("replyCursor1.2:", replyCursor)
        };

        fetchPosts();
    }, []);  // post_idが変更されたときに再度フェッチするように依存配列を設定


    //    let reply = false;
    //    let quote = false;
    //    let post_id = false;
    //    if (submitFormMode === "reply") {
    //        reply = true;
    //        post_id = target;
    //    }
    //    if (submitFormMode === "quote") {
    //        quote = true;
    //        post_id = target;
    //    }
    Modal.setAppElement('#root');
    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            padding: "0px",
            width: '100%',
            height: '100%',
            zIndex: '1000',
            backgroundColor: "rgb(255 255 255 / 20%)",
        },
        content: {
            position: 'absolute',  // 絶対位置指定
            width: "600px",
            border: "None",
            padding: "0px",
            overflow: 'None',
            inset: "50% 50% 50% 50%",
        },
    };


    const modalClose = () => {
        close();
    };

    const afterOpen = () => {
        document.body.style.overflow = 'hidden';
    };

    const afterClose = () => {
        document.body.style.overflow = 'unset';
    };




    const handleImageChange = (e) => {
        if (e.target.files) {
            // 最初のFileオブジェクトだけをheaderImageに保存
            setHeaderImage([e.target.files[0]]);
        }
    };

    const handleUserIconChange = (e) => {
        if (e.target.files) {
            // 最初のFileオブジェクトだけをheaderImageに保存
            setIconImage([e.target.files[0]]);
        }
    };

    const handleImageRemove = (index) => {
        setHeaderImage((prevImages) => prevImages.filter((image, i) => i !== index));
    };


    const renderPhotos = (source, aspect) => {
        return useMemo(() => source.map((file, index) => {
            const url = URL.createObjectURL(file);
            return (
                <div style={{
                    width: '100%',
                    height: '100%',
                    paddingBottom: `${aspect}%`,
                    backgroundImage: `url(${url})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }} />
            );
        }), [source]);
    };


    const [posts, setPosts] = useState([false]);



    async function PostSubmitWrapper(message, headerImage) {
        console.log("レングス")
        console.log(headerImage.length)
        console.log(headerImage)
        console.log(typeof (headerImage))

        setsubmitProgress(true)



        console.log("投稿結果ログ")
        console.log(result)
        if (result && result === true) {
            // 投稿成功した場合の処理
            console.log("投稿成功！")
            openSnackbar('投稿しました');
            setHeaderImage([]);
            setIconImage([])
            modalClose()
        } else {
            //setEasySnackbarMessage(result.message)
            openSnackbar('エラーが発生しました: ' + result.message, 'error');
        }
        setsubmitProgress(false)
    }

    useEffect(() => {
        const formElement = document.querySelector('.postSubmitFormContent');
        if (formElement) {
            const formHeight = formElement.offsetHeight;
            console.log(formHeight)
            if (formHeight > 300) {
                setContentHeight('500px');
                console.log("500モード！")
            } else {
                setContentHeight('300px');
                console.log("300モード！")
            }
        }
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        // loginUserは非同期処理であるが、処理が完了してからログインチェックを行わないとログインチェックに失敗する
        // そのためawaitで処理の完了を待つ必要がある
        //	let result = await loginUser(username, password);

        if (result == true) {
            openSnackbar("ログイン成功")
        } else {
            openSnackbar("ログインに失敗しました: " + result.message, 'error')
            if (result?.response?.status === 401) {
                openSnackbar("ユーザー名またはパスワードが間違えています", "error")
            }
        }


        //return (<h1>TTTTTSE</h1>);
        //return <LoginManagerWrapper />
        //navigate("/login")
        // const root = createRoot(document.body);
        // const divFoo = <LoginManagerWrapper />;
        // root.render(divFoo);
        setLoginAttempt(loginAttempt + 1);
        console.log("aaa" + await accessTokenCheck())
    };


 
    async function ProfileEditWrapper() {
        console.log("レングス")
        console.log(headerImage.length)
        console.log(headerImage)
        console.log(typeof (headerImage))

        setsubmitProgress(true)

        //  username = null, nickname = null, bio = null, iconImage, headerImage 
        let apiCallArg = {}

        if (username) {
            apiCallArg.username = username;
        }
        if (nickname) {
            apiCallArg.nickname = nickname;
        }
        if (bio) {
            apiCallArg.bio = bio;
        }
        if (iconImage) {
            apiCallArg.iconImage = iconImage;
        }
        if (headerImage) {
            apiCallArg.headerImage = headerImage;
        }
        console.log("作成した引数")
        console.log(apiCallArg)
        let result = await ProfileEdit(apiCallArg)
        
        if (result && result === true) {
            // 投稿成功した場合の処理
            console.log("変更成功！")
            openSnackbar('プロフィールを変更しました');
            setHeaderImage([]);
            setIconImage([])
            modalClose()
        } else {
            //setEasySnackbarMessage(result.message)
            const message='1行目\n2行目'
            openSnackbar('エラーが発生しました: ' + result.request.response, 'error');
        }
    }

    return (
        <Modal isOpen={modal} overlayClassName="postSubmitFormPortalRoot" onAfterOpen={afterOpen} onRequestClose={close} onAfterClose={afterClose} style={{ content: { height: contentHeight } }}>
            <div class="profileEditorFormRoot">
                <div class="postSubmitFormRoot">
                    <EasySnackbar message={easySnackbarMessage} />
                    <div class="postSubmitFormHeader">
                        <FontAwesomeIcon icon={faPaperPlane} />
                        プロフィール編集
                        <FontAwesomeIcon onClick={modalClose} className='postSubmitFormCloseButton' icon={faXmark} />
                    </div>
                    <div class="postSubmitFormFrame">
                        <div class="postSubmitFormContent">

                            <div class="postSubmitForm">


                                <div className='profileHeader'>
                                    <div className='profileHeaderImage'>
                                        {/*<h2> これは{profile.nickname}のプロフィール画像</h2>*/}
                                        <div className='postSubmitImageSelect'>
                                            <label>
                                                <FontAwesomeIcon icon={faCamera} />
                                                <input type="file" id="file" multiple onChange={handleImageChange} accept=".png, .jpg, .jpeg"/>
                                            </label>
                                            <label>
                                                <FontAwesomeIcon icon={faXmark} />
                                                <input type="button" id="default" multiple/>
                                            </label>
                                            {/*<div className="label-holder">
                                                <label htmlFor="file" className="label">
                                                </label>
                                            </div>*/}
                                            {headerImage.length > 0 ? (
                                                <div className="postSubmitPreviewImg">{renderPhotos(headerImage, 33.33)}</div>
                                            ) : (
                                                <div><img src={profile.user_header} alt="" className='profileUserIcon' />{renderPhotos(headerImage, 33.33)}</div>
                                            )}

                                        </div>
                                    </div>
                                    <div className='profileDetal'>
                                        <div className='profileDetalUpper'>
                                            <div className='profileUserIconFrame'>
                                                <div className="profileUserIcon">
                                                    {iconImage.length > 0 ? (
                                                        <div className='overfit'>{renderPhotos(iconImage, 1)}</div>
                                                    ) : (
                                                        <div><img src={profile.user_icon} alt="" className='overfit' />{renderPhotos(iconImage, 1)}</div>
                                                    )}
                                                    <label>
                                                        <FontAwesomeIcon icon={faCamera} />
                                                        <input type="file" id="file" multiple onChange={handleUserIconChange} accept=".png, .jpg, .jpeg"/>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="profileManagement">
                                                <div className='profileOtherButton'>...</div>
                                                {console.log(profile.is_following)}
                                            </div>
                                        </div>
                                        <form className='seamlessInpiutForm' onSubmit={handleSubmit}>
                                            <div className='profileDetalDown'>
                                                <div className='profileName'>
                                                    <div class="nickname">
                                                        <input
                                                            type="text"
                                                            required
                                                            id="nickname"
                                                            placeholder={profile.nickname}
                                                            value={nickname}
                                                            onChange={(e) => setNickname(e.target.value)}
                                                        />
                                                    </div>
                                                    <div class="username">
                                                        <span>@</span>
                                                        <input
                                                            type="text"
                                                            required
                                                            id="username"
                                                            placeholder={profile.username}
                                                            value={username}
                                                            onChange={(e) => setUsername(e.target.value)}
                                                        />
                                                    </div>
                                                    <div class="bio">
                                                        <textarea
                                                            type="text"
                                                            required
                                                            id="bio"
                                                            placeholder={"プロフィールを入力"}
                                                            value={bio}
                                                            onChange={(e) => setBio(e.target.value)}
                                                            cols="50"
                                                            rows="6"
                                                        />
                                                    </div>

                                                </div>

                                            </div>

                                        </form>

                                    </div>
                                </div>

                            </div>

                 


                        </div>
                    </div>
                    <div className='postSubmitFormFooterNavigation'>
                                <div class="rightContent">
                                    <input type="submit" className='postSubmitButton' value="保存" onClick={() => ProfileEditWrapper()} />
                                </div>

                            </div>
                </div>
            </div>
        </Modal>
    );
};

//export default PostSubmitView;