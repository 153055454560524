import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
//import Sidebar from "./Sidebar";
import "./style.scss"
import logo from "assets/img/conceptpdn-white-alpha.png"

import Header from "components/blocks/Header/Header"

import Sidebar from "components/blocks/Sidebar/Sidebar"

import RightSidebar from "components/blocks/RightSidebar/RightSidebar"

import PostRender from "components/blocks/PostsRenderer/PostsRenderer"

import EasyCache from 'components/blocks/EasyCache/EasyCache';

import EasySnackbar from 'components/blocks/EasySnackbar/EasySnackbar'

import PostListRender from "components/blocks/PostListRenderer/PostListRenderer"

import PullToRefresh from "components/blocks/PullToRefresh/PullToRefresh"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { getTimeLine, TokenAuthManager, UserAuthCheckManager, LoginManagerWrapper, PostFavorite, GetEngagement, DeleteFavorite, getCursor } from "../../../common/api/APIUtils"
let posts
if (await TokenAuthManager()) {
	console.log("認証成功")
	//	let timelines = await getTimeLine()
	//	console.log(timelines)
	//posts = 
}



const Home = () => {
	const navigate = useNavigate();
	const [favorited, setFavorited] = useState(false);

	//	const favorite = (post_id) => {
	//		console.log('いいねしました', post_id);
	//		// ここに実装を書く
	//		//setFavorited(!favorited);
	//		PostFavorite(post_id)
	//	  };
	const favorite = async (post_id) => {
		console.log('いいねしました', post_id);
		// PostFavorite APIを呼び出す
		await PostFavorite(post_id);
		const response = await GetEngagement(post_id)

		// 新しいfavorite_countを取得する（APIのレスポンスから取得または再度APIを呼び出してデータをフェッチ）
		const newFavoriteCount = response.favorite_count; // これは仮のコードで、実際のコードはAPIのレスポンスによります

		// 現在のpostsの状態を保存する
		const currentPosts = [...posts.posts];

		// 更新されたpostsを作成する
		const updatedPosts = currentPosts.map(post =>
			post.post_id === post_id
				? { ...post, favorite_count: newFavoriteCount }
				: post
		);

		// postsの状態を更新する
		setPosts({ ...posts, posts: updatedPosts });
	};

	//	const [posts, setPosts] = useState(() => {
	//		// ローカルストレージからキャッシュを取得
	//		const savedPosts = window.localStorage.getItem('posts');
	//		return savedPosts ? JSON.parse(savedPosts) : [];
	//	});

	const [posts, setPosts] = useState(EasyCache("timeline", "timeline", "json"));

	//	useEffect(() => {
	//		const getTimeline = async () => {
	//			const response = await getTimeLine();
	//			console.log(response);
	//
	//			// レスポンスをローカルストレージに保存
	//			window.localStorage.setItem('posts', JSON.stringify(response));
	//
	//			setPosts(response);
	//		};
	//
	//		// キャッシュが存在しない場合のみAPIを呼び出す
	//		//console.log("キャッシュ状態")
	//		//console.log(posts[0])
	//		if (posts.length === 0 || posts[0] === false) {
	//			getTimeline();
	//		}
	//	}, []);

	useEffect(() => {
		window.addEventListener('beforeunload', () => {
			//sessionStorage.clear();
			console.log("リロード検知1")
			EasyCache("timeline", "timeline", "delete")
		});

		return () => {
			window.removeEventListener('beforeunload', () => {
				//sessionStorage.clear();
				console.log("リロード検知2")
				EasyCache("timeline", "timeline", "delete")
			});
		};
	}, []);

	useEffect(() => {
		const fetchPosts = async () => {
			const data = EasyCache("timeline", "timeline", "json")
			let response
			// セッションからページ状態を復旧する処理
			if (data) {
				console.log("セッションから復旧")
				response = data
			} else {
				//response = await getProfile(user_id);
				response = await getTimeLine();
			}
			setTimeline(response);  // レスポンスをステートにセット


			if (response[0] !== false) {
				EasyCache("timeline", "timeline", "json", response)
			}
			if (response.length === 0 || response[0] === false) {
				await getTimeLine();
			}
		};

		fetchPosts();
	}, []);  // post_idが変更されたときに再度フェッチするように依存配列を設定



	//<p>'Media Link: {post.fields.post_media_link.join(', ')}</p>


	const [showMenu, setShowMenu] = useState(false);
	// メニューの外側がクリックされたときにメニューを閉じる関数
	const closeMenu = (event) => {
		if (event.target.className === 'userInfo') {
			setShowMenu(false);
		}
	};
	const [easySnackbarMessage, setEasySnackbarMessage] = useState();


	const [timeline, setTimeline] = useState(EasyCache("timeline", "timeline", "json"));


	const observer = useRef();
	const lastElementRef = useRef(null);

	useEffect(() => {
		if (observer.current) observer.current.disconnect();

		observer.current = new IntersectionObserver(entries => {
			if (entries[0].isIntersecting) {
				// ここに最後の要素が表示されたときの処理を書く
				console.log('あああ.');
				console.log(timeline)
				EasyCache("timeline", "timeline", "json", timeline)

				// 新しい投稿をフェッチする
				fetchMorePosts();

			}
		});

		if (lastElementRef.current) {
			observer.current.observe(lastElementRef.current);
		}

		return () => {
			if (lastElementRef.current) {
				observer.current.unobserve(lastElementRef.current);
			}
		};
	}, [timeline.results]);  // timeline.resultsが変更されたときに再度フェッチするように依存配列を設定

	// 新しい投稿をフェッチするための非同期関数
	const fetchMorePosts = async () => {
		//console.log("timelineCursor!:", timelineCursor)
		if (timeline.next) {
			const response = await getCursor(timeline.next);
			console.log('fetchMorePosts response:', response);
			console.log('fetchMorePosts response.results:', response.results);
			setTimeline(prevtimeline => ({
				...response,
				results: [...prevtimeline.results, ...response.results]
			}));
			//		if (response.next) {
			//			setTimelineCursor(response.next)
			//	}
		}
	};

	return (
		<>
			<div className="home">
				<UserAuthCheckManager />
				<Sidebar />
				<Header />
				<EasySnackbar message={easySnackbarMessage} />

				<div class="mainContentBase">
					<div class="mainContent">
						<div class="mainLine" id="mainlinnn">
							<PullToRefresh onRefresh={() => sessionStorage.clear() & location.reload()}>
								{0 !== timeline?.results?.length &&
									<div className="postReload" onClick={() => sessionStorage.clear() & location.reload()}>
										<span>新しい投稿を読み込む</span>
									</div>
								}
								<PostListRender postList={timeline} postListKey="Timeline" />

								{0 === timeline?.results?.length &&
									<div className='post'>
										<div className='loginWarn'>
											<div>
												<FontAwesomeIcon icon={faUser} />
												<br />
												アカウントをフォローしてみましょう！
												<br />
												<br />
												まずはグローバルタイムラインを確認してみましょう！
											</div>
										</div>
									</div>
								}
							</PullToRefresh>
						</div>
						<RightSidebar />
					</div>
				</div>
			</div>
		</>
	);
};

export default Home;