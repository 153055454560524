export var localData = [
    { name: "初期地点", pos: [34.985, 135.753] },
    { name: "北海道", pos: [43.064310, 141.346879] },
    { name: "東京都", pos: [35.689501, 139.691722] },
    { name: "愛知県", pos: [35.180209, 136.906582] },
    { name: "京都府", pos: [35.021242, 135.755613] },
    { name: "大阪府", pos: [34.686344, 135.520037] },
    { name: "広島県", pos: [34.396558, 132.459646] },
    { name: "福岡県", pos: [33.606389, 130.417968] },
    { name: "沖縄県", pos: [26.212445, 127.680922] },
    { name: "マキノ駅", pos: [35.46104165491825, 136.0601946052626] },
    { name: "KCG", pos: [34.98506474643144, 135.75267049758284] },
    { name: "京都コンピュータ学院", pos: [34.98506474643144, 135.75267049758284] },
    { name: "USJ", pos: [34.66636237327547, 135.43225333955877] },
    { name: "ユニバーサルスタジオジャパン", pos: [34.66636237327547, 135.43225333955877] },
    { name: "TDL", pos: [35.63291713786023, 139.8804038104651] },
    { name: "東京ディズニーランド", pos: [35.63291713786023, 139.8804038104651] },
]

/*
{ name: "", pos: [] },
*/