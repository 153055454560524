import { useParams, useLocation } from 'react-router-dom';
import Header from "components/blocks/Header/Header"
import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss"

import PostRender from "components/blocks/PostsRenderer/PostsRenderer"

import Sidebar from "components/blocks/Sidebar/Sidebar"

import RightSidebar from "components/blocks/RightSidebar/RightSidebar"

import logo from "assets/img/conceptpdn-white-alpha.png"

import { faCalendarDays, faL } from "@fortawesome/free-solid-svg-icons";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faUmbrella } from "@fortawesome/free-solid-svg-icons";
import { faSkull } from "@fortawesome/free-solid-svg-icons";

import { useIntelligentNavigate, UserAuthCheckManager, getPost, getReply, getCursor, getProfile, getProfilePosts, UserFollow, UserUnfollow } from "common/api/APIUtils"

import EasyCache from 'components/blocks/EasyCache/EasyCache';

import { useModal, ModalBox } from 'components/blocks/ProfileEditor/ProfileEditor';

import PostListRender from "components/blocks/PostListRenderer/PostListRenderer"
import PullToRefresh from "components/blocks/PullToRefresh/PullToRefresh"


function PostView({ mode }) {
	let { username } = useParams();

	const intelligentNavigate = useIntelligentNavigate();

	//const [treePosts, setTreePosts] = useState([false]);

	const [posts, setPosts] = useState([false]);

	const [reply, setReply] = useState(EasyCache("profilePost", username, "json"));

	const [profile, setProfile] = useState([false])

	const [following, setFollowing] = useState(profile.is_following);

	const [usernameState, setUsernameState] = useState(username);

	//const [showMode, setShowMode] = useState(EasyCache("profileShowMode", username, "bypass", "post"));
	const [showMode, setShowMode] = useState(mode);


	const { modal, submitFormMode, target, modalOpen, modalClose } = useModal();

	//const [replyCursor, setReplyCursor] = useState("");

	//	useEffect(() => {
	//		const fetchPosts = async () => {
	//			const response = await getPost(post_id);
	//			console.log("ポスト取得d")
	//			console.log(response);
	//			console.log(post_id)
	//			setPosts(response);  // レスポンスをステートにセット
	//			//setReplyCursor(response.next)
	//			//setReplyCursor("STR")
	//		};
	//
	//		fetchPosts();
	//	}, [user_id]);  // post_idが変更されたときに再度フェッチするように依存配列を設定

	const followManage = async () => {
		console.log('フォロー制御', username);
		if (following) {
			// is_favoritedがtrueの場合、「いいね」を解除するAPIを呼び出す
			//response = await DeleteFavorite(post_id);
			await UserUnfollow(username);
		} else {
			// is_favoritedがfalseの場合、「いいね」をするAPIを呼び出す
			//response = await PostFavorite(post_id);
			await UserFollow(username);
		}

		const response = await getProfile(username);

		setProfile(response); // 値を更新
		setFollowing(response.is_following)
	};

	useEffect(() => {
		window.addEventListener('beforeunload', () => {
			//sessionStorage.clear();
			console.log("リロード検知1")
			EasyCache("profilePost", username, "delete")
		});

		return () => {
			window.removeEventListener('beforeunload', () => {
				//sessionStorage.clear();
				console.log("リロード検知2")
				//EasyCache("profilePost", user_id, "delete")
			});
		};
	}, []);

	useEffect(() => {
		const fetchPosts = async () => {
			console.log("ユーザーネーム")

			console.log(username)
			const response = await getProfile(username);

			//console.log("ポスト取得!!")
			//console.log(response);
			//console.log(user_id)
			setProfile(response);  // レスポンスをステートにセット
			setFollowing(response.is_following)
			//console.log("response")
			//console.log(response.next)
			//	if (response.next) {
			//			setReplyCursor(response.next)
			//		}

			//	console.log("replyCursor1:", replyCursor)
			//	setReplyCursor("STR")
			//	console.log("replyCursor1.1:", response.next)
			//		console.log("replyCursor1.2:", replyCursor)
		};
		fetchPosts();
	}, [username]);  // post_idが変更されたときに再度フェッチするように依存配列を設定

	//	useEffect(() => {
	//		EasyCache("profileShowMode", username, "", showType)
	//	}, [showType]);  // post_idが変更されたときに再度フェッチするように依存配列を設定

	useEffect(() => {
		let isCancelled = false;  // キャンセルフラグを追加

		const fetchPosts = async () => {
			let data = EasyCache("profilePost", username, "json")
			let response;

			if (data) {
				console.log("セッションから復旧")
				response = data;
			} else {
				if (showMode === "post") {
					response = await getProfilePosts(username);
				} else if (showMode === "media") {
					response = await getProfilePosts(username, "has:media");
				} else if (showMode === "reply") {
					response = await getProfilePosts(username, "has:reply");
				} else if (showMode === "favorite") {
					response = await getProfilePosts(username, "has:favorite");
				}
			}

			if (!isCancelled) {  // キャンセルフラグをチェック
				EasyCache("profilePost", username, "json", response)
				setReply(response);
			}
		};

		fetchPosts();

		return () => {
			isCancelled = true;  // クリーンアップ関数でキャンセルフラグを設定
		};
	}, [showMode, username, usernameState]);

	useEffect(() => {
		console.log(reply)
	}, [reply]);  // post_idが変更されたときに再度フェッチするように依存配列を設定

	function showModeSwitchWrapper(mode) {
		// もし別モードが選択された場合は即座に前のコンテンツを除去する
		if (mode !== showMode) {
			setReply("")
		}
		//EasyCache("profilePost", username, "delete")
		sessionStorage.clear()
		if (mode === "post") {
			setShowMode("post")
			intelligentNavigate("", { root: username, replace: true })
		}
		if (mode === "media") {
			setShowMode("media")
			intelligentNavigate("media", { root: username, replace: true })
		}
		if (mode === "reply") {
			setShowMode("reply")
			intelligentNavigate("with_replies", { root: username, replace: true })
		}
		if (mode === "favorite") {
			setShowMode("favorite")
			intelligentNavigate("likes", { root: username, replace: true })
		}
	}


	const observer = useRef();
	const lastElementRef = useRef(null);

	useEffect(() => {
		if (observer.current) observer.current.disconnect();

		observer.current = new IntersectionObserver(entries => {
			if (entries[0].isIntersecting) {
				// ここに最後の要素が表示されたときの処理を書く
				console.log('あああ.');
				console.log(reply)
				EasyCache("profilePost", username, "json", reply)

				// 新しい投稿をフェッチする
				fetchMorePosts();

			}
		});

		if (lastElementRef.current) {
			observer.current.observe(lastElementRef.current);
		}

		return () => {
			if (lastElementRef.current) {
				observer.current.unobserve(lastElementRef.current);
			}
		};
	}, [reply.results]);  // reply.resultsが変更されたときに再度フェッチするように依存配列を設定

	// 新しい投稿をフェッチするための非同期関数
	const fetchMorePosts = async () => {
		//console.log("replyCursor!:", replyCursor)
		if (reply.next) {
			const response = await getCursor(reply.next);
			console.log('fetchMorePosts response:', response);
			console.log('fetchMorePosts response.results:', response.results);
			setReply(prevReply => ({
				...response,
				results: [...prevReply.results, ...response.results]
			}));
			//		if (response.next) {
			//			setReplyCursor(response.next)
			//	}
		}
	};

	const [favorited, setFavorited] = useState(false);



	return (
		<>
			<div className="home">
				<Sidebar />
				<Header enableBack={true} />

				<ModalBox modal={modal} submitFormMode={submitFormMode} target={target} close={modalClose}>
					<button onClick={modalClose}>Close Modal</button>
				</ModalBox>

				<div class="mainContentBase">
					<div class="mainContent">
						<div className='mainLine'>
							<PullToRefresh onRefresh={() => sessionStorage.clear() & location.reload()}>
								<>
									{console.log(profile)}
									{console.log(profile.nickname)}
									<div className='profileHeader'>
										<div className='profileHeaderImage'>
											{/*<h2> これは{profile.nickname}のプロフィール画像</h2>*/}
											<img src={profile.user_header} alt="" className='profileUserIcon' />
										</div>
										<div className='profileDetal'>
											<div className='profileDetalUpper'>
												<div className='profileUserIconFrame'>
													<img src={profile.user_icon} alt="" className='profileUserIcon' />
												</div>
												<div className="profileManagement">
													{/*<div className='profileOtherButton'>...</div>*/}
													<div className='userFollowingManagementContent'>
														{username === localStorage.getItem('user_name') ? <div onClick={() => modalOpen()} className="following">プロフィールを編集する</div> : <div className={following ? "profileFollowingManageButton following" : "profileFollowingManageButton follow"} onClick={() => { followManage(profile.username) }}>{following ? <><span class="followNomal">フォロー中</span><span class="followHover">フォロー解除</span></> : "フォローする"}</div>}												{console.log("sag")}
													</div>
													{console.log(profile.is_following)}
												</div>
											</div>
											<div className='profileDetalDown'>
												<div className='profileName'>
													<span className='profileNickname'>{profile.nickname}</span>
													<br />
													<span className='profileUsername'>@{profile.username}</span>
													{profile.is_follower && <span className='isFollower'>フォローされています</span>}
													<br />
													<br />
													{profile?.bio &&
														<span className='profileBio'>
															{profile.bio.split('\n').map((line, index) => (
																<React.Fragment key={index}>
																	{line.split(/(#[^\s　\p{Punctuation}]+|https?:\/\/[^\s]+|@\w+\b)/gu).map((word, i) => {
																		if (word.startsWith('#')) {
																			return <span key={i} className='tagText cursorPointer' onClick={(e) => { e.stopPropagation(); intelligentNavigate("/search/?tag=" + word.slice(1)) }}>{word}</span>
																		} else if (word.startsWith('@')) {
																			let username = word.slice(1);  // '@'を除去
																			// mentions配列をチェック
																			return <span key={i} className='linkText cursorPointer' onClick={(e) => { e.stopPropagation(); intelligentNavigate("/profile/" + username) }}>{word}</span>
																		} else if (word.startsWith('http')) {
																			return <a key={i} className='linkText cursorPointer' href={word} target="_blank" rel="noopener noreferrer">{word}</a>
																		}
																		return word;
																	})}
																</React.Fragment>
															))}
														</span>
													}
													<br />
													<span className='profileAccountCreatedDate'>
														<FontAwesomeIcon icon={faCalendarDays} />
														{(() => {
															const date = new Date(profile.created_date);
															const year = date.getFullYear();
															const month = date.getMonth() + 1;  // getMonthは0から始まるため、1を足す
															return ` ${year}年${month}月からBlackbirdを利用しています`;
														})()}
													</span>

													<br />
													<span className='followEngagementFrame'>
														<span className='followEngagementContent' onClick={() => intelligentNavigate('following', { root: username })}>
															<span className='followEngagementCount'>
																{profile.following_count}
															</span>
															フォロー中
														</span>
														<span className='followEngagementContent' onClick={() => intelligentNavigate('followers', { root: username })}>
															<span className='followEngagementCount'>
																{profile.follower_count}
															</span>
															フォロワー
														</span>
													</span>
												</div>
											</div>
										</div>
									</div>
								</>

								{profile &&
									<>
										<div className='postType'>
											<div onClick={() => showModeSwitchWrapper("post")} className={showMode === "post" ? "selected" : ""}>
												<span>投稿</span>
											</div>
											<div onClick={() => showModeSwitchWrapper("reply")} className={showMode === "reply" ? "selected" : ""}>
												<span>返信</span>
											</div>
											<div onClick={() => showModeSwitchWrapper("media")} className={showMode === "media" ? "selected" : ""}>
												<span>メディア</span>
											</div>
											<div onClick={() => showModeSwitchWrapper("favorite")} className={showMode === "favorite" ? "selected" : ""}>
												<span>いいね</span>
											</div>
										</div>
										<div className='childPost'>
											{/*こっちが親投稿のリプライを表示する部分 */}
											{reply && <PostListRender postList={reply} postListKey={showMode + username} />}


											{0 === reply?.results?.length &&
												<div className='post'>
													<div className='loginWarn'>
														<FontAwesomeIcon icon={faUmbrella} />
														<br />
														表示できる投稿がないようです...
													</div>
												</div>
											}


											{(showMode === "favorite") && (localStorage.getItem('user_name') == null) &&
												<div className='post'>
													<div className='loginWarn'>
														<FontAwesomeIcon icon={faTriangleExclamation} />
														<br />
														閲覧するにはログインが必要です
													</div>
												</div>
											}
										</div>
									</>
								}
								{!profile &&
									<div className='post'>
										<div className='loginWarn'>
											<FontAwesomeIcon icon={faSkull} />
											<br />
											ユーザーが見つかりませんでした
										</div>
									</div>
								}
							</PullToRefresh>
						</div>
						<RightSidebar />
					</div>
				</div>
			</div>
		</>
	);
}

export default PostView;
