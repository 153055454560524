import React, { useState, useContext } from 'react';
import MaskedInput from 'react-text-mask';
import axios from 'axios';
import "./style.scss";
import logo from "assets/img/conceptpdn-white-alpha.png"


import { Signup, useIntelligentNavigate, logout, allCleanup, EmailAuthenticated, EmailCodeSubmit } from "common/api/APIUtils";
import { SnackbarContext } from 'components/blocks/GlobalSnackbar/SnackbarProvider';

import { Helmet } from 'react-helmet';

//import { loginUser } from "../../../common/api/APIUtils"

const SignupForm = () => {
	const [username, setUsername] = useState('');
	const [nickname, setNickname] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [birthday, setBirthday] = useState('');
	const [tokenId, setTokenId] = useState('');
	const [activationCode, setActivationCode] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const [prosessing, setProsessing] = useState(false);

	const openSnackbar = useContext(SnackbarContext);

	const [page, setPage] = useState(0);

	const intelligentNavigate = useIntelligentNavigate();

	const handleEmailSubmit = async (e) => {
		e.preventDefault();
		setProsessing(true)
		let result = await EmailAuthenticated(email);
		setProsessing(false)
		console.log("result")
		if (result?.name === "AxiosError") {
			openSnackbar("エラーが発生しました: " + result, "error")
			openSnackbar(result.request.response, "error")
		} else if (result?.message) {
			setTokenId(result.token_id)
			setPage(1)
			openSnackbar("認証コードがメールに送信されました")
		} else {
			openSnackbar("エラーが発生しました: " + result, "error")
			openSnackbar(result.request.response, "error")
		}
	}

	const handleCodeSubmit = async (e) => {
		e.preventDefault();
		setProsessing(true)
		let result = await EmailCodeSubmit(tokenId, activationCode);
		setProsessing(false)
		console.log("result")
		if (result == true) {
			openSnackbar("認証完了")
		} else {
			openSnackbar("エラーが発生しました: " + result, "error")
			openSnackbar(result.request.response, "error")
		}
		if (result == true) {
			// 次の認証コード入力画面に飛ばす
			setPage(2)
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		setProsessing(true)
		let result = await Signup(username, nickname, tokenId, password, birthday);
		setProsessing(false)
		console.log("result")
		console.log(birthday)

		console.log(result)
		if (result == true) {
			openSnackbar("アカウントが作成されました")
		} else {
			openSnackbar("エラーが発生しました: " + result, "error")
			openSnackbar(result.request.response, "error")
		}
		if (result == true) {
			allCleanup()
			//intelligentNavigate("/login")
			setPage(3)
		}
	};

	const formatBirthdayOLD = (birthday) => {
		console.log(birthday.slice(-2, -1))
		if (birthday.slice(-2, -1) === "-") {
			return birthday
		}
		if (birthday.length === 4) {
			//return birthday.slice(0, 4) + '-' + birthday.slice(4, 6) + '-' + birthday.slice(6, 8);
			return birthday.slice(0, 4) + '-';
		} else if (birthday.length === 7) {
			return birthday.slice(0, 7) + '-';
		} else {
			return birthday;
		}
	}


	return (
		<>
			<div className='blackbirdLoginFormRoot'>
				<div class="container">
					<Helmet>
						<script src="https://www.google.com/recaptcha/enterprise.js?render=6LfHvIUpAAAAAGKt7B9UnXTN8AL3587h3ml-Uv1-"></script>
					</Helmet>
					<div class="logo">
						<img src={logo} alt="" class="logo" />
					</div>
					{(page == 0) &&
						<>
							<div class="elements">
								<p class="text" id="title">アカウントを作成</p>
							</div>
							<div class="elements">
								<p class="text">電子メールを入力してください</p>
							</div>
							<form onSubmit={handleEmailSubmit}>
								<div class="form">
									<input
										type="text"
										required
										id="email"
										placeholder="Email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>
								<div class="elements topMargin">
									{prosessing ?
										<div id="loginbuttonDummy">送信中...</div>
										:
										<button type="submit" id="loginbutton">次に進む</button>
									}
								</div>
								<br />
								<div onClick={() => { intelligentNavigate("/login") }}>
									<span className='cursorPointer'>既にアカウントをお持ちですか？</span>
									<br />
									<span className='cursorPointer'>ログインする</span>
								</div>
							</form>
						</>}
					{(page == 1) &&
						<>
							<div class="elements">
								<p class="text" id="title">アカウントを作成</p>
							</div>
							<div class="elements">
								<p class="text">Emailに送信された5桁の認証コードを入力してください</p>
							</div>
							<form onSubmit={handleCodeSubmit}>
								<div class="form">
									<input
										type="text"
										required
										id="activationCode"
										placeholder="認証コード"
										value={activationCode}
										onChange={(e) => setActivationCode(e.target.value)}
									/>
								</div>
								<div class="elements topMargin">
									{prosessing ?
										<div id="loginbuttonDummy">送信中...</div>
										:
										<button type="submit" id="loginbutton">次に進む</button>
									}
								</div>
								<br />
								<div className='cursorPointer' onClick={() => { location.reload() }}>
									<span>認証コードが届きませんか？</span>
									<br />
									<span>もう一度やり直しましょう</span>
								</div>
							</form>
						</>}
					{(page == 2) &&
						<>
							<div class="elements">
								<p class="text" id="title">アカウントを作成</p>
							</div>
							<form onSubmit={handleSubmit}>
								<div class="form">
									<input
										type="text"
										required
										id="username"
										placeholder="@ユーザー名"
										value={username}
										onChange={(e) => setUsername(e.target.value)}
									/>
									<input
										type="text"
										required
										id="nickname"
										placeholder="ニックネーム"
										value={nickname}
										onChange={(e) => setNickname(e.target.value)}
									/>
									<br />
									<input
										type={showPassword ? "text" : "password"}
										required
										id="password"
										placeholder="パスワード"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
									{/*<input
									type="text"
									required
									id="birthday"
									placeholder="生年月日 例：20230114"
									//value={formatBirthday(birthday)}
			
									onChange={(e) => setBirthday(e.target.value)}
								/>*/}
									<MaskedInput
										type="text"
										required
										id="birthday"
										placeholder="生年月日 例：20230114"
										mask={[/[1-2]/, /\d/, /\d/, /\d/, '-', /[0-1]/, /\d/, '-', /\d/, /\d/]}
										onChange={(e) => setBirthday(e.target.value)}
									/>
								</div>
								<div class="checkboxXXX">
									<input type="checkbox" id="showPassword" onClick={() => { setShowPassword(!showPassword); }} />
									<label for="showPassword">パスワードの表示</label>
								</div>
								<div class="elements">
									{prosessing ?
										<div id="loginbuttonDummy">送信中...</div>
										:
										<button type="submit" id="loginbutton">アカウントを作成</button>
									}
								</div>
								<br />
								<div onClick={() => { intelligentNavigate("/login") }}>
									<span className='cursorPointer'>既にアカウントをお持ちですか？</span>
									<br />
									<span className='cursorPointer'>ログインする</span>
								</div>
							</form>
						</>}
					{(page == 3) &&
						<>
							<div class="elements">
								<p class="text" id="title">アカウント作成完了！</p>
							</div>
							<h1>🎉</h1>
							<br />
							<span>Welcome to the Blackbird Family</span>
							<br />
							<span>Blackbirdへようこそ！！</span>
							<button onClick={() => { intelligentNavigate("/login") }} id="loginbutton" style={{ position: "relative", left: "50%", transform: "translateX(-50%)", marginTop: "40px" }}>続ける</button>
						</>}
				</div>
			</div>
		</>
	);
};

export default SignupForm;
