import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
//import Sidebar from "./Sidebar";
import "./style.scss"
import logo from "assets/img/conceptpdn-white-alpha.png"
import Header from "components/blocks/Header/Header"
import Sidebar from "components/blocks/Sidebar/Sidebar"
import RightSidebar from "components/blocks/RightSidebar/RightSidebar"
import PostRender from "components/blocks/PostsRenderer/PostsRenderer"
import EasyCache from 'components/blocks/EasyCache/EasyCache';
import EasySnackbar from 'components/blocks/EasySnackbar/EasySnackbar'
import { getTimeLine, TokenAuthManager, UserAuthCheckManager, LoginManagerWrapper, PostFavorite, GetEngagement, DeleteFavorite, getCursor, getGlobalTimeLine, GetBookmark } from "../../../common/api/APIUtils"
import PostListRender from "components/blocks/PostListRenderer/PostListRenderer"
import PullToRefresh from "components/blocks/PullToRefresh/PullToRefresh"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";

let posts
if (await TokenAuthManager()) {
	console.log("認証成功")
}

const Home = () => {
	const navigate = useNavigate();
	const [favorited, setFavorited] = useState(false);

	const favorite = async (post_id) => {
		console.log('いいねしました', post_id);
		// PostFavorite APIを呼び出す
		await PostFavorite(post_id);
		const response = await GetEngagement(post_id)

		// 新しいfavorite_countを取得する（APIのレスポンスから取得または再度APIを呼び出してデータをフェッチ）
		const newFavoriteCount = response.favorite_count; // これは仮のコードで、実際のコードはAPIのレスポンスによります

		// 現在のpostsの状態を保存する
		const currentPosts = [...posts.posts];

		// 更新されたpostsを作成する
		const updatedPosts = currentPosts.map(post =>
			post.post_id === post_id
				? { ...post, favorite_count: newFavoriteCount }
				: post
		);

		// postsの状態を更新する
		setPosts({ ...posts, posts: updatedPosts });
	};

	const [posts, setPosts] = useState(EasyCache("BookmarkTimeline", "BookmarkTimeline", "json"));

	useEffect(() => {
		window.addEventListener('beforeunload', () => {
			//sessionStorage.clear();
			console.log("リロード検知1")
			EasyCache("BookmarkTimeline", "BookmarkTimeline", "delete")
		});

		return () => {
			window.removeEventListener('beforeunload', () => {
				//sessionStorage.clear();
				console.log("リロード検知2")
				EasyCache("BookmarkTimeline", "BookmarkTimeline", "delete")
			});
		};
	}, []);

	useEffect(() => {
		const fetchPosts = async () => {
			const data = EasyCache("BookmarkTimeline", "BookmarkTimeline", "json")
			let response
			// セッションからページ状態を復旧する処理
			if (data) {
				console.log("セッションから復旧")
				response = data
			} else {
				//response = await getProfile(user_id);
				response = await GetBookmark();
			}
			setTimeline(response);  // レスポンスをステートにセット

			if (response[0] !== false) {
				EasyCache("BookmarkTimeline", "BookmarkTimeline", "json", response)
			}
			if (response.length === 0 || response[0] === false) {
				await GetBookmark();
			}
		};

		fetchPosts();
	}, []);  // post_idが変更されたときに再度フェッチするように依存配列を設定

	const [showMenu, setShowMenu] = useState(false);
	// メニューの外側がクリックされたときにメニューを閉じる関数
	const closeMenu = (event) => {
		if (event.target.className === 'userInfo') {
			setShowMenu(false);
		}
	};
	const [easySnackbarMessage, setEasySnackbarMessage] = useState();
	const [timeline, setTimeline] = useState(EasyCache("BookmarkTimeline", "BookmarkTimeline", "json"));

	return (
		<>
			<div className="home">
				<UserAuthCheckManager />
				<Sidebar />
				<Header enableBack={true} />
				<EasySnackbar message={easySnackbarMessage} />

				<div class="mainContentBase">
					<div class="mainContent">
						<div class="mainLine" id="mainlinnn">
							<PullToRefresh onRefresh={() => sessionStorage.clear() & location.reload()}>
								<PostListRender postList={timeline} postListKey="Bookmark" />
								{0 === timeline?.results?.length &&
									<div className='post'>
										<div className='loginWarn'>
											<FontAwesomeIcon icon={faThumbtack} />
											<br />
											お気に入りの投稿を追加しましょう！
										</div>
									</div>
								}
							</PullToRefresh>
						</div>
						<RightSidebar />
					</div>
				</div>
			</div>
		</>
	);
};

export default Home;