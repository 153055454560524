import { Link, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
//import Sidebar from "./Sidebar";
import "./style.scss"
import logo from "assets/img/conceptpdn-white-alpha.png"

import Header from "components/blocks/Header/Header"

import Sidebar from "components/blocks/Sidebar/Sidebar"

import RightSidebar from "components/blocks/RightSidebar/RightSidebar"

import PostRender from "components/blocks/PostsRenderer/PostsRenderer"

import EasyCache from 'components/blocks/EasyCache/EasyCache';

import EasySnackbar from 'components/blocks/EasySnackbar/EasySnackbar'

import { getTimeLine, TokenAuthManager, UserAuthCheckManager, LoginManagerWrapper, PostFavorite, GetEngagement, DeleteFavorite, getCursor, GetFollowing, GetFollower } from "../../../common/api/APIUtils"

import UserListRenderer from "components/blocks/UserListRenderer/UserListRenderer"

import { useIntelligentNavigate } from "common/api/APIUtils"



let posts
if (await TokenAuthManager()) {
    console.log("認証成功")
    //	let timelines = await getTimeLine()
    //	console.log(timelines)
    //posts = 
}



const FollowerView = ({ mode }) => {
    let { username } = useParams();
    console.log("ユーザ名は" + username)
    const intelligentNavigate = useIntelligentNavigate();

    const [easySnackbarMessage, setEasySnackbarMessage] = useState();
    const [userList, setUserList] = useState();
    const [showMode, setShowMode] = useState(mode);





    useEffect(() => {
        //EasyCache("searchMode", "searchMode", "val", searchMode)
        const fetchPosts = async () => {
            if (showMode === "following") {
                let result = await GetFollowing(username)
                console.log("CSR")
                console.log(result)
                setUserList(result)
            }
            if (showMode === "followers") {
                let result = await GetFollower(username)
                console.log("CSRV")
                console.log(result)
                setUserList(result)
            }
        };

        fetchPosts();


        //setUserlist("")
        //setReply(EasyCache("searchPost", "searchPost", "json"))
        //setReply("")

        //setKeyword("")
        //setKeyword("")
        //EasyCache("userList", user_id, "delete")
        //EasyCache("profilePost", user_id, "delete")
    }, [showMode]);  // reply.resultsが変更されたときに再度フェッチするように依存配列を設定

    function searchModeSwitchWrapper(mode) {
        if (mode === "following") {
            setUserList("")
            setShowMode("following")
            intelligentNavigate("following", {root:1})
        }
        if (mode === "followers") {
            setShowMode("followers")
            intelligentNavigate("followers", {root:1})
        }
    }

    return (
        <>
            <div className="home">
                <UserAuthCheckManager />
                <Sidebar />
                <Header enableBack={true}/>
                <EasySnackbar message={easySnackbarMessage} />

                <div class="mainContentBase">
                    <div class="mainContent">
                        <div class="mainLine" id="mainlinnn">
                            <div className='postType'>
                                <div onClick={() => searchModeSwitchWrapper("following")} className={showMode === "following" ? "selected" : ""}>
                                    <span>フォロー中</span>
                                </div>
                                <div onClick={() => searchModeSwitchWrapper("followers")} className={showMode === "followers" ? "selected" : ""}>
                                    <span>フォロワー</span>
                                </div>
                            </div>
                            {userList && <UserListRenderer importUserList={userList} />}
                        </div>
                        <RightSidebar />
                    </div>
                </div>
            </div>
        </>
    );
};

export default FollowerView;