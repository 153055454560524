import { useParams } from 'react-router-dom';
import Header from "components/blocks/Header/Header"
import React, { useState, useEffect, useRef } from 'react';
import "./style.scss"

import PostRender from "components/blocks/PostsRenderer/PostsRenderer"

import Sidebar from "components/blocks/Sidebar/Sidebar"

import RightSidebar from "components/blocks/RightSidebar/RightSidebar"

import logo from "assets/img/conceptpdn-white-alpha.png"

function About() {
	return (
		<>
			<div className="home">
				<Sidebar />
				<Header enableBack={true} />

				<div class="mainContentBase">
					<div class="mainContent">
						<div className='mainLine'>
							<div className='aboutMessage'>
								<h1>Blackbird API 1.0 Docs(仮)</h1>
								<h3>Powered by DjangoRestFramework</h3>

								<p>BlackbirdAPI1.0の各エンドポイント集です</p>
								<p>こちらのドキュメントは現在整備中であり、一部誤りや見づらい点があるかもしれません</p>

								<p>ルートURL: https://b-bird.net:8000/api/</p>
								<ul>
									<li>1.0/</li>
									<li>1.0/post/</li>
									<li>1.0/access-token-check/</li>
									<li>1.0/user_timeline/</li>
									<li>1.0/global_timeline/</li>
									<li>1.0/who-am-i/</li>
									<li>1.0/trend/</li>
									<li>1.0/post-get/</li>
									<li>1.0/notification/</li>
									<li>1.0/[str:post_id]/engagement_count/</li>
									<li>1.0/signup/</li>
									<li>1.0/[str:post_id]/bookmark/</li>
									<li>1.0/bookmark/</li>
									<li>1.0/[str:target_user_id]/following/</li>
									<li>1.0/[str:target_user_id]/follower/</li>
									<li>1.0/[str:post_id]/post/</li>
									<li>1.0/[str:post_id]/favorite/</li>
									<li>1.0/[str:post_id]/repost/</li>
									<li>1.0/[str:post_id]/quote/</li>
									<li>1.0/[str:post_id]/reply/</li>
									<li>1.0/settings/profile/</li>
									<li>1.0/[str:username]/profile/</li>
									<li>1.0/[str:username]/profile-posts/</li>
									<li>1.0/[str:username]/change_password/</li>
									<li>1.0/search/keyword/</li>
									<li>1.0/search/user/</li>
								</ul>
								<p>Copyright 2023 RavenDevelopTeam.</p>
							</div>
						</div>
						<RightSidebar />
					</div>
				</div>
			</div>
		</>
	);
}

export default About;
