import { Link } from "react-router-dom";
import "./style.scss"
import logo from "assets/img/logo-white-alpha.png"
import { LoginManagerWrapper } from "common/api/APIUtils"
import { useIntelligentNavigate } from "common/api/APIUtils"

const Wellcome = () => {
	const intelligentNavigate = useIntelligentNavigate();
	return (
		<div className="wellcomeContainer">
			<LoginManagerWrapper />
			<img src={logo} alt="" class="wellcomeLogo" />
			<div className="messageContent">
				<div class="centerMessage">
					<h2><span>Botを除いた<span>すべての話題が、</span></span><span>ここに。</span></h2>
					<div className="login">
						<h4>今すぐ参加しよう。</h4>
						<div className="button cursorPointer" onClick={() => { intelligentNavigate("/signup") }}>
							<p>アカウントを作成</p>
							{/*<a class="Link" href="/signup/"></a>*/}
						</div>
						<div className="button cursorPointer" onClick={() => { intelligentNavigate("/login") }}>
							<p>ログイン</p>
							{/*<a class="Link" href="/login/"></a>*/}
						</div>
						<p class="text cursorPointer" onClick={() => { intelligentNavigate("/global") }}>タイムラインを覗いてみる</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Wellcome;