import React, { useState, useEffect, useRef, useContext } from 'react';

import { getTimeLine, TokenAuthManager, UserAuthCheckManager, LoginManagerWrapper, PostFavorite, GetEngagement, DeleteFavorite, PostRepost, DeleteRepost, useIntelligentNavigate, DeletePost, postBookmark, deleteBookmark } from "common/api/APIUtils"


import logo from "assets/img/conceptpdn-white-alpha.png"
import { useNavigate } from 'react-router-dom';
import { SnackbarContext } from 'components/blocks/GlobalSnackbar/SnackbarProvider';

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';

import { faRetweet as solidRetweet } from '@fortawesome/free-solid-svg-icons';

import { faMessage as regularMessage } from '@fortawesome/free-regular-svg-icons';

import { faChartBar } from '@fortawesome/free-solid-svg-icons';

import { faReply } from '@fortawesome/free-solid-svg-icons';

import { faBookmark as solidBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as regularBookmark } from '@fortawesome/free-regular-svg-icons';

import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faHippo } from '@fortawesome/free-solid-svg-icons';

import { useModal, ModalBox } from 'components/blocks/PostSubmit/PostSubmit';

import mediaSizeSwitch from "components/blocks/mediaSizeSwitch/mediaSizeSwitch"

import DisplayTime from "components/blocks/DisplayTime/DisplayTime"

import "./style.scss"
import EasyCache from '../EasyCache/EasyCache';
import { pos } from '../Map/MapComp';

//import { faRetweet as regularRetweet } from '@fortawesome/free-regular-svg-icons'; // 輪郭のみのアイコン
//import { faHeart } from '@fortawesome/free-svg-icons';

let modeSelect
let tab = 0;//tabIndex用


const typeCheck = (post) => {
    if (modeSelect === "reply") {
        return ["direct", post.content.post, post.content.reply]
    }
    //オブジェクトが存在していない場合にエラーとならないように"?"をつけています
    if (post?.content?.post) {
        if (post.content.repost) {
            return ["quote", post.content.post, post.content.repost]
        } else if (post.content.reply) {
            return ["reply", post.content.post, post.content.reply]
        } else {
            return ["post", post.content.post]
        }
    } else if (post?.content?.repost) {
        return ["repost", post.content.repost]
    } else {
        return ["direct", post]
    }
}

// 投稿を表示するためのコンポーネント
const PostsRenderer = ({ post, mode = false, engagementHidden = false, showUserFrom = false, replyBorder = false, hidePoem = false }) => {
    const openSnackbar = useContext(SnackbarContext);
    const { modal, submitFormMode, target, modalOpen, modalClose } = useModal();
    const [postState, setPostState] = useState(post)

    useEffect(() => {
        setPostState(post);
        const postOBJ = EasyCache((typeCheck(post)[1].post_id), "post_object", "json")
        if (postOBJ !== false) {
            setPostState(postOBJ)
            //EasyCache((typeCheck(postState)[1].post_id), "post_object", "json", postOBJ)
        }
    }, [post]);  // post を依存配列に追加して、post が更新されたときにエフェクトを実行

    tab += 1;
    //console.log(tab)

    const intelligentNavigate = useIntelligentNavigate();
    const navigate = useNavigate();

    const [replyBorderMode, setReplyBorderMode] = useState(replyBorder);
    const [hidePoemMode, setHidePoemMode] = useState(hidePoem);
    

    const [favorited, setFavorited] = useState(typeCheck(postState)[1].engagement.is_favorited);
    const [reposted, setReposted] = useState(typeCheck(postState)[1].engagement.is_reposted);
    const [bookmarked, setBookmarked] = useState(typeCheck(postState)[1].engagement.is_bookmaked);

    const [favoriteCount, setFavoriteCount] = useState(typeCheck(postState)[1].engagement.favorite_count);
    const [repostCount, setRepostCount] = useState(typeCheck(postState)[1].engagement.repost_count);
    const [quoteCount, setQuoteCount] = useState(typeCheck(postState)[1].engagement.quote_count);
    const [replyCount, setReplyCount] = useState(typeCheck(postState)[1].engagement.reply_count);
    const [visitedCount, setVisitedCount] = useState(typeCheck(postState)[1].engagement.visited_count);

    const [modeSelect, setModeSelect] = useState();

    const [mediaHidden, setMediaHidden] = useState(typeCheck(postState)[1].nsfw_status);

    const [renderMode, setRenderMode] = useState(mode);

    const [showFrom, setShowFrom] = useState(showUserFrom)

    useEffect(() => {
        setFavorited(typeCheck(postState)[1].engagement.is_favorited);
        setReposted(typeCheck(postState)[1].engagement.is_reposted);
        setBookmarked(typeCheck(postState)[1].engagement.is_bookmaked);
    
        setFavoriteCount(typeCheck(postState)[1].engagement.favorite_count);
        setRepostCount(typeCheck(postState)[1].engagement.repost_count);
        setQuoteCount(typeCheck(postState)[1].engagement.quote_count);
        setReplyCount(typeCheck(postState)[1].engagement.reply_count);
        setVisitedCount(typeCheck(postState)[1].engagement.visited_count);
    }, [postState]);  // post を依存配列に追加して、post が更新されたときにエフェクトを実行

    useEffect(() => {
        // レンダリングのモードを管理する
        if (renderMode === "reply") {
            setModeSelect("reply");
        }
        if (renderMode === "showEngagementButton") {
            setModeSelect("showEngagementButton");
        }
    }, [renderMode]);  // modeを依存配列に追加

    const postClick = (post_id) => {
        //history.push('/posts/'+post_id);
        localStorage.setItem('timeline-scroll-position', window.scrollY);
        intelligentNavigate('/posts/' + post_id);
    }

    const postUsernameClick = (user_id) => {
        //history.push('/posts/'+post_id);
        localStorage.setItem('timeline-scroll-position', window.scrollY);
        intelligentNavigate('/profile/' + user_id);
    }

    function engagementUpdate(response, update = false) {

        if (localStorage.getItem('user_name')) {

            if (update) {
                // 強引にエンゲージメントをキャッシュしています
                const UpdatePost = postState
                UpdatePost.content.post.engagement = response
                EasyCache((typeCheck(post)[1].post_id), "post_object", "json", UpdatePost)
            }

            const newFavorited = response.is_favorited
            const newReposted = response.is_reposted
            const newFavoriteCount = response.favorite_count; // これは仮のコードで、実際のコードはAPIのレスポンスによります
            const newRepostCount = response.repost_count
            const newQuoteCount = response.quote_count
            const newReplyCount = response.reply_count
            const newVisitedCount = response.visited_count
            const newBookmarked = response.is_bookmarked

            // favoritedとfavoriteCountの状態を更新する
            //setFavorited(!favorited);
            setFavoriteCount(newFavoriteCount);
            setRepostCount(newRepostCount);
            setQuoteCount(newQuoteCount);
            setReplyCount(newReplyCount);
            setFavorited(newFavorited)
            setReposted(newReposted)
            setBookmarked(newBookmarked)
            setVisitedCount(newVisitedCount)
        } else {
            setFavorited(false)
            setReposted(false)
        }
    }

    useEffect(() => {
        // エンゲージメントを更新
        async function updateReplyCount() {
            const post_id = typeCheck(postState)[1].post_id; // ここでpost_idを取得

            if (typeCheck(postState)[1]?.engagement) {
                engagementUpdate(typeCheck(postState)[1].engagement)
            }
        }
        setMediaHidden(typeCheck(postState)[1].nsfw_status)
        updateReplyCount();
        
    }, [post]); // postが変更されたときにこのuseEffectが実行されます

    const repost = async (post_id) => {
        console.log('Repostしました', post_id);
        if (reposted) {
            // is_favoritedがtrueの場合、「いいね」を解除するAPIを呼び出す
            //response = await DeleteFavorite(post_id);
            await DeleteRepost(post_id);
        } else {
            // is_favoritedがfalseの場合、「いいね」をするAPIを呼び出す
            //response = await PostFavorite(post_id);
            await PostRepost(post_id);
        }

        const response = await GetEngagement(post_id)

        // 新しいfavorite_countを取得する（APIのレスポンスから取得または再度APIを呼び出してデータをフェッチ）
        engagementUpdate(response, true)
    };

    const favorite = async (post_id) => {
        console.log('いいねしました', post_id);
        if (favorited) {
            // is_favoritedがtrueの場合、「いいね」を解除するAPIを呼び出す
            //response = await DeleteFavorite(post_id);
            await DeleteFavorite(post_id);
        } else {
            // is_favoritedがfalseの場合、「いいね」をするAPIを呼び出す
            //response = await PostFavorite(post_id);
            await PostFavorite(post_id);
        }

        const response = await GetEngagement(post_id)

        // 新しいfavorite_countを取得する（APIのレスポンスから取得または再度APIを呼び出してデータをフェッチ）
        engagementUpdate(response, true)
    };

    const bookmark = async (post_id) => {
        console.log('ブックマークしました', post_id);
        if (bookmarked) {
            // is_favoritedがtrueの場合、「いいね」を解除するAPIを呼び出す
            //response = await DeleteFavorite(post_id);
            await deleteBookmark(post_id);
        } else {
            // is_favoritedがfalseの場合、「いいね」をするAPIを呼び出す
            //response = await PostFavorite(post_id);
            await postBookmark(post_id);
        }

        const response = await GetEngagement(post_id)

        // 新しいfavorite_countを取得する（APIのレスポンスから取得または再度APIを呼び出してデータをフェッチ）
        engagementUpdate(response, true)
    };

    const repost_before = (n) => {
        //setFavorited(!favorited);
        setReposted(!reposted);
        repost(n)
    }

    const favorite_before = (n) => {
        //setFavorited(!favorited);
        setFavorited(!favorited);
        favorite(n)
    }

    const bookmark_before = (n) => {
        setBookmarked(!bookmarked);
        bookmark(n)
    }

    const mediaHiddenSwitch = () => {
        //setFavorited(!favorited);
        setMediaHidden(!mediaHidden);
    }

    const [showRepostMenu, setshowRepostMenu] = useState(false);
    const [showPostOtherMenu, setshowPostOtherMenu] = useState(false);

    // メニューの外側がクリックされたときにメニューを閉じる関数
    const closeMenu = (event) => {
        if (event.target.className === 'userInfo') {
            setshowRepostMenu(false);
        }
    };
    const menuRef = useRef();
    useEffect(() => {
        // メニューが表示されているときにのみクリックイベントを監視
        if (showRepostMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        // クリーンアップ関数
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showRepostMenu]);
    useEffect(() => {
        // メニューが表示されているときにのみクリックイベントを監視
        if (showPostOtherMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        // クリーンアップ関数
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showPostOtherMenu]);
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setshowRepostMenu(false);
            setshowPostOtherMenu(false);
        }
    };

    async function deletePost(post_id) {
        let result = await DeletePost(post_id)
        console.log("result")
        console.log(result)
        if (result == true) {
            openSnackbar("投稿を削除しました")
        } else {
            openSnackbar("エラーが発生しました: " + result?.message, "error")
        }
    }


    const [isTextSelected, setIsTextSelected] = useState(false);
    const handleMouseDown = (event, link) => {
        setIsTextSelected(false);

        // ホイールクリックを検出
        if (event.button === 1 || (event.button === 0 && event.ctrlKey)) {
            event.preventDefault();  // デフォルトのホイールクリック動作（通常はページのスクロール）を防ぐ
            //console.log('ホイールクリックが検出されました！');
            intelligentNavigate(link, { newTab: true });
        }
    };

    const handleMouseUp = () => {
        const selectedText = window.getSelection().toString();
        if (selectedText.length > 0) {
            setIsTextSelected(true);
        }
    };

    const handleClick = (link) => {
        if (!isTextSelected) {
            // テキストが選択されていない場合のみ、クリックイベントを実行
            postClick(link);
        }
    };

    //通常投稿とリポスト時のレスポンスを生成
    if (typeCheck(postState)[0] === "post" || typeCheck(postState)[0] === "repost" || typeCheck(postState)[0] === "reply" || typeCheck(postState)[0] === "direct") {
        // ここでpostを表示する
        return (
            <>
                <ModalBox modal={modal} submitFormMode={submitFormMode} target={target} close={modalClose}>
                    <button onClick={modalClose}>Close Modal</button>
                </ModalBox>
                <div class="post"
                    onMouseDown={engagementHidden ? undefined : () => handleMouseDown(event, '/posts/' + typeCheck(postState)[1].post_id)}
                    onMouseUp={engagementHidden ? undefined : handleMouseUp}
                    onClick={engagementHidden ? undefined : () => handleClick(typeCheck(postState)[1].post_id)}
                >
                    <div className='postPoem'>
                        {typeCheck(postState)[0] === "repost" && <span><FontAwesomeIcon icon={solidRetweet}></FontAwesomeIcon>{post.user.nickname} さんがリポストしました</span>}
                        {!hidePoemMode && !replyBorderMode && typeCheck(postState)[0] === "reply" && <span><FontAwesomeIcon icon={faReply}></FontAwesomeIcon>{post.user.nickname} さんが返信しました</span>}
                    </div>
                    <div className='postFrame'>
                        <div className='logoFrame'>
                            {/*<img src={logo} alt="" class="logo" />*/}
                            <img onClick={(e) => { e.stopPropagation(); postUsernameClick(typeCheck(postState)[1].user.username); }} src={typeCheck(postState)[1].user.user_icon} alt="" class="logo" />
                            {replyBorderMode && <div className='border'></div>}
                            {modeSelect === "reply" && <div className='border'></div>}
                        </div>
                        <div className="postContent">
                            <div className='postContentUsername'>
                                <strong onClick={(e) => { e.stopPropagation(); postUsernameClick(typeCheck(postState)[1].user.username); }}>{typeCheck(postState)[1].user.nickname}</strong>
                                {modeSelect && (modeSelect === "showEngagementButton") ?
                                    <strong className='displayTime'><DisplayTime timestamp={typeCheck(postState)[1].post_created_date} forceFullDate={true} /></strong>
                                    :
                                    <strong className='displayTime'><DisplayTime timestamp={typeCheck(postState)[1].post_created_date} /></strong>
                                }
                                <strong className='userName' onClick={(e) => { e.stopPropagation(); postUsernameClick(typeCheck(postState)[1].user.username); }}>@{typeCheck(postState)[1].user.username}</strong>

                                {showFrom && typeCheck((postState)[0] === "reply") &&
                                    <>
                                        <br />
                                        <strong className='fromUser'>返信先: <strong className='userName' onClick={(e) => { e.stopPropagation(); postUsernameClick(typeCheck(postState)[2].user.username); }}>@{typeCheck(postState)[2].user.username}</strong>さん</strong>
                                        <br />
                                    </>
                                }
                            </div>
                            <p className='postMessage'>
                                {typeCheck(postState)[1].post_message.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line.split(/(#[^\s　\p{Punctuation}]+|https?:\/\/[^\s]+|@\w+\b)/gu).map((word, i) => {
                                            if (word.startsWith('#')) {
                                                return <span key={i} className='tagText' onMouseDown={() => handleMouseDown(event, "/search/?tag=" + word.slice(1))} onClick={(e) => { e.stopPropagation(); intelligentNavigate("/search/?tag=" + word.slice(1)) }}>{word}</span>
                                            } else if (word.startsWith('@')) {
                                                let username = word.slice(1);  // '@'を除去
                                                // mentions配列をチェック
                                                if (typeCheck(postState)[1].mentions.some(mention => mention.mentioned_user.username === username)) {
                                                    return <span key={i} className='linkText' onClick={(e) => { e.stopPropagation(); intelligentNavigate("/profile/" + username) }}>{word}</span>
                                                }
                                            } else if (word.startsWith('http')) {
                                                return <a
                                                    key={i}
                                                    className='linkText'
                                                    href={word}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    onClick={(e) => {
                                                        e.stopPropagation();  // イベントの伝播を停止
                                                    }}>{word}</a>
                                            }
                                            return word;
                                        })}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </p>

                            <div className="postImg">
                                {mediaHidden &&
                                    <div className='hiddenMedia' onClick={(e) => { e.stopPropagation(); mediaHiddenSwitch() }}>
                                        <p className='guidMessage'>
                                            <p><FontAwesomeIcon icon={faEye}></FontAwesomeIcon>センシティブ</p>
                                            <p>クリックして表示</p>
                                        </p>
                                    </div>
                                }
                                {typeCheck(postState)[1].media_links?.map((img, num) => (
                                    <div onClick={(e) => { e.stopPropagation(); intelligentNavigate("/posts/" + typeCheck(postState)[1].post_id + "/preview/" + num) }}>
                                        <img src={mediaSizeSwitch(img, "medium")} tabIndex={tab} />
                                    </div>
                                ))}
                            </div>
                            {/*親要素がボタンのため、ボタンには親要素の実行を阻止するための e.stopPropagation() が必要*/}
                            {/*<span class={favorited ? "heart" : "heart love"} onClick={(e) => { e.stopPropagation(); favorite_before(typeCheck(postState)[1].post_id); }}></span>*/}

                            {modeSelect == "showEngagementButton" &&
                                <div className='engagement-view-container'>
                                    <div className='reply' onClick={() => intelligentNavigate("repost")}>{repostCount}<span>リポスト</span></div>
                                    <div className='quote' onClick={() => intelligentNavigate("quote")}>{quoteCount}<span>件の引用</span></div>
                                    <div className='favorite' onClick={() => intelligentNavigate("like")}>{favoriteCount}<span>いいね</span></div>
                                </div>
                            }

                            {!engagementHidden &&
                                <div class="engagement-container">
                                    <button onClick={(e) => { e.stopPropagation(); modalOpen("reply", typeCheck(postState)[1].post_id) }}>
                                        <div class="reply" tabIndex={tab} onClick={(e) => { e.stopPropagation(); modalOpen("reply", typeCheck(postState)[1].post_id) }}>
                                            <div className='engagementLogo'><FontAwesomeIcon icon={regularMessage} /></div>
                                            <div className='engagementCount'>{replyCount}</div>
                                        </div>
                                    </button>
                                    <button onClick={(e) => { e.stopPropagation(); setshowRepostMenu(!showRepostMenu) }}>
                                        <div class="repost" tabIndex={tab} onClick={(e) => { e.stopPropagation(); setshowRepostMenu(!showRepostMenu); }}>
                                            <div className='engagementLogo'>{reposted ? <FontAwesomeIcon icon={solidRetweet} style={{ color: "#4cbb17", }} /> : <FontAwesomeIcon icon={solidRetweet} />}</div>
                                            <div className='engagementCount'>{repostCount}</div>
                                        </div>
                                    </button>
                                    <button onClick={(e) => { e.stopPropagation(); favorite_before(typeCheck(postState)[1].post_id) }}>
                                        <div class="favorite" tabIndex={tab} onClick={(e) => { e.stopPropagation(); favorite_before(typeCheck(postState)[1].post_id); }}>
                                            <div className='engagementLogo'>{favorited ? <FontAwesomeIcon icon={solidHeart} style={{ color: "#ff007f", }} /> : <FontAwesomeIcon icon={regularHeart} />}</div>
                                            <div className='engagementCount'>{favoriteCount}</div>
                                        </div>
                                    </button>
                                    <button>
                                        <div>
                                            <div className='engagementLogo'><FontAwesomeIcon icon={faChartBar} /></div>
                                            <div className='engagementCount'>{visitedCount}</div>
                                        </div>
                                    </button>
                                    <button onClick={(e) => { e.stopPropagation(); bookmark_before(typeCheck(postState)[1].post_id) }}>
                                        <div>
                                            <div className='engagementLogo'>{bookmarked ? <FontAwesomeIcon icon={solidBookmark} style={{ color: "#0080ff", }} /> : <FontAwesomeIcon icon={regularBookmark} />}</div>
                                        </div>
                                    </button>
                                </div>
                            }
                            {showRepostMenu && (
                                <div className="engagementRepostSelect" ref={menuRef}>
                                    <div className='engagementRepostSelectMenu'>
                                        {/* メニューの内容をここに書く */}
                                        <button onClick={(e) => { e.stopPropagation(); repost_before(typeCheck(postState)[1].post_id); setshowRepostMenu(false) }}>
                                            <div tabIndex={tab} onClick={(e) => { e.stopPropagation(); repost_before(typeCheck(postState)[1].post_id); setshowRepostMenu(false); }}>
                                                リポスト
                                            </div>
                                        </button>
                                        <button onClick={(e) => { e.stopPropagation(); modalOpen("quote", typeCheck(postState)[1].post_id) }}>
                                            <div tabIndex={tab} onClick={(e) => { e.stopPropagation(); modalOpen("quote", typeCheck(postState)[1].post_id) }}>
                                                引用リポスト
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            )}

                            <p className=''>{typeCheck(postState)[1].username}</p>
                            <p className='postID postEdit'>Post ID: {typeCheck(postState)[1].post_id}</p>
                            <p className='postCreator postEdit'>Creator: {post.post_creator}</p>
                            <p className='postCreated postEdit'>Created Date: {post.post_created_date}</p>
                            <p className='postModified postEdit'>Modified Date: {post.post_modified_date}</p>

                        </div>
                        <div className="postOtherMenuFrame" ref={menuRef}>
                            <button tabIndex={tab} onClick={(e) => { e.stopPropagation(); setshowPostOtherMenu(!showPostOtherMenu); }}>
                                <div className='postOtherMenuButton cursorPointer'>
                                    <FontAwesomeIcon icon={faEllipsis} />
                                </div>
                            </button>
                            {showPostOtherMenu && (
                                <div className='postOtherMenu engagementRepostSelectMenu'>
                                    {/* メニューの内容をここに書く */}
                                    {typeCheck(postState)[1].user.user_id == localStorage.getItem('user_id') &&
                                        <div className='deletePost' onClick={(e) => { e.stopPropagation(); deletePost(typeCheck(postState)[1].post_id); setshowPostOtherMenu(false); }}>
                                            <FontAwesomeIcon icon={faTrashCan} />
                                            投稿を削除する
                                        </div>
                                    }
                                    {typeCheck(postState)[1].user.user_id !== localStorage.getItem('user_id') &&
                                        <div onClick={(e) => { e.stopPropagation(); deletePost(typeCheck(postState)[1].post_id); setshowPostOtherMenu(false); }}>
                                            <FontAwesomeIcon icon={faHippo} />
                                            使用できる項目がありません...
                                        </div>
                                    }
                                    {/*<div onClick={(e) => { e.stopPropagation(); modalOpen("quote", typeCheck(postState)[1].post_id) }}>
                                        <FontAwesomeIcon icon={faFlag} />
                                        投稿を報告する
                                </div>*/}
                                    {/*<div onClick={(e) => { e.stopPropagation(); modalOpen("quote", typeCheck(postState)[1].post_id) }}>
                                        **さんをブロックする
                            </div>*/}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    } else if (typeCheck(postState)[0] === "quote") {
        return (
            <>
                <ModalBox modal={modal} submitFormMode={submitFormMode} target={target} close={modalClose}>
                    <button onClick={modalClose}>Close Modal</button>
                </ModalBox>
                <div class="post"
                    onMouseDown={engagementHidden ? undefined : () => handleMouseDown(event, '/posts/' + typeCheck(postState)[1].post_id)}
                    onMouseUp={engagementHidden ? undefined : handleMouseUp}
                    onClick={engagementHidden ? undefined : () => handleClick(typeCheck(postState)[1].post_id)}
                >
                    <div className='postPoem'>
                    </div>

                    <div className='postFrame'>
                        <div className='logoFrame'>
                            {/*<img src={logo} alt="" class="logo" />*/}
                            <img src={typeCheck(postState)[1].user.user_icon} alt="" class="logo" />
                            {replyBorderMode && <div className='border'></div>}
                        </div>
                        <div className="postContent">
                            <div className='postContentUsername'>
                                <strong onClick={(e) => { e.stopPropagation(); postUsernameClick(typeCheck(postState)[1].user.username); }}>{typeCheck(postState)[1].user.nickname}</strong>
                                <strong className='displayTime'><DisplayTime timestamp={typeCheck(postState)[1].post_created_date} /></strong>
                                <strong className='userName' onClick={(e) => { e.stopPropagation(); postUsernameClick(typeCheck(postState)[1].user.username); }}>@{typeCheck(postState)[1].user.username}</strong>

                                {showFrom && typeCheck((postState)[0] === "reply") &&
                                    <>
                                        <br />
                                        <strong className='fromUser'>返信先: <strong className='userName' onClick={(e) => { e.stopPropagation(); postUsernameClick(typeCheck(postState)[2].user.username); }}>@{typeCheck(postState)[2].user.username}</strong>さん</strong>
                                        <br />
                                    </>
                                }
                            </div>
                            <p className='postMessage'>
                                {typeCheck(postState)[1].post_message.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line.split(/(#[^\s　\p{Punctuation}]+|https?:\/\/[^\s]+|@\w+\b)/gu).map((word, i) => {
                                            if (word.startsWith('#')) {
                                                return <span key={i} className='tagText' onMouseDown={() => handleMouseDown(event, "/search/?tag=" + word.slice(1))} onClick={(e) => { e.stopPropagation(); intelligentNavigate("/search/?tag=" + word.slice(1)) }}>{word}</span>
                                            } else if (word.startsWith('@')) {
                                                let username = word.slice(1);  // '@'を除去
                                                // mentions配列をチェック
                                                if (typeCheck(postState)[1].mentions.some(mention => mention.mentioned_user.username === username)) {
                                                    return <span key={i} className='linkText' onClick={(e) => { e.stopPropagation(); intelligentNavigate("/profile/" + username) }}>{word}</span>
                                                }
                                            } else if (word.startsWith('http')) {
                                                return <a
                                                    key={i}
                                                    className='linkText'
                                                    href={word}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    onClick={(e) => {
                                                        e.stopPropagation();  // イベントの伝播を停止
                                                    }}>{word}</a>
                                            }
                                            return word;
                                        })}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </p>

                            <div className="postImg">
                                {mediaHidden &&
                                    <div className='hiddenMedia' onClick={(e) => { e.stopPropagation(); mediaHiddenSwitch() }}>
                                        <p className='guidMessage'>
                                            <p><FontAwesomeIcon icon={faEye}></FontAwesomeIcon>センシティブ</p>
                                            <p>クリックして表示</p>
                                        </p>
                                    </div>
                                }
                                {typeCheck(postState)[1].media_links?.map((img, num) => (
                                    <div><img src={img} /></div>
                                ))}
                            </div>
                            {/*親要素がボタンのため、ボタンには親要素の実行を阻止するための e.stopPropagation() が必要*/}
                            {/*<span class={favorited ? "heart" : "heart love"} onClick={(e) => { e.stopPropagation(); favorite_before(typeCheck(postState)[1].post_id); }}></span>*/}

                            <div className='childPost' onClick={engagementHidden ? undefined : (e) => { e.stopPropagation(); postClick(typeCheck(postState)[2].post_id) }}>
                                <div className='postFrame'>
                                    <div className='logoFrame'>
                                        {/*<img src={logo} alt="" class="logo" />*/}
                                        <img src={typeCheck(postState)[2].user.user_icon} alt="" class="logo" />
                                        {replyBorderMode && <div className='border'></div>}
                                    </div>
                                    <div className="postContent">
                                        <strong>{typeCheck(postState)[2].user.nickname}</strong>
                                        <strong className='displayTime'><DisplayTime timestamp={typeCheck(postState)[2].post_created_date} /></strong>
                                        <br />
                                        <strong className='userName'>@{typeCheck(postState)[2].user.username}</strong>
                                        <br />
                                        <p className='postMessage'>
                                            {typeCheck(postState)[2].post_message.split('\n').map((line, index) => (
                                                <React.Fragment key={index}>
                                                    {line.split(/(#[^\s　\p{Punctuation}]+|https?:\/\/[^\s]+|@\w+\b)/gu).map((word, i) => {
                                                        if (word.startsWith('#')) {
                                                            return <span key={i} className='tagText' onMouseDown={() => handleMouseDown(event, "/search/?tag=" + word.slice(1))} onClick={(e) => { e.stopPropagation(); intelligentNavigate("/search/?tag=" + word.slice(1)) }}>{word}</span>
                                                        } else if (word.startsWith('@')) {
                                                            let username = word.slice(1);  // '@'を除去
                                                            // mentions配列をチェック
                                                            if (typeCheck(postState)[2].mentions.some(mention => mention.mentioned_user.username === username)) {
                                                                return <span key={i} className='linkText' onClick={(e) => { e.stopPropagation(); intelligentNavigate("/profile/" + username) }}>{word}</span>
                                                            }
                                                        } else if (word.startsWith('http')) {
                                                            return <a
                                                                key={i}
                                                                className='linkText'
                                                                href={word}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();  // イベントの伝播を停止
                                                                }}>{word}</a>
                                                        }
                                                        return word;
                                                    })}
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </p>
                                        <div className="postImg">
                                            {typeCheck(postState)[2].media_links?.map((img, num) => (
                                                <div><img src={img} /></div>
                                            ))}
                                        </div>
                                        {/*親要素がボタンのため、ボタンには親要素の実行を阻止するための e.stopPropagation() が必要*/}
                                        {/*<span class={favorited ? "heart" : "heart love"} onClick={(e) => { e.stopPropagation(); favorite_before(typeCheck(postState)[1].post_id); }}></span>*/}

                                        <p className=''>{typeCheck(postState)[2].username}</p>
                                        <p className='postID postEdit'>Post ID: {typeCheck(postState)[2].post_id}</p>
                                        <p className='postCreator postEdit'>Creator: {post.post_creator}</p>
                                        <p className='postCreated postEdit'>Created Date: {post.post_created_date}</p>
                                        <p className='postModified postEdit'>Modified Date: {post.post_modified_date}</p>
                                    </div>
                                </div>
                            </div>

                            {modeSelect == "showEngagementButton" &&
                                <div className='engagement-view-container'>
                                    <div className='reply' onClick={() => intelligentNavigate("repost")}>{repostCount}<span>リポスト</span></div>
                                    <div className='quote' onClick={() => intelligentNavigate("quote")}>{quoteCount}<span>件の引用</span></div>
                                    <div className='favorite' onClick={() => intelligentNavigate("like")}>{favoriteCount}<span>いいね</span></div>
                                </div>
                            }
                            {!engagementHidden &&
                                <div class="engagement-container">
                                    <button onClick={(e) => { e.stopPropagation(); modalOpen("reply", typeCheck(postState)[1].post_id) }}>
                                        <div class="reply" tabIndex={tab} onClick={(e) => { e.stopPropagation(); modalOpen("reply", typeCheck(postState)[1].post_id) }}>
                                            <div className='engagementLogo'><FontAwesomeIcon icon={regularMessage} /></div>
                                            <div className='engagementCount'>{replyCount}</div>
                                        </div>
                                    </button>
                                    <button onClick={(e) => { e.stopPropagation(); setshowRepostMenu(!showRepostMenu) }}>
                                        <div class="repost" tabIndex={tab} onClick={(e) => { e.stopPropagation(); setshowRepostMenu(!showRepostMenu); }}>
                                            <div className='engagementLogo'>{reposted ? <FontAwesomeIcon icon={solidRetweet} style={{ color: "#4cbb17", }} /> : <FontAwesomeIcon icon={solidRetweet} />}</div>
                                            <div className='engagementCount'>{repostCount}</div>
                                        </div>
                                    </button>
                                    <button onClick={(e) => { e.stopPropagation(); favorite_before(typeCheck(postState)[1].post_id) }}>
                                        <div class="favorite" tabIndex={tab} onClick={(e) => { e.stopPropagation(); favorite_before(typeCheck(postState)[1].post_id); }}>
                                            <div className='engagementLogo'>{favorited ? <FontAwesomeIcon icon={solidHeart} style={{ color: "#ff007f", }} /> : <FontAwesomeIcon icon={regularHeart} />}</div>
                                            <div className='engagementCount'>{favoriteCount}</div>
                                        </div>
                                    </button>
                                    <button>
                                        <div>
                                            <div className='engagementLogo'><FontAwesomeIcon icon={faChartBar} /></div>
                                            <div className='engagementCount'>{visitedCount}</div>
                                        </div>
                                    </button>
                                    <button onClick={(e) => { e.stopPropagation(); bookmark_before(typeCheck(postState)[1].post_id) }}>
                                        <div>
                                            <div className='engagementLogo'>{bookmarked ? <FontAwesomeIcon icon={solidBookmark} style={{ color: "#0080ff", }} /> : <FontAwesomeIcon icon={regularBookmark} />}</div>
                                        </div>
                                    </button>
                                </div>
                            }
                            {showRepostMenu && (
                                <div className="engagementRepostSelect" ref={menuRef}>
                                    <div className='engagementRepostSelectMenu'>
                                        {/* メニューの内容をここに書く */}
                                        <button onClick={(e) => { e.stopPropagation(); repost_before(typeCheck(postState)[1].post_id); setshowRepostMenu(false) }}>
                                            <div tabIndex={tab} onClick={(e) => { e.stopPropagation(); repost_before(typeCheck(postState)[1].post_id); setshowRepostMenu(false); }}>
                                                リポスト
                                            </div>
                                        </button>
                                        <button onClick={(e) => { e.stopPropagation(); modalOpen("quote", typeCheck(postState)[1].post_id) }}>
                                            <div tabIndex={tab} onClick={(e) => { e.stopPropagation(); modalOpen("quote", typeCheck(postState)[1].post_id) }}>
                                                引用リポスト
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            )}

                            <p className=''>{typeCheck(postState)[1].username}</p>
                            <p className='postID postEdit'>Post ID: {typeCheck(postState)[1].post_id}</p>
                            <p className='postCreator postEdit'>Creator: {post.post_creator}</p>
                            <p className='postCreated postEdit'>Created Date: {post.post_created_date}</p>
                            <p className='postModified postEdit'>Modified Date: {post.post_modified_date}</p>

                        </div>
                        <div className="postOtherMenuFrame" ref={menuRef}>
                            <button tabIndex={tab} onClick={(e) => { e.stopPropagation(); setshowPostOtherMenu(!showPostOtherMenu); }} onSubmit={console.log(123)}>
                                <div className='postOtherMenuButton cursorPointer'>
                                    <FontAwesomeIcon icon={faEllipsis} />
                                </div>
                            </button>
                            {showPostOtherMenu && (
                                <div className='postOtherMenu engagementRepostSelectMenu'>
                                    {/* メニューの内容をここに書く */}
                                    {console.log("OPENN")}
                                    {typeCheck(postState)[1].user.user_id == localStorage.getItem('user_id') &&
                                        <div className='deletePost' onClick={(e) => { e.stopPropagation(); deletePost(typeCheck(postState)[1].post_id); setshowPostOtherMenu(false); }}>
                                            <FontAwesomeIcon icon={faTrashCan} />
                                            投稿を削除する
                                        </div>
                                    }
                                    <div onClick={(e) => { e.stopPropagation(); modalOpen("quote", typeCheck(postState)[1].post_id) }}>
                                        <FontAwesomeIcon icon={faFlag} />
                                        投稿を報告する
                                    </div>
                                    <div onClick={(e) => { e.stopPropagation(); modalOpen("quote", typeCheck(postState)[1].post_id) }}>
                                        **さんをブロックする
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return (
        <div>ANY</div>
    )
}

export default PostsRenderer;