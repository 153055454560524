import { useParams } from 'react-router-dom';
import Header from "components/blocks/Header/Header"
import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import "./style.scss"

import PostRender from "components/blocks/PostsRenderer/PostsRenderer"

import { getPost, getReply, getCursor, getProfile, PostSubmit, QuoteSubmit, ReplySubmit } from "common/api/APIUtils"

import EasySnackbar from 'components/blocks/EasySnackbar/EasySnackbar'

import { SnackbarContext } from 'components/blocks/GlobalSnackbar/SnackbarProvider';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import { PostMap } from './../Map/PostMap';
import { pos } from './../Map/MapComp';

import { faImages } from '@fortawesome/free-solid-svg-icons';
import { faEarthAsia } from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-modal';

export const useModal = () => {
    const [modal, setModal] = React.useState(false);
    const [submitFormMode, setSubmitFormMode] = React.useState("");
    const [target, setTarget] = React.useState("");
    const modalOpen = (submitFormMode = "", target = "") => {
        if (localStorage.getItem('user_name') !== null) {
            setSubmitFormMode(submitFormMode);
            setTarget(target);
            setModal(true);

        }
    };
    const modalClose = () => setModal(false);
    return { modal, submitFormMode, target, modalOpen, modalClose };
};

export const ModalBox = ({ modal, submitFormMode, target, close }) => {
    const [submitProgress, setsubmitProgress] = useState(false);
    const [easySnackbarMessage, setEasySnackbarMessage] = useState();
    const [contentHeight, setContentHeight] = useState('300px');
    const openSnackbar = useContext(SnackbarContext);

    let reply = false;
    let quote = false;
    let post_id = false;
    if (submitFormMode === "reply") {
        reply = true;
        post_id = target;
    }
    if (submitFormMode === "quote") {
        quote = true;
        post_id = target;
    }
    Modal.setAppElement('#root');
    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            padding: "0px",
            width: '100%',
            height: '100%',
            zIndex: '1000',
            backgroundColor: "rgb(255 255 255 / 20%)",
        },
        content: {
            position: 'absolute',  // 絶対位置指定
            width: "600px",
            border: "None",
            padding: "0px",
            overflow: 'None',
            inset: "50% 50% 50% 50%",
        },
    };


    const modalClose = () => {
        setSelectedImages([]);
        setMessage("")
        close();
    };

    const afterOpen = () => {
        document.body.style.overflow = 'hidden';
    };

    const afterClose = () => {
        document.body.style.overflow = 'unset';
    };

    const [message, setMessage] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);

    const handleImageChange = (e) => {
        if (e.target.files) {
            // Fileオブジェクトの配列を直接selectedImagesに保存
            const filesArray = Array.from(e.target.files);
            setSelectedImages((prevImages) => prevImages.concat(filesArray.slice(0, 4 - prevImages.length)));
        }
    };

    const handleImageRemove = (index) => {
        setSelectedImages((prevImages) => prevImages.filter((image, i) => i !== index));
    };


    const renderPhotos = (source) => {
        return useMemo(() => source.map((file, index) => {
            const url = URL.createObjectURL(file);
            return (
                <div key={url} className='previewImageFrame'>
                    <img src={url} alt="" />
                    <FontAwesomeIcon onClick={() => handleImageRemove(index)} className='previewImageDeleteButton' icon={faXmark} />
                </div>
            );
        }), [source]);
    };



    const [posts, setPosts] = useState([false]);

    useEffect(() => {
        if (!modal) return;  // モーダルが閉じている場合は何もしない

        const fetchPosts = async () => {
            let response = await getPost(post_id);
            setPosts(response);  // レスポンスをステートにセット
        };

        fetchPosts();
    }, [post_id, modal]);  // post_idまたはmodalが変更されたときに再度フェッチするように依存配列を設定


    async function PostSubmitWrapper(message, selectedImages) {
        console.log("レングス")
        console.log(selectedImages.length)
        console.log(selectedImages)
        console.log(typeof (selectedImages))
        console.log(pos)

        setsubmitProgress(true)

        let result
        if (reply) {
            result = await ReplySubmit(post_id, message, selectedImages)
        } else if (quote) {
            result = await QuoteSubmit(post_id, message, selectedImages)
        } else {
            result = await PostSubmit(message, selectedImages, finalPos[0], finalPos[1])
        }

        console.log("投稿結果ログ")
        console.log(result)
        if (result && result === true) {
            // 投稿成功した場合の処理
            console.log("投稿成功！")
            openSnackbar('投稿しました');
            setSelectedImages([]);
            modalClose();
        } else {
            //setEasySnackbarMessage(result.message)
            openSnackbar('エラーが発生しました: ' + result.message, 'error');
            openSnackbar('エラーが発生しました: ' + result.message, 'error');
        }
        setsubmitProgress(false)
    }

    const [showMap, setShowMap] = useState(false);

    useEffect(() => {
        const formElement = document.querySelector('.postSubmitFormContent');
        if (formElement) {
            const formHeight = formElement.offsetHeight;
            //console.log(formHeight)
            if (formHeight > 300) {
                setContentHeight('500px');
                //console.log("500モード！")
            } else {
                setContentHeight('400px');
                //console.log("300モード！")
            }
        }
    });

    //マップの表示
    const handleShowMap = () => {
        if (showMap === false) {
            setShowMap(true)
        } else {
            setShowMap(false)
        }
    }

    //座標データの取得
    const [finalPos, setFinalPos] = useState([null, null]);
    const handleSetPos = () => {
        setFinalPos(pos);
        setShowMap(false);
    }
    //座標リセット
    const handleResetPos = () => {
        setFinalPos([null, null]);
        setShowMap(false);
    }
    //座標の表示情報
    const [viewPosData, setViewPosData] = useState("座標未設定")
    useEffect(() => {
        if (finalPos[0] === null) {
            setViewPosData("座標未設定")
        } else {
            setViewPosData("座標設定 ( " + finalPos[0] + ", " + finalPos[1] + " )")
        }
    }, finalPos);

    function textLengthGuardian(message, limit) {
        return limit - message.length
        //    if (message.length < limit) {
        //        return
        //    } else {
        //        return(<h5>{message.length}</h5>)
        //    }
    }

    return (
        <Modal isOpen={modal} overlayClassName="postSubmitFormPortalRoot" onAfterOpen={afterOpen} onRequestClose={close} onAfterClose={afterClose} style={{ content: { height: contentHeight, maxHeight: "100%" } }}>
            <div class="postSubmitFormRoot">
                <EasySnackbar message={easySnackbarMessage} />
                <div class="postSubmitFormHeader">
                    <FontAwesomeIcon icon={faPaperPlane} />
                    投稿する
                    <button onClick={modalClose} tabIndex={0} src={faXmark}>
                        <FontAwesomeIcon onClick={modalClose} className='postSubmitFormCloseButton' icon={faXmark} />
                    </button>
                </div>
                <div class="postSubmitFormFrame">
                    <div class="postSubmitFormContent">
                        {reply &&
                            <div>
                                <div className='rootPost'>
                                    {/*こっちが親投稿 */}
                                    {posts[0]?.content && <PostRender post={posts[0]} engagementHidden={true}/>}

                                </div>
                            </div>
                        }
                        <div class="postSubmitForm">
                            <div class="form-example">
                                <div class="messageFormFrame">
                                    <img src={localStorage.getItem('user_icon')} alt="" class="userIcon" />
                                    <div className='postSubmitForms'>
                                        <textarea name="example" cols="50" rows="6" required placeholder="いまどうしてる？" className='postSubmitFormInForm' onChange={(e) => setMessage(e.target.value)}></textarea>
                                        <div className='postSubmitImageSelect'>
                                            {textLengthGuardian(message,140)}
                                            <div name="hr" style={{ background:"linear-gradient(to Right,lightGray 0%,lightGray "+message.length/140*100+"%, gray "+message.length/140*100+"%, gray 100%)",
                                                                    width:100+"%",height:3+"px",margin:0+" "+0+" "+5+"px"}}></div>
                                            <div className="postSubmitPreviewImg">{renderPhotos(selectedImages)}</div>
                                            <div className="label-holder">
                                                <label htmlFor="file" className="label">
                                                    <input type="file" id="file" multiple onChange={handleImageChange} accept=".png, .jpg, .jpeg"/>
                                                    <FontAwesomeIcon icon={faImage} />
                                                    <div>{selectedImages.length!=0?selectedImages.length:""}</div>
                                                </label>
                                            </div>
                                        </div>
                                        
                                        {!showMap && (
                                            <div className='viewPosData'>{viewPosData}</div>
                                        )}
                                        {showMap && (
                                            <>
                                                <div className='showMapMenu'>
                                                    <button className="getPosbtn" onClick={handleSetPos}>取得</button>
                                                    <button className="resetPosbtn" onClick={handleResetPos}>リセット</button>
                                                </div>
                                                <PostMap />
                                            </>
                                        )}

                                        {quote &&
                                            <div className='postSubmitFormQuotePost'>
                                                <div className='rootPost'>
                                                    {/*こっちが親投稿 */}
                                                    {posts[0]?.content && <PostRender post={posts[0]} engagementHidden={true} />}

                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="postSubmitFormFooterNavigation">
                    <div class="leftContent">
                        <label htmlFor="selectImgbtn" ><FontAwesomeIcon className='contents' icon={faImages} /></label>
                        <input type="file" id="selectImgbtn" multiple accept='image/*' onChange={handleImageChange} style={{ display: 'none' }} />
                        <FontAwesomeIcon className='contents' onClick={handleShowMap} icon={faEarthAsia} />
                    </div>
                    <div class="rightContent">
                        {submitProgress ? <div className='postSubmitProgress'>送信中...</div> : <input type="submit" className={selectedImages.length+message.length>=1&&message.length<=140?"postSubmitButton cursorPointer":"postSubmitButton unjoined"} value="投稿" onClick={() => PostSubmitWrapper(message, selectedImages)} />}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

//export default PostSubmitView;